/*
 * @Author: zwb 1172031304@qq.com
 * @Date: 2022-05-17 16:41:37
 * @LastEditors: zwb 1172031304@qq.com
 * @LastEditTime: 2022-05-27 15:25:52
 * @FilePath: /jyws_vue/src/core/date.js
 * @Description: 这是默认设置,请设置`customMade`, 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 */
export function exportFile(url, data) {
	return new Promise((resolve) => {
		fetch(url, {
			method: 'post',
			body: JSON.stringify(data),
			headers: {
				Accept: 'application/json',
				'Content-Type': 'application/json',
			},
			responseType: 'blob',
		}).then((res) => {
			const fileNameEncode = res.headers
				.get('content-disposition')
				.split('filename=')[1]
			const fileName = decodeURIComponent(fileNameEncode)
			res.blob().then((blob) => {
				// let bl = new Blob([blob], { type: 'application/vnd.ms-excel;charset=UTF-8' });
				var link = document.createElement('a')
				link.href = window.URL.createObjectURL(blob)
				link.download = fileName
				link.click()
				window.URL.revokeObjectURL(link.href)
			})
			resolve(true)
		})
	})
}

export function urlDataDeal(obj) {
	let temp = ''
	var str = []
	for (var p in obj) {
		str.push(encodeURIComponent(p) + '=' + encodeURIComponent(obj[p]))
	}
	if (str.length) {
		temp = '&' + str.join('&')
	}
	return temp
}

export function dateFormat01(dateTime, type) {
  if (dateTime === null || dateTime === '' || dateTime === undefined) {
    return dateTime
  } else {
    var arr = dateTime.split('')
    var year = arr[0] + arr[1] + arr[2] + arr[3]
    var month = arr[4] + arr[5]
    var day = arr[6] + arr[7]
    var hour = arr[8] + arr[9]
    var second = arr[10] + arr[11]
    if (type) {
      if (type === 'year') {
        return year
      } else if (type === 'month') {
        return year + '-' + month
      } else if (type === 'day') {
        return year + '-' + month + '-' + day
      } else if (type === 'monthDay') {
        return month + '月' + day + '日' + hour + ':' + second
      }
    } else {
      return year + '-' + month + '-' + day
    }
  }
}
export function dateFormat7(dateTime) {
	if (dateTime === null || dateTime === "" || dateTime === undefined) {
		return dateTime;
	} else {
		var arr = dateTime.split('');
		var year = arr[0] + arr[1] + arr[2] + arr[3];
		var month = arr[4] + arr[5];
		var day = arr[6] + arr[7];
		dateTime = year + '-' + month + '-' + day;
		return dateTime;
	}
}
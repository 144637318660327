<!--
 * @Author: your name
 * @Date: 2022-04-22 11:51:01
 * @LastEditTime: 2022-05-27 15:49:08
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/views/equipmentLifeCycle/equipmentLifeCycle.vue
-->
<template>
  <div class="numberOfDevices">
    <div style="width: 220px;margin-right: 21px;">
      <div class="content-left">
        <!-- 左侧标题 -->
        <div class="content-left-title">
          <div>设备列表</div>
          <div class="flush" @click="oneTree">
            <a-icon type="sync"
                    style="margin-right:6px" />刷新
          </div>
        </div>
        <div class="content-left-tree ">
          <deviceTree v-if="oneTreeVisble" :treeData='deviceTreeList' @treeSelect='treeSelect' @expandTree="expandTree"></deviceTree>
        </div>
      </div>
    </div>
    <div class="content-center">
      <div class="fullLifeCycleOfTheDevice-content">
        <div class="f-content-top">
          <div>
            <div class="f-content-top-item">
              <div class="f-content-top-item-title">{{ reg.objectName }}</div>
              <div class="f-content-top-item-subtitle"><div class="f-content-top-item-subtitle-model">{{ reg.assetsCode }}</div><div v-if="reg.equpmentStyle" class="f-content-top-item-subtitle-type">{{ reg.equpmentStyle }}</div><div class="f-content-top-item-subtitle-type" style="color: #2FC25B;background: #E6FFFA;">{{ reg.status | conditionCheck(reg.status) }}</div></div>
              <div class="marginb2">部门：{{ reg.depName }}</div>
              <div>型号：{{ reg.c_standard }}</div>
            </div>
          </div>
        </div>
        <div class="f-content-analysis">
          <div class="f-content-MaxTitle">
            <div>设备稼动率分析</div>
            <img src="https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/app/icon/icon_guanyu.png" class="f-content-top-img" @click="labelVisble2 = true">
          </div>
          <div class="f-content-echarts">
            <div class="f-content-echarts-item">
              <div style="width: 50%;height: 100%">
                <div class="f-content-echarts-title">设备完全有效生产率</div>
                <div class="f-content-echarts-name">TEEP</div>
                <div class="f-content-echarts-num">{{ basicData.teep }}<span class="f-content-echarts-unit">%</span></div>
              </div>
              <div style="width: 50%;height: 100%">
                <div id="myChart3" style="width: 100%;height: 100%" />
              </div>
            </div>
            <div class="f-content-echarts-item">
              <div style="width: 50%;height: 100%">
                <div class="f-content-echarts-title">设备综合效率</div>
                <div class="f-content-echarts-name">OEE</div>
                <div class="f-content-echarts-num">{{ basicData.oee }}<span class="f-content-echarts-unit">%</span></div>
              </div>
              <div style="width: 50%;height: 100%">
                <div id="myChart4" style="width: 100%;height: 100%" />
              </div>
            </div>
          </div>
        </div>
        <div class="search_list">
          <div
            style="border-bottom: none;"
            class="search_list_item"
            @click="CheckRecordClick('资产台帐')">
            <div class="img-border-kuang">
              <img class="search-list-img" src="@/assets/shezhi_white.png">
            </div>
            <div>资产台帐</div>
          </div>
          <div
            class="search_list_item"
            @click="CheckRecordClick('点检记录')">
            <div style="background: #13C2C2;" class="img-border-kuang">
              <img class="search-list-img" src="@/assets/dianjianjilu_cyan.png">
            </div>
            <div>点检记录</div>
          </div>
          <div
            class="search_list_item"
            @click="CheckRecordClick('保养记录')">
            <div style="background: #2FC25B;" class="img-border-kuang">
              <img class="search-list-img" src="@/assets/baoyangjilu_cyan.png">
            </div>
            <div>保养记录</div>
          </div>
          <div
            class="search_list_item"
            @click="CheckRecordClick('维修记录')">
            <div style="background: #FCAD00;" class="img-border-kuang">
              <img class="search-list-img" src="@/assets/weixiujilu_white.png">
            </div>
            <div>维修记录</div>
          </div>
          <div
            class="search_list_item"
            @click="CheckRecordClick('备件记录')">
            <div style="background: #35C3FF;" class="img-border-kuang">
              <img class="search-list-img" src="@/assets/beijianjilu_white.png">
            </div>
            <div>备件记录</div>
          </div>
          <div
            style="border-right: none;"
            class="search_list_item"
            @click="CheckRecordClick('检验记录')">
            <div style="background: #2FC25B;" class="img-border-kuang">
              <img class="search-list-img" src="@/assets/jianyanjilu_white.png">
            </div>
            <div>检验记录</div>
          </div>
          <div
            style="border-bottom: none;"
            class="search_list_item"
            @click="CheckRecordClick('盘点记录')">
            <div style="background: #FCAD00;" class="img-border-kuang">
              <img class="search-list-img" src="@/assets/pandianjilu_white.png">
            </div>
            <div>盘点记录</div>
          </div>
          <div
            style="border-bottom: none;"
            class="search_list_item"
            @click="CheckRecordClick('工单确认')">
            <div style="background: #35C3FF;" class="img-border-kuang">
              <img class="search-list-img" src="@/assets/gongdanqueren_white.png">
            </div>
            <div>工单确认</div>
          </div>
          <!-- <div
            style="border-bottom: none;"
            class="search_list_item"
            @click="ExceptionRecordClick">
            <img
              class="search-list-img"
              src="@/assets/yichangjilu_orange.png">
            <div>隐患记录</div>
          </div> -->
          <div
            style="border-bottom: none;"
            class="search_list_item"
            @click="CheckRecordClick('设备资料')">
            <div style="background: #1890FF;" class="img-border-kuang">
              <img class="search-list-img" src="@/assets/guzhangshouce_white.png">
            </div>
            <div>设备资料</div>
          </div>
          <!-- <div
            style="border-bottom: none;"
            class="search_list_item"
            @click="CheckRecordClick('物联监测')">
            <div style="background: #13C2C2;" class="img-border-kuang">
              <img class="search-list-img" src="@/assets/wulianjiance_white.png">
            </div>
            <div>物联监测</div>
          </div> -->
        </div>
        <div class="f-content-analysis" style="margin-bottom: 0px;">
          <div class="f-content-maintenance-time">
            <div class="f-content-maintenance-time-title">停机时长及次数分析</div>
            <div class="f-content-maintenance-line">
              <div
                id="myChart"
                style="width: 100%;height: 100%" />
            </div>
          </div>
          <div class="f-content-human-cost">
            <div class="human-cost-t">
              <div
                class="human-cost-t-item-l"
                @click="HumanCostClick">
                <div class="human-cost-t-item-title">
                  <span>人力成本&nbsp;</span>
                  <img
                    class="f-content-top-img"
                    src="@/assets/you_blue.png">
                </div>
                <div class="human-cost-t-item-num">{{ reg.RLCB.toFixed(2) }}<span class="human-cost-t-item-unit">万元</span></div>
                <!-- <img
                  class="item-img"
                  src="@/assets/renlichengben.png"> -->
              </div>
              <div
                class="human-cost-t-item-r"
                @click="ConsumeSparePartsClick">
                <div class="human-cost-t-item-title">
                  <span>消耗备件&nbsp;</span>
                  <img
                    class="f-content-top-img"
                    src="@/assets/you_blue.png">
                </div>
                <div class="human-cost-t-item-num">{{ reg.partNumber.toFixed(2) }}<span class="human-cost-t-item-unit">万元</span></div>
                <!-- <img
                  class="item-img"
                  src="@/assets/xiaohaobeijian.png"> -->
              </div>
            </div>
            <div class="human-cost-b">
              <div style="flex: 1;">
                <div
                  id="myChart2"
                  style="width: 100%;height: 100%" />
              </div>
              <div
                style="flex: 1;"
                class="cost-bottom">
                <div class="cost-bottom-item">
                  <div class="cost-bottom-item-title">
                    <span class="circle" />
                    检修计划
                  </div>
                  <div class="cost-bottom-item-num">{{ reg.endPlanTaskNum || 0 }}次、{{ reg.endPlanTaskNum ? parseFloat((((reg.endPlanTaskNum) / reg.totalTime)*100).toFixed(2)) : 0 }}%</div>
                </div>
                <div class="cost-bottom-item">
                  <div class="cost-bottom-item-title">
                    <span
                      style="background: #1890FF;"
                      class="circle" />
                    预知维修
                  </div>
                  <div
                    style="color: #1890FF;"
                    class="cost-bottom-item-num">{{ reg.YZWX || 0 }}次、{{ reg.YZWX ? parseFloat((((reg.YZWX) / reg.totalTime)*100).toFixed(2)) : 0 }}%</div>
                </div>
                <div class="cost-bottom-item">
                  <div class="cost-bottom-item-title">
                    <span
                      style="background: #F87500;"
                      class="circle" />
                    故障维修
                  </div>
                  <div
                    style="color: #F87500;"
                    class="cost-bottom-item-num">{{ reg.endTaskNum || 0 }}次、{{ reg.endTaskNum ? parseFloat((((reg.endTaskNum) / reg.totalTime)*100).toFixed(2)) : 0 }}%</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="labelVisble" class="jyws-popup" >
        <div class="jyws-popup-content">
          <div class="jyws-popup-content-data">
            <div class="screening-label-flex">
              <div>指标说明</div>
              <img class="screening-label-flex-img" src="@/assets/icon_close_blue.png" @click="labelVisble = false">
            </div>
            <div class="screening-label-list">
              <div class="screening-label-title">MTBF（平均故障间隔时间）</div>
              <div class="margint8">MTBF表示的是设备故障发生期间的时间平均值，MTBF越大即表示设备可靠性越高、生产保障能力越强；是评价设备部门工作成效有效参考值。</div>
              <div class="margint8">计算公式为：MTBF=总运行时间/(总故障次数 + 1)</div>
              <div class="screening-label-title margint20">MRT（平均维修响应时间）</div>
              <div class="margint8">MRT表示的是设备故障发生后维修人员到达现场维修的时间平均值，MRT越小即表示维修人员到达现场维修的时间越短、故障响应越及时；是评价维修部门效率的有效参考值。</div>
              <div class="margint8">计算公式为：MRT=总响应时间/总维修次数</div>
              <div class="screening-label-title margint20">MTTR（平均故障修复时间）</div>
              <div class="margint8">MTTR表示的是设备故障发生期间修复时间（发生故障到维修结束）的平均值，MTTR越小表示维修的效率越高、对生产的停机影响越低；是评价设备维修知识经验共享、人员技能成熟度、备件合理库存的有效参考指标。</div>
              <div class="margint8">计算公式为：MTTR=总故障时间/总维修次数</div>
              <div class="screening-label-title margint20">设备可用度</div>
              <div class="margint8">计算公式为：设备可用度=MTBF/（MTBF+MTTR）*100%</div>
              <div class="margint8">设备可用度越高，表示设备的可靠性与维修性设计越好，是评价设备质量的有效参考值。</div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="labelVisble2" class="jyws-popup" >
        <div class="jyws-popup-content">
          <div class="jyws-popup-content-data">
            <div class="screening-label-flex">
              <div>指标说明</div>
              <img class="screening-label-flex-img" src="@/assets/icon_close_blue.png" @click="labelVisble2 = false">
            </div>
            <div class="screening-label-list">
              <div class="warm-prompt">温馨提示：TEEP和OEE等指标数据需设备接入精益卫士-智能物联云盒才能展示数据。</div>
              <div class="screening-label-title margint20">TEEP（完全有效生产率）</div>
              <div class="margint8">即把所有与设备有关和无关的因素都考虑在内来全面反映设备利用率，从企业资产投资角度，设备的利用率代表了资金的使用效率，效率越高越好；是评价企业设备投资回报率的有效参考值。</div>
              <div class="margint8">计算公式为：TEEP=价值工作时间/自然时间</div>
              <div class="screening-label-title margint20">OEE（设备综合效率）</div>
              <div class="margint8">即设备负荷时间内实际产量与理论产量的比值。OEE越高，表示设备时间稼动率、性能稼动率、良品率越好；是评价企业生产过程水平的有效参考值。</div>
              <div class="margint8">计算公式为：OEE=价值工作时间/计划工作时间</div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="content-right">
      <div class="f-content-top-item">
        <div class="conditionQuery-head">
          <div class="conditionQuery-head-left">
            <div class="conditionQuery-head-item">
              <template>
                <span class="flexShrink marginRight12">时间范围</span>
                <a-range-picker class="width220" @change="onChange" />
              </template>
            </div>
          </div>
          <div style="text-align: right;" class="flexShrink">
            <a-button v-if="exportState" class="marginl20" icon="export" @click="exportExcel">导出</a-button>
            <a-button class="marginl20" type="primary" icon="search" @click="selectClick">查询</a-button>
          </div>
        </div>
        <div class="time-flex">
          <div :style="basicsType == 1 ? 'background: #e6f7ff;color: #1890ff;':''" class="time-flex-item" @click="basicsSelectClick(1)">近1月</div>
          <div :style="basicsType == 2 ? 'background: #e6f7ff;color: #1890ff;':''" class="time-flex-item" @click="basicsSelectClick(2)">近3月</div>
          <div :style="basicsType == 3 ? 'background: #e6f7ff;color: #1890ff;':''" class="time-flex-item" @click="basicsSelectClick(3)">近6月</div>
          <div :style="basicsType == 4 ? 'background: #e6f7ff;color: #1890ff;':''" class="time-flex-item" @click="basicsSelectClick(4)">近1年</div>
        </div>
      </div>
      <div class="f-content-analysis">
        <div class="f-content-MaxTitle">
          <div>设备可靠性分析</div>
          <img src="https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/app/icon/icon_guanyu.png" class="f-content-top-img" @click="labelVisble = true">
        </div>
        <div class="f-content-time">
          <div class="fault_time border-r1">
            <div>
              <span style="color: #2FC25B" class="setinterval">{{ (reg.MTBF/60).toFixed(0) }}</span>
              <span style="color: #2FC25B" class="unit">小时</span>
            </div>
            <div class="padingb4">
              <span class="abbreviations">MTBF</span>
            </div>
            <span class="abbreviations_explain">平均故障间隔时间</span>
          </div>
          <div class="fault_time border-r1">
            <div>
              <span style="color: #1890FF" class="setinterval">{{ reg.MRT.toFixed(0) }}</span>
              <span style="color: #1890FF" class="unit">分钟</span>
            </div>
            <div class="padingb4">
              <span class="abbreviations">MRT</span>
            </div>
            <span class="abbreviations_explain">平均维修响应时间</span>
          </div>
          <div class="fault_time">
            <div>
              <span
                style="color: #F87500;"
                class="setinterval">{{ reg.MTTR.toFixed(0) }}</span>
              <span
                style="color: #F87500;"
                class="unit">分钟</span>
            </div>
            <div class="padingb4">
              <span class="abbreviations">MTTR</span>
            </div>
            <span class="abbreviations_explain">平均故障修复时间</span>
          </div>
        </div>
        <div class="f-content-availability">设备可用度：{{ (reg.MTBF + reg.MTTR) == 0 ? '100' : ((reg.MTBF / (reg.MTBF + reg.MTTR)) * 100).toFixed(0) }}%</div>
        <div class="f-content-progress-bar">
          <div :style="`width: ${reg.MTBF == 0 ? '0' : ((reg.MTBF / (reg.MTBF + reg.MRT + reg.MTTR)) * 100).toFixed(0) }%;height: 100%;background: #2fc25b;`"/>
          <div :style="`width: ${reg.MRT == 0 ? '0' : ((reg.MRT / (reg.MTBF + reg.MRT + reg.MTTR)) * 100).toFixed(0) }%;height: 100%;background: #1890FF;`"/>
          <div :style="`width: ${reg.MTTR == 0 ? '0' : ((reg.MTTR / (reg.MTBF + reg.MRT + reg.MTTR)) * 100).toFixed(0) }%;height: 100%;background: #F87500;`"/>
        </div>
      </div>
      <div class="OEEMonitoring-content-echarts marginb10">
        <div class="OEEMonitoring-content-echarts-name">TEEP趋势分析</div>
        <div class="OEEMonitoring-content-echarts-chart">
          <div id="myChart5" style="height:100%" />
          <div v-if="oeeSelect.teepValue.length == 0" class="no-data-err">暂无数据</div>
        </div>
      </div>
      <div class="OEEMonitoring-content-echarts marginb10">
        <div class="OEEMonitoring-content-echarts-name">OEE趋势分析</div>
        <div class="OEEMonitoring-content-echarts-chart">
          <div id="myChart6" style="height:100%" />
          <div v-if="oeeSelect.oeeValue.length == 0" class="no-data-err">暂无数据</div>
        </div>
      </div>
    </div>
    <equipmentDetail v-if="labelVisble3" :id="this.objectId" :visible="labelVisble3" @updataWin="updataWin"/>
  </div>
</template>

<script>
import deviceTree from '@/components/deviceTree.vue'
import equipmentDetail from '@/components/equipmentDetail.vue'
import * as util from '@/core/util'
import { dateFormat01 } from '@/core/date'
import moment from 'moment'
export default {
  name: 'EquipmentLifeCycle',
  components: {
    deviceTree, equipmentDetail
  },
  filters: {
    conditionCheck(text) {
      if (text === 1) {
        return '正投运'
      } else if (text === 2) {
        return '已闲置'
      } else if (text === 3) {
        return '即将报废'
      } else if (text === 4) {
        return '已报废'
      } else if (text === 5) {
        return '已封存'
      } else if (text === 6) {
        return '已转让'
      }
    }
  },
  data() {
    return {
      userId:'',
      tenantId: '',
      deviceTreeList: [], // 设备树数据源
      oneTreeVisble: true,
      objectId: '',
      objectName: '',
      organizationId: '',
      organizationName: '',

      // 设备全生命周期
      exportState: false,
      labelVisble: false,
      labelVisble2: false,
      labelVisble3: false,
      time: new Date(),
      myChart1: {},
      myChart2: {},
      myChart3: {},
      myChart4: {},
      myChart5: {},
      myChart6: {},
      basicsType: 1,
      beginTime: moment(new Date()).subtract(1, 'months').format('YYYYMMDD'),
      endTime: moment(new Date()).format('YYYYMMDD'),
      // 返回数据
      reg: {
        assetsCode: '',
        equpmentStyle: '',
        MTBF: 0, // 平均间隔时间
        MRT: 0, // 平均维修响应时间
        MTTR: 0, // 平均故障修复时间
        RLCB: 0,
        c_standard: '', // 型号
        dataset: [], // 停机时长及次数分析 数据源
        depName: '', // 部门名称
        objectId: '', // 设备id
        objectName: '', // 设备名称
        partNumber: 0, // 消耗备件
        endTaskNum: 0, // 故障维修
        repNumber: 0, // 检维修
        YZWX: 0, // 预知维修
        taskNumber: 0, // 点巡检
        endPlanTaskNum: 0, // 检修计划
        totalTime: 0 // 点检保养 + 检修计划 + 故障维修
      },
      // 停机时长及次数分析
      downDate: [], // 时间：['201801','201802','201803',...]
      downNum: [], // 次数：[5,10,8,...]
      downTime: [], // 时长：[5,10,8,...]
      oeeType: 1,
      oeeTime: {
        beginTime: moment(new Date()).subtract(1, 'months').format('YYYYMMDD'),
        endTime: moment(new Date()).format('YYYYMMDD')
      },
      basicData: {
        num: 0,
        teep: 100,
        oee: 100,
        runTime: 0,
        standbyTime: 0,
        stopTime: 0
      },
      oeeSelect: {
        teepName: [],
        teepValue: [],
        oeeName: [],
        oeeValue: []
      },
      gzwxNum: 0
    }
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
    this.userId = this.GetQueryString('userId')
    this.initTree()
  },
  mounted() {
    const that = this
    this.myChartDashboard1()
    this.myChartDashboard2()
    this.getTEEPLine()
    this.getTEEPLine2()
    this.queryRepairRecord()
    setTimeout(() => {
      this.basicData.teep = 0
      this.basicData.oee = 0
      const basicData = this.basicData
      const teep = basicData.teep < 60 ? '一般' : basicData.teep >= 60 && basicData.teep < 75 ? '国内领先' : '世界先进'
      const oee = basicData.oee < 70 ? '一般' : basicData.oee >= 70 && basicData.oee < 85 ? '国内领先' : '世界先进'
      this.myChart3.setOption({
        series: [{
          name: '设备完全有效生产率',
          detail: {
            backgroundColor: basicData.teep < 60 ? '#FCAD00' : basicData.teep >= 60 && basicData.teep < 75 ? '#2FC25B' : '#1890FF',
            formatter: function (value) {
              return '{unit|' + teep + '}'
            },
            rich: {
              unit: {
                fontSize: 10,
                fontWeight: 400,
                color: '#FFFFFF'
              }
            }
          },
          data: [
            {
              value: this.basicData.teep,
              name: 'TEEP'
            }
          ]
        }]
      })
      this.myChart4.setOption({
        series: [{
          name: '设备综合效率',
          detail: {
            backgroundColor: basicData.oee < 70 ? '#FCAD00' : basicData.oee >= 70 && basicData.oee < 85 ? '#2FC25B' : '#1890FF',
            formatter: function (value) {
              return '{unit|' + oee + '}'
            },
            rich: {
              unit: {
                fontSize: 10,
                fontWeight: 400,
                color: '#FFFFFF'
              }
            }
          },
          data: [
            {
              value: this.basicData.oee,
              name: 'OEE'
            }
          ]
        }]
      })
      this.getAppOeeList()
    }, 1000)
    // 改变窗口大小时重新设置
    window.addEventListener('resize', () => {
      // that.myChart1.resize()
      // that.myChart2.resize()
      // that.myChart3.resize()
      // that.myChart4.resize()
      that.myChart5.resize()
      that.myChart6.resize()
    })
  },
  methods: {
    // 分解地址栏参数
    GetQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = this.$route.fullPath.substr(1).match(reg)
      if (r != null) return decodeURIComponent(r[2])
      return null
    },
    // 设备树主级
    initTree() {
      let dataset = {
        tenantId: this.tenantId,
        id: ''
      }
      this.oneTreeVisble = false
      util.postform('departmentNew/queryLazyTrees', dataset).then((res) => {
        this.oneTreeVisble = true
        console.log(res)
        let data = res.data.pubTreeInfos;
        data.forEach(element => {
          element.key = element.id
          element.title = element.name
          if (element.isDep < 1 && element.isEqu < 1) {
              element.isLeaf = true;
          } else {
              element.isLeaf = false;
          }
          if (element.type === 'dep') {
            element.scopedSlots = { icon: 'smile' }
          } else {
            element.scopedSlots = { icon: 'custom' }
          }
        });
        this.deviceTreeList = data
      })
    },
    // 设备树子集
    dynamicLoadingTree(item) {
      let dataset = {
        id: item.id,
        modeType: 4,
        tenantId: this.tenantId
      }
      util.postform('departmentNew/queryLazyTrees', dataset).then((res) => {
        console.log(res)
        let data = res.data.pubTreeInfos;
        data.forEach(element => {
            element.key = element.id;
            element.title = element.name;
            if (element.type == 'equ' || (element.type == 'dep' && element.isDep < 1 && element.isEqu < 1)) {
                element.isLeaf = true
            } else {
              element.isLeaf = false
            }
            if (element.type === 'dep') {
              element.scopedSlots = { icon: 'smile' }
            } else {

              element.scopedSlots = { icon: 'custom', selected: element.componentFlag > 0 ? true : false }
            }
        });
        item.children = data
        this.deviceTreeList = [...this.deviceTreeList]
        this.$forceUpdate()
      }).catch(err => {
        this.$message.error(err.message);
      })
    },
    oneTree() {
      this.initTree()
    },
    // 展开设备树
    expandTree(item) {
      this.dynamicLoadingTree(item)
    },
    // 选中设备树的回调
    treeSelect(val, data) {
      if (val.length > 0) {
        if (data.type == 'equ') {
          this.objectId = data.id
          this.objectName = data.name
          this.organizationId = data.parentId
          this.organizationName = data.parentName
          this.query()
          this.getAppOeeList()
        } else {
          this.objectId = ''
          this.objectName = ''
          this.organizationId = data.id
          this.organizationName = data.name
        }
      } else {
        this.objectId = ''
        this.objectName = ''
        this.organizationId = ''
        this.organizationName = ''
      }
    },
    onChange(date, dateString) {
      console.log(date, dateString)
      this.beginTime = moment(date[0]).format('YYYYMMDD')
      this.endTime = moment(date[1]).format('YYYYMMDD')
    },
    exportExcel(){
    },
    selectClick() {
      this.query()
      this.getAppOeeList()
    },
    basicsSelectClick(type) {
      this.basicsType = type
      if (type === 1) {
        this.beginTime = moment(new Date()).subtract(1, 'months').format('YYYYMMDD')
        this.endTime = moment(new Date()).format('YYYYMMDD')
      } else if (type === 2) {
        this.beginTime = moment(new Date()).subtract(3, 'months').format('YYYYMMDD')
        this.endTime = moment(new Date()).format('YYYYMMDD')
      } else if (type === 3) {
        this.beginTime = moment(new Date()).subtract(6, 'months').format('YYYYMMDD')
        this.endTime = moment(new Date()).format('YYYYMMDD')
      } else if (type === 4) {
        this.beginTime = moment(new Date()).subtract(12, 'months').format('YYYYMMDD')
        this.endTime = moment(new Date()).format('YYYYMMDD')
      }
      // this.query()
      // this.getAppOeeList()
    },
    query() {
      const dataset = {
        id: this.objectId,
        beginTime: this.beginTime,
        endTime: this.endTime
      }
      util.post('appEquipment/queryAppEquInfo', dataset).then((reg) => {
        // 判断该设备是否属于该租户
        reg[0].partNumber = reg[0].partNumber || 0 // 消耗备件
        reg[0].taskNumber = reg[0].taskNumber || 0 // 点巡检
        reg[0].repNumber = reg[0].repNumber || 0 // 检维修
        this.equipName = reg[0].objectName
        this.orgName = reg[0].depName
        // orgName;
        this.reg = reg[0]
        const downDate = []
        const downNum = []
        const downTime = []
        if (reg[0].dataset.length > 0) {
          reg[0].dataset.forEach((element, index) => {
            element.monthTime ? downDate.push(dateFormat01(element.monthTime, 'month')) : ''
            element.stopNumber ? downNum.push(element.stopNumber) : ''
            element.stopTime ? element.stopTime = (element.stopTime / 60).toFixed(2) : ''
            element.stopTime ? downTime.push(element.stopTime) : ''
          })
          // 人力成本
          this.reg.endPlanTaskNum = reg[0].endPlanTaskNum || 0
          this.reg.YZWX = reg[0].YZWX || 0
          this.reg.endTaskNum = reg[0].endTaskNum || 0
          this.reg.totalTime = (this.reg.endPlanTaskNum || 0) + (this.reg.YZWX || 0) + (this.reg.endTaskNum || 0)
          // 停机时长及次数分析
          this.downDate = downDate
          this.downNum = downNum
          this.downTime = downTime
        }
        console.log(this.reg)
        setTimeout(() => {
          this.myChartAxis()
          this.myChartPie()
        }, 100)
      }).catch((err) => {
        if (err.data.message == '设备id不能为空') {
          this.$message.error('请从左边设备树选择设备！');
        } else {
          this.$message.error(err.data.message);
        }
      })
    },
    // 初始化界面数据
    getAppOeeList() {
      const dataset = {
        beginTime: this.beginTime,
        endTime: this.endTime,
        tenantId: this.tenantId,
        objectId: this.objectId
      }
      util.postform('ThingRealTimeStatistics/appOee', dataset).then((res) => {
        if (res.code === 200) {
          this.refreshTime = moment().format('MM-DD HH:mm')
          const basicData = {
            num: 0,
            teep: Math.ceil(Number(res.data.teep) * 100),
            oee: Math.ceil(Number(res.data.oee) * 100),
            runTime: (res.data.runTime / 60).toFixed(1),
            standbyTime: (res.data.standbyTime / 60).toFixed(1),
            stopTime: (res.data.stopTime / 60).toFixed(1)
          }
          const oeeSelect = {
            teepName: [],
            teepValue: [],
            oeeName: [],
            oeeValue: []
          }
          res.data && res.data.oeedatas && res.data.oeedatas.forEach((ele, index) => {
            oeeSelect.teepName.push(ele.time)
            oeeSelect.teepValue.push(ele.hasOwnProperty('teep') ? Math.floor(Number(ele.teep) * 100) : 0)
            oeeSelect.oeeName.push(ele.time)
            oeeSelect.oeeValue.push(ele.hasOwnProperty('oee') ? Math.floor(Number(ele.oee) * 100) : 0)
          })
          this.basicData = basicData
          this.oeeSelect = oeeSelect
          this.myChartDashboard1()
          this.myChartDashboard2()
          this.getTEEPLine()
          this.getTEEPLine2()
        }
      }).catch((err) => {
        this.$message.error(err.response.data.message)
        console.log(err)
      })
    },
    myChartAxis() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart1 = this.$echarts.init(document.getElementById('myChart'))
      // 绘制图表
      this.myChart1.setOption({
        color: ['#f87500', '#2fc25b'],
        backgroundColor: '', // 背景颜色透明
        tooltip: {
          trigger: 'axis'
        },
        legend: {
          icon: 'circle',
          data: ['停机次数', '停机时长'],
          // orient: 'horizontal', // 'vertical'
          right: '15px', // 'center' | 'left' | {number},
          itemGap: 20
        },
        grid: {
          top: '60px',
          left: '8px',
          right: '12px',
          bottom: '15px',
          containLabel: true
        },
        xAxis: {
          type: 'category',
          boundaryGap: false,
          data: this.downDate,
          show: true,
          splitLine: {
            show: false
          },
          axisLine: {
            lineStyle: {
              color: '#b2b2b2'
            }
          },
          axisLabel: {
            show: true,
            textStyle: {
              color: '#8f8f8f'
            }
          },
          axisTick: {
            show: false
          }
        },
        yAxis: [
          {
            type: 'value',
            scale: true,
            name: '',
            min: 0,
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#b2b2b2'
              }
            },
            axisLabel: {
              show: false
            },
            axisTick: {
              show: false
            }
          },
          {
            type: 'value',
            scale: true,
            name: '',
            min: 0,
            splitLine: {
              show: false
            },
            axisLine: {
              lineStyle: {
                color: '#b2b2b2'
              }
            },
            axisLabel: {
              show: false
            },
            axisTick: {
              show: false
            }
          }
        ],
        series: [
          {
            name: '停机次数',
            type: 'line',
            data: this.downNum
          },
          {
            name: '停机时长',
            type: 'line',
            yAxisIndex: 1,
            data: this.downTime
          }
        ]
      })
    },
    myChartPie() {
      // 基于准备好的dom，初始化echarts实例
      this.myChart2 = this.$echarts.init(document.getElementById('myChart2'))
      // 绘制图表
      this.myChart2.setOption({
        tooltip: {
          position: [150, 50],
          trigger: 'item',
          formatter: '{a} <br/>{b}: {c} ({d}%)'
        },
        color: ['#2fc25b', '#40a9ff', '#f87500'],
        grid: {
          top: '25%',
          containLabel: true
        },
        series: [
          {
            name: '类型',
            type: 'pie',
            radius: ['40%', '60%'],
            avoidLabelOverlap: false,
            label: {
              normal: {
                show: false,
                position: 'right'
              }
            },
            labelLine: {
              normal: {
                show: false
              }
            },
            data: [
              { value: this.reg.endPlanTaskNum || 0, name: '检修计划' },
              { value: this.reg.YZWX || 0, name: '预知维修' },
              { value: this.reg.endTaskNum || 0, name: '故障维修' }
            ]
          }
        ]
      })
    },
    myChartDashboard1() {
      const basicData = this.basicData
      const name = basicData.teep < 60 ? '一般' : basicData.teep >= 60 && basicData.teep < 75 ? '国内领先' : '世界先进'
      // 基于准备好的dom，初始化echarts实例
      this.myChart3 = this.$echarts.init(document.getElementById('myChart3'))
      // 绘制图表
      this.myChart3.setOption({
        tooltip: {
          position: ['50%', '-100%'],
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#5AD8A6'
            }
          },
          backgroundColor: 'rgba(0,0,0,0.80)',
          borderColor: 'rgba(0,255,244,0.4)',
          borderWidth: 1,
          textStyle: {
            color: '#FFF'
          },
          formatter: '{a} <br/>{b} : {c}%'
        },
        series: [
          {
            type: 'gauge',
            name: '设备完全有效生产率',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            radius: '90%',
            itemStyle: {
              color: '#1890FF'
            },
            progress: {
              show: true,
              roundCap: true,
              width: 7
            },
            pointer: {
              icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
              length: '55%',
              width: 5,
              offsetCenter: [0, '5%']
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 7
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              backgroundColor: basicData.teep < 60 ? '#FCAD00' : basicData.teep >= 60 && basicData.teep < 75 ? '#2FC25B' : '#1890FF',
              width: '100%',
              lineHeight: 9,
              height: 9,
              borderRadius: 9,
              offsetCenter: [0, '70%'],
              formatter: function (value) {
                return '{unit|' + name + '}'
              },
              rich: {
                unit: {
                  fontSize: 10,
                  fontWeight: 400,
                  color: '#FFFFFF'
                }
              }
            },
            data: [
              {
                value: this.basicData.teep,
                name: 'TEEP'
              }
            ]
          }
        ]
      })
    },
    myChartDashboard2() {
      const basicData = this.basicData
      const name = basicData.oee < 70 ? '一般' : basicData.oee >= 70 && basicData.oee < 85 ? '国内领先' : '世界先进'
      // 基于准备好的dom，初始化echarts实例
      this.myChart4 = this.$echarts.init(document.getElementById('myChart4'))
      // 绘制图表
      this.myChart4.setOption({
        tooltip: {
          position: ['-100%', '-100%'],
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#5AD8A6'
            }
          },
          backgroundColor: 'rgba(0,0,0,0.80)',
          borderColor: 'rgba(0,255,244,0.4)',
          borderWidth: 1,
          textStyle: {
            color: '#FFF'
          },
          formatter: '{a} <br/>{b} : {c}%'
        },
        series: [
          {
            type: 'gauge',
            name: '设备综合效率',
            startAngle: 180,
            endAngle: 0,
            min: 0,
            max: 100,
            radius: '90%',
            itemStyle: {
              color: '#2FC25B'
            },
            progress: {
              show: true,
              roundCap: true,
              width: 7
            },
            pointer: {
              icon: 'path://M2090.36389,615.30999 L2090.36389,615.30999 C2091.48372,615.30999 2092.40383,616.194028 2092.44859,617.312956 L2096.90698,728.755929 C2097.05155,732.369577 2094.2393,735.416212 2090.62566,735.56078 C2090.53845,735.564269 2090.45117,735.566014 2090.36389,735.566014 L2090.36389,735.566014 C2086.74736,735.566014 2083.81557,732.63423 2083.81557,729.017692 C2083.81557,728.930412 2083.81732,728.84314 2083.82081,728.755929 L2088.2792,617.312956 C2088.32396,616.194028 2089.24407,615.30999 2090.36389,615.30999 Z',
              length: '55%',
              width: 5,
              offsetCenter: [0, '5%']
            },
            axisLine: {
              roundCap: true,
              lineStyle: {
                width: 7
              }
            },
            axisTick: {
              show: false
            },
            splitLine: {
              show: false
            },
            axisLabel: {
              show: false
            },
            title: {
              show: false
            },
            detail: {
              backgroundColor: basicData.oee < 70 ? '#FCAD00' : basicData.oee >= 70 && basicData.oee < 85 ? '#2FC25B' : '#1890FF',
              width: '100%',
              lineHeight: 9,
              height: 9,
              borderRadius: 9,
              offsetCenter: [0, '70%'],
              formatter: function (value) {
                return '{unit|' + name + '}'
              },
              rich: {
                unit: {
                  fontSize: 10,
                  fontWeight: 400,
                  color: '#FFFFFF'
                }
              }
            },
            data: [
              {
                value: this.basicData.oee,
                name: 'OEE'
              }
            ]
          }
        ]
      })
    },
    // TEEP
    getTEEPLine() {
      // 绘制图表
      this.myChart5 = this.$echarts.init(document.getElementById('myChart5'))
      // 指定图表的配置项和数据
      this.myChart5.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#5AD8A6'
            }
          },
          backgroundColor: 'rgba(0,0,0,0.80)',
          borderColor: 'rgba(0,255,244,0.4)',
          borderWidth: 1,
          textStyle: {
            color: '#FFF'
          },
          formatter: '{b0}<br/>{a0}: {c0}%'
        },
        legend: {
          left: '2%',
          top: '0%',
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            color: '#333333'
          },
          data: ['TEEP']
        },
        grid: {
          borderWidth: 0,
          top: '20%',
          left: '4%',
          right: '4%',
          bottom: '8%',
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: {
          type: 'category',
          data: this.oeeSelect.teepName.slice(0, 60).reverse()
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value} %'
          }
        },
        series: [
          {
            name: 'TEEP',
            smooth: true,
            showSymbol: false,
            data: this.oeeSelect.teepValue.slice(0, 60).reverse(),
            itemStyle: {
              normal: {
                color: '#1890FF',
                barBorderRadius: 0
              }
            },
            type: 'line'
          }
        ]
      })
    },
    getTEEPLine2() {
      // 绘制图表
      this.myChart6 = this.$echarts.init(document.getElementById('myChart6'))
      // 指定图表的配置项和数据
      this.myChart6.setOption({
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross',
            label: {
              backgroundColor: '#5AD8A6'
            }
          },
          backgroundColor: 'rgba(0,0,0,0.80)',
          borderColor: 'rgba(0,255,244,0.4)',
          borderWidth: 1,
          textStyle: {
            color: '#FFF'
          },
          formatter: '{b0}<br/>{a0}: {c0}%'
        },
        legend: {
          left: '2%',
          top: '0%',
          itemWidth: 8,
          itemHeight: 8,
          textStyle: {
            color: '#333333'
          },
          data: ['OEE']
        },
        grid: {
          borderWidth: 0,
          top: '20%',
          left: '4%',
          right: '4%',
          bottom: '8%',
          textStyle: {
            color: '#fff'
          }
        },
        xAxis: {
          type: 'category',
          data: this.oeeSelect.oeeName.reverse()
        },
        yAxis: {
          type: 'value',
          axisLabel: {
            show: true,
            interval: 'auto',
            formatter: '{value} %'
          }
        },
        series: [
          {
            name: 'OEE',
            smooth: true,
            showSymbol: false,
            data: this.oeeSelect.oeeValue.reverse(),
            itemStyle: {
              normal: {
                color: '#1890FF',
                barBorderRadius: 0
              }
            },
            type: 'line'
          }
        ]
      })
    },
    ConsumeSparePartsClick() {
      this.$router.push({
        name: 'ConsumeSpareParts',
        query: {
          objectName: this.reg.objectName,
          c_standard: this.reg.c_standard
        }
      })
    },
    HumanCostClick() {
      this.$router.push({
        name: 'HumanCost',
        query: {
          objectName: this.reg.objectName,
          c_standard: this.reg.c_standard
        }
      })
    },
    CheckRecordClick(type) {
      // window.parent.postMessage({msg:type}, '*')
      if (type === '资产台帐') {
        this.labelVisble3 = true
      }
    },
    ExceptionRecordClick() {
      this.$router.push({
        name: 'ExceptionRecord'
      })
    },
    ForTheTallyClick() {
      this.$router.push({
        name: 'ForTheTally',
        query: {
          objectName: this.reg.objectName,
          c_standard: this.reg.c_standard
        }
      })
    },
    ForTheMaintenanceClick() {
      this.$router.push({
        name: 'ForTheMaintenance',
        query: {
          objectName: this.reg.objectName,
          c_standard: this.reg.c_standard
        }
      })
    },
    CheckTheTaskClick() {
      this.$router.push({
        name: 'CheckTheTask',
        query: {
          objectName: this.reg.objectName,
          c_standard: this.reg.c_standard,
          qrCode: this.objectId,
          objectId: this.reg.objectId
        }
      })
    },
    // 查询是否有故障维修
    queryRepairRecord() {
      const dataset = {
        tenantId: this.tenantId,
        objectId: this.objectId
      }
      util.post('RepairAppNew/queryRepairRecord', dataset).then((res) => {
        if (res.code === 200) {
          this.gzwxNum = res.data
        }
      }).catch((err) => {
        this.$message.error(err.response.data.message)
        console.log(err)
      })
    },
    RepairClick() {
      if (this.gzwxNum > 0) {
        Dialog.confirm({
          title: '消息提示',
          message: '当前设备有未完成的故障维修，是否继续报修？'
        })
          .then(() => {
            // on confirm
            const equInfo = {
              id: this.reg.objectId,
              name: this.reg.objectName,
              deptName: this.reg.depName
            }
            currency.setLocalStorageItem('equipment', JSON.stringify(equInfo))
            this.$router.push({
              name: 'FaultRepairService'
            })
          })
          .catch(() => {
            // on cancel
          })
      } else {
        const equInfo = {
          id: this.reg.objectId,
          name: this.reg.objectName,
          deptName: this.reg.depName
        }
        currency.setLocalStorageItem('equipment', JSON.stringify(equInfo))
        this.$router.push({
          name: 'FaultRepairService'
        })
      }
    },
    updataWin() {
      this.labelVisble3 = !this.labelVisble3
    }
  },
}
</script>

<style scoped lang="less">
.numberOfDevices {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
}
.numberOfDevices-title {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.title-icon {
  width: 4px;
  height: 16px;
  background: #1890ff;
  display: inline-block;
  margin-right: 12px;
}
.query-button {
  padding: 0px 21px 0px 17px;
  background: #ffffff;
}
.numberOfDevices-content {
  background: #ffffff;
  width: 100%;
  height: calc(100% - 185px);
  display: flex;
  padding: 32px 21px 36px 17px;
}
.flush {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-left {
  width: 220px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  flex-shrink: 0;
  .content-left-title {
    height: 48px;
    width: 100%;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-btnGroup {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-hint {
    margin-top: 17px;
    font-size: 14px;
    font-weight: 400;
    color: #f87500;
    padding: 6px 7px;
    background: #fffbe6;
  }
  .content-left-tree  {
    height: calc(100% - 55px);
  }
  .content-left-tree2  {
    height: calc(100% - 115px);
  }
}
.content-center {
  height: 100%;
  width: 350px;
  flex-shrink: 0;
}
.content-right {
  height: 100%;
  width: calc(100% - 613px);
  margin-left: 22px;
  overflow: auto;
}
.conditionQuery-head {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.conditionQuery-head-left {
  display: flex;
  align-items: center;
  // width: 100%;
  flex-wrap: wrap;
}
.conditionQuery-head-item {
  display: flex;
  align-items: center;
  margin-right: 40px;
  margin-bottom: 16px;
}
.flexShrink {
  flex-shrink: 0;
}
.marginRight12 {
  margin-right: 12px;
}
.marginl20 {
  margin-left: 20px;
}
.width150 {
  width: 150px;
}
.width220 {
  width: 220px;
}
.fullLifeCycleOfTheDevice-content {
  height: 100%;
  overflow: auto;
}
.f-content-top {
  position: relative;
}
.f-content-top-bg {
  width: 100%;
  height: 84px;
  background: #1890ff;
  position: absolute;
  top: 0;
}
.f-content-top-item {
  background-color: #FFFFFF;
  border-radius: 4px;
  padding: 15px 14px;
  font-size: 12px;
  font-weight: 400;
  color: #333333;
  position: relative;
  z-index: 2px;
}
.f-content-top-item-title {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  margin-bottom: 2px;
}
.f-content-top-item-subtitle {
  display: flex;
  align-items: center;
  margin-bottom: 12px;
}
.f-content-top-item-subtitle-model {
  font-size: 12px;
  font-weight: 700;
  color: #999999;
  margin-right: 4px;
}
.f-content-top-item-subtitle-type {
  font-size: 10px;
  font-weight: 400;
  color: #1890FF;
  margin-right: 4px;
  background: #e6f7ff;
  border-radius: 2px;
  padding: 1px 2px;
}
.marginb2 {
  margin-bottom: 2px;
}
.f-content-top-img {
  width: 16px;
  height: 16px;
}
.f-content-top-img2 {
  width: 12px;
  height: 12px;
}
.marginlr6 {
  margin: 0px 6px;
}
.f-content-analysis {
  margin: 12px 0px;
  padding: 12px;
  background: #ffffff;
  border-radius: 4px;
}
.f-content-MaxTitle {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-size: 15px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 24px;
}
.f-content-time {
  display: flex;
  background: #fff;
  padding-bottom: 12px;
}
.fault_time {
  flex: 1;
  padding: 0px 12px;
}
.fault_time:first-of-type {
  padding-left: 0px;
}
.fault_time:last-of-type {
  padding-right: 0px;
}
.f-content-availability {
  padding: 0px 0px 6px;
  font-size: 12px;
  font-weight: 400;
  color: #2fc25b;
}
.f-content-progress-bar {
  display: flex;
  align-items: center;
  width: 100%;
  height: 12px;
  background: #F0F2F5;
  margin-bottom: 8px;
}
.time-flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.time-flex-item {
  width: 67px;
  height: 34px;
  border-radius: 17px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 13px;
  font-weight: 400;
  color: #666666;
  cursor: pointer;
}
.f-content-echarts {
  display: flex;
  height: 72px;
}
.f-content-echarts-item {
  width: 50%;
  height: 100%;
  border-right: 1px solid #E8E8E8;
  display: flex;
  align-items: center;
  position: relative;
}
.f-content-echarts-item:last-of-type {
  border-right: none;
  padding-left: 12px;
}
.f-content-echarts-title {
  font-size: 9px;
  font-weight: 400;
  color: #999999;
  position: absolute;
  top: 0;
}
.f-content-echarts-name {
  font-size: 14px;
  font-weight: 500;
  color: #1890ff;
  position: absolute;
  top: 20px;
}
.f-content-echarts-num {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
  position: absolute;
  top: 50px;
}
.f-content-echarts-algorithm {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 56px;
  height: 17px;
  background: #faad14;
  border-radius: 9px;
  font-size: 10px;
  font-weight: 400;
  color: #ffffff;
  position: absolute;
  top: 55px;
  right: 22px;
}
.f-content-echarts-unit {
  font-size: 10px;
  font-weight: 400;
  color: #333333;
}
.abbreviations {
  font-size: 14px;
  font-weight: 700;
  color: #333333;
}
.abbreviations_explain {
  font-size: 11px;
  font-weight: 400;
  color: #666666;
}
.padingb2 {
  padding-bottom: 2px;
}
.border-r1 {
  border-right: 1px solid #e8e8e8;
}
.setinterval {
  font-size: 24px;
  color: #1890ff;
  font-weight: 700;
}
.unit {
  font-size: 11px;
  font-weight: 400;
  color: #1890ff;
}
.f-content-maintenance-time {
  background-color: #ffffff;
  margin-bottom: 24px;
}
.f-content-maintenance-time-title {
  font-size: 15px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 12px;
}
.rectangle_blue {
  display: inline-block;
  width: 4px;
  height: 12px;
  background: #1890ff;
  vertical-align: middle;
  margin-bottom: 3px;
  margin-right: 8px;
}
.circle {
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #2fc25b;
  border-radius: 50%;
  vertical-align: middle;
  margin-bottom: 2px;
}
.f-content-maintenance-line {
  width: 100%;
  height: 160px;
}
.f-content-human-cost {
  background: #fff;
  /* padding: 16px; */
  height: 272px;
  margin-bottom: 8px;
}
.human-cost-t {
  display: flex;
}
.human-cost-t-item-l {
  flex: 1;
  padding: 12px;
  background: #E6F7FF;
  border-radius: 4px;
  margin-right: 6px;
  position: relative;
}
.human-cost-t-item-r {
  flex: 1;
  padding: 12px;
  background: #FFF7E6;
  border-radius: 4px;
  margin-left: 6px;
  position: relative;
}
.item-img {
  width: 64px;
  height: 48px;
  position: absolute;
  bottom: 0;
  right: 0;
}
.human-cost-t-item-title {
  padding-bottom: 8px;
  font-size: 13px;
  font-weight: 500;
  color: #333333;
  display: flex;
  align-items: center;
}
.human-cost-t-item-num {
  font-size: 18px;
  font-weight: 700;
  color: #333333;
}
.human-cost-t-item-unit {
  font-size: 13px;
}
.human-cost-b {
  height: 172px;
  display: flex;
}
.cost-bottom {
  padding-top: 10px;
}
.cost-bottom-item {
  margin-top: 12px;
}
.cost-bottom-item-title {
  font-size: 12px;
  font-weight: 500;
  color: #333333;
}
.cost-bottom-item-num {
  font-size: 15px;
  font-weight: 700;
  color: #2fc25b;
  padding-left: 12px;
  padding-top: 4px;
}
.search_list {
  background: #fff;
  margin: 12px 0px;
  padding: 16px 6px 0px;
  display: flex;
  text-align: center;
  flex-wrap: wrap;
  border-radius: 4px;
}
.marginb10 {
  margin-bottom: 10px !important;
}
.marginb60 {
  margin-bottom: 60px !important;
}
.img-border-kuang {
  width: 40px;
  height: 40px;
  background: #1890ff;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0px auto 6px;
}
.search-list-img {
  width: 24px;
  height: 24px;
}
.search_list_item {
  width: 20%;
  padding-bottom: 16px;
  font-size: 14px;
  font-weight: 500;
  color: #333333;
}
.operation-bottom {
  position: fixed;
  left: 0;
  right: 0;
  bottom: 0;
  height: 50px;
  background: #ffffff;
  box-shadow: 0px -3px 6px 0px rgba(0, 0, 0, 0.06);
  display: flex;
  padding-bottom: constant(safe-area-inset-bottom);
  padding-bottom: env(safe-area-inset-bottom);
}
.bottom-item-w {
  flex: 3;
  background: #ffffff;
  text-align: center;
  font-size: 10px;
  font-weight: 500;
  color: #333333;
}
.bottom-item-w-num {
  font-size: 22px;
  font-weight: 700;
  color: #1890ff;
  position: relative;
}
.stateCircle {
  position: absolute;
  top: 0;
  display: inline-block;
  width: 8px;
  height: 8px;
  background: #f5222d;
  border-radius: 50%;
}
.footer-buttom {
  flex: 3;
  background: #1890ff;
  font-size: 17px;
  font-weight: 700;
  color: #ffffff;
  display: flex;
  align-items: center;
  justify-content: center;
}
.OEEMonitoring-content-echarts {
  margin: 12px 0px;
  padding: 12px;
  height: 307px;
  background: #ffffff;
  border-radius: 4px;
}
.OEEMonitoring-content-echarts-name {
  font-size: 15px;
  font-weight: 500;
  color: #333333;
  margin-bottom: 12px;
}
.OEEMonitoring-content-echarts-chart {
  width: 100%;
  height: calc(270px - 23px);
  position: relative;
}
.no-data-err {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* 弹窗 */
.jyws-popup {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  /* height: calc(100% - safe-area-inset-bottom);
  height: calc(100% - safe-area-inset-bottom); */
  z-index: 3;
  background: rgba(0,0,0,0.45);
}
.jyws-popup-content {
  width: 100%;
  height: 80%;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  position: absolute;
  left: 0px;
  right: 0px;
  bottom: 0px;
}
.jyws-popup-content-data {
  padding: 16px;
  height: calc(100% - 32px);
}
.screening-label-flex {
  font-size: 15px;
  font-weight: 700;
  color: #333333;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
.screening-label-flex-img {
  width: 20px;
  height: 20px;
  cursor: pointer;
}
.screening-label-list {
  padding-top: 16px;
  height: calc(100% - 38px);
  overflow: auto;
  font-size: 13px;
  font-weight: 400;
  color: #666666;
}
.screening-label-title {
  font-size: 15px;
  font-weight: 700;
  color: #1890ff;
}
.margint7 {
  margin-top: 4px;
}
.margint8 {
  margin-top: 8px;
}
.margint12 {
  margin-top: 12px;
}
.margint20 {
  margin-top: 20px;
}
.warm-prompt {
  padding: 8px;
  background: #fffbe6;
  border: 1px solid #ffe58f;
  border-radius: 2px;
  font-size: 14px;
  font-weight: 400;
  color: #f87500;
}
</style>

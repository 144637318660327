<template>
  <div class="visible">
    <a-modal
      :visible="visible"
      :title="title"
      :dialog-style="{ top: width == '100%' ? '0px' : '100px' }"
      :width="width"
      :closable="showClose"
      :maskClosable="closeModal"
      :mask="modal"
      @cancel="updataClose"
      style="border-radius: 16px;white-space:pre;">
      <div v-if="amplifier" style="width: 56px;height: 56px;position: absolute;top: 0px;right: 56px;display: flex;align-items: center;justify-content: center;" @click="amplifierClick"> 
        <a-icon type="fullscreen" style="color: #FFFFFF;font-size: 16px;" />
      </div>
      <slot/>
      <span v-if="bottomName" slot="footer" class="dialog-footer">
        <div v-if="understandShow" style="float: left;color: #FF4E28;font-size: 14px;line-height: 34px;cursor: pointer;" @click="particulars"><img style="vertical-align: text-bottom;margin-right: 8px;" src="https://common.xxpie.com/icon-InformationMark.svg">{{ understandShow }}</div>
        <a-button v-if="choosePhotos" style="border-radius: 2px;padding: 0px 23px;" @click="updataimg"><img style="vertical-align: text-bottom;margin-right: 8px;" src="https://common.xxpie.com/icon-blackReplace.svg">重新上传</a-button>
        <a-button v-if="deleteCard" style="border-radius: 2px;padding: 0px 23px;" @click="deleteCards"><img style="vertical-align: text-bottom;margin-right: 8px;" src="https://common.xxpie.com/icon-blackTrash.svg">{{ deleteCard }}</a-button>
        <a-button v-if="bottomSaveAs" style="border-radius: 2px;padding: 0px 23px;" @click="bottomSaveAsWin"><img style="vertical-align: text-bottom;margin-right: 8px;" src="https://common.xxpie.com/Icon-SaveAs.svg">{{ bottomSaveAs }}</a-button>
        <a-button v-if="roger" style="border-radius: 2px;padding: 0px 23px;" @click="updataClose">{{ roger }}</a-button>
        <a-button style="border-radius: 2px;padding: 0px 23px;" :type="typePrimary" @click="updataWin">{{ bottomName }}</a-button>
      </span>
      <span v-else slot="footer" class="dialog-footer" style="overflow: hidden;display: flex;align-items: center;justify-content: space-between;">
        <div v-if="understandShow" style="color: #FF4E28;font-size: 14px;line-height: 34px;cursor: pointer;" @click="particulars"><img style="vertical-align: text-bottom;margin-right: 8px;" src="https://common.xxpie.com/icon-InformationMark.svg">{{ understandShow }}</div>
        <a-button v-if="packing" style="border-radius: 2px;padding: 0px 23px;" @click="packingWin"><img style="vertical-align: text-bottom;margin-right: 8px;" src="https://common.xxpie.com/web_console_album_detail_icon_13.svg">{{ packing }}</a-button>
      </span>
    </a-modal>
  </div>
</template>

<script>
export default {
  name: 'JywsDialog',
  props: {
    title: {
      type: String,
      default: ''
    },
    typePrimary:{
      type: String,
      default: 'primary'
    },
    visible: {
      type: Boolean,
      default: false
    },
    width: {
      type: String,
      default: '448px'
    },
    bottomName: {
      type: String,
      default: '保存'
    },
    bottomSaveAs: {
      type: String,
      default: ''
    },
    // 压缩包
    packing: {
      type: String,
      default: ''
    },
    // 重新上传图片
    choosePhotos: {
      type: String,
      default: ''
    },
    // 了解展示方式
    understandShow: {
      type: String,
      default: ''
    },
    // 移除按钮
    deleteCard: {
      type: String,
      default: ''
    },
    // 取消
    roger: {
      type: String,
      default: ''
    },
    // 是否点model 关闭
    closeModal: {
      type: Boolean,
      default: false
    },
    // 是否显示关闭按钮
    showClose: {
      type: Boolean,
      default: true
    },
    // 是否隐藏遮罩
    modal: {
      type: Boolean,
      default: true
    },
    amplifier: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
    }
  },
  computed: {
    dialogVisible() {
      return this.visible
    }
  },
  methods: {
    updataClose(done) {
      console.log(done)
      this.$emit('updataClose')
    },
    bottomSaveAsWin() {
      this.$emit('bottomSaveAsWin')
    },
    updataWin() {
      this.$emit('updataWin')
    },
    updataimg() {
      this.$emit('updataimg')
    },
    deleteCards() {
      this.$emit('deleteCards')
    },
    particulars() {
      this.$emit('particulars')
    },
    packingWin() {
      this.$emit('packingWin')
    },
    amplifierClick() {
      this.$emit('amplifierClick')
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style>
</style>

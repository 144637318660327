<!--
 * @Author: your name
 * @Date: 2022-04-22 11:51:01
 * @LastEditTime: 2022-06-02 15:04:15
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/views/businessTransaction/businessTransaction.vue
-->
<template>
  <div class="businessTransaction">
    <div style="width: 100%;height: 100%;overflow: auto;">
      <div class="businessTransaction-title">
        <span class="title-icon">
        </span>
        <span>企业交易记录</span>
      </div>
      <div class="query-button">
        <conditionQuery :headData="headData" @onHandleChange="onHandleChange" @onInputChange="onInputChange" @onSelectClick="onSelectClick"/>
      </div>
      <div class="businessTransaction-content">
        <div class="content-right">
          <div style="height: calc(100% - 32px);">
            <a-table :scroll="{ y: 400 }" :pagination="false" :loading="loading" :columns="columns" :data-source="dataSource">
              <!-- <a slot="type" slot-scope="text">{{ text == 1 ? '按时间' : '按次数' }}</a>
              <a slot="cycle" slot-scope="text,record">{{text}}{{ record.type == 1 ? '天' : '次' }}</a>
              <a slot="updateTime" slot-scope="text">{{text || '-'}}</a>
              <a slot="schedule" slot-scope="text"><a-progress v-if="text" :percent="text" size="small" /><span v-else>-</span></a>
              <a slot="state" slot-scope="text"><a-badge :color="text == 1 ? '#2FC25B' : '#FAAD14'" :text="text == 1 ? '启用' : '停用'" /></a>
              <a slot="componentExperiences" slot-scope="text"><span :style="text.length > 0 ? 'color: #666666;' : 'color: #FAAD14;'">{{ text.length > 0 ? '预防维修' : '未关联' }}</span></a>
              <a v-if="record.componentExperiences.length > 0" slot="operation" slot-scope="text,record" @click="standardLibraryDetails(record)">详情</a>
              <a v-else slot="operation">-</a> -->
            </a-table>
            <div class="pagination-style">
            <a-pagination size="small"
            :total="pageTotal"
            :defaultCurrent='pageIndex'
            :defaultPageSize='pageSize'
            show-size-changer
            show-quick-jumper
            :show-total="total => `总共 ${total} 条`"
            @change="thePager"
            @showSizeChange="showSizeChange" />
          </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import * as util from '@/core/util'
export default {
  name: 'BusinessTransaction',
  components: {
    conditionQuery
  },
  filters: {
  },
  data() {
    return {
      userId:'',
      tenantId: '',
      headData: [
        { key: 1, duplicate: 'num', name: '流水号', component: 'a-input', placeholder: '请输入流水号', value: '' },
        { key: 2, duplicate: 'name', name: '企业信息', component: 'a-input', placeholder: '请输入企业名称', value: '' },
        { key: 1, duplicate: 'time', name: '时间范围', component: 'a-range-picker', value: '' },
        { key: 3, duplicate: 'type', name: '类型', component: 'a-select', placeholder: '请选择', value: '', provinceData: [
          { key: 1, value: '' , name: '全部' },
          { key: 2, value: '1' , name: '充值' },
          { key: 3, value: '2' , name: '提现' },
          { key: 3, value: '3' , name: '转出' },
          { key: 3, value: '4' , name: '转入' },
          ]
        }
      ],
      loading: false,
      pageIndex: 1,
      pageSize: 10,
      pageTotal: 0,
      columns: [
        {
          title: '流水号',
          dataIndex: 'componentName',
          key: 'componentName',
          ellipsis: true,
        },
        {
          title: '企业信息',
          dataIndex: 'type',
          key: 'type',
          ellipsis: true,
          scopedSlots: { customRender: 'type' }
        },
        {
          title: '时间',
          dataIndex: 'cycle',
          key: 'cycle',
          ellipsis: true,
          scopedSlots: { customRender: 'cycle' }
        },
        {
          title: '操作人',
          dataIndex: 'updateTime',
          key: 'updateTime',
          ellipsis: true,
          scopedSlots: { customRender: 'updateTime' }
        },
        {
          title: '金额（元）',
          dataIndex: 'schedule',
          key: 'schedule',
          ellipsis: true,
          scopedSlots: { customRender: 'schedule' }
        },
        {
          title: '类型',
          dataIndex: 'state',
          key: 'state',
          ellipsis: true,
          scopedSlots: { customRender: 'state' }
        },
        {
          title: '交易方式',
          dataIndex: 'componentExperiences',
          key: 'componentExperiences',
          ellipsis: true,
          scopedSlots: { customRender: 'componentExperiences' }
        },
        {
          title: '备注',
          dataIndex: 'componentExperiences2',
          key: 'componentExperiences2',
          ellipsis: true,
          scopedSlots: { customRender: 'componentExperiences2' }
        }
      ],
      dataSource: [],
    }
  },
  created() {
  },
  mounted() {},
  methods: {
    onHandleChange(item) {
      if (item.duplicate == 'anystandard') {
        this.equipmentData.forEach(ele => {
          if (ele.duplicate == 'anystandard') {
            ele.value = item.value
          }
        })
      } else if (item.duplicate == 'status') {
        this.headData.forEach(ele => {
          if (ele.duplicate == 'status') {
            ele.value = item.value
          }
        })
      } else if (item.duplicate == 'trigger') {
        this.headData.forEach(ele => {
          if (ele.duplicate == 'trigger') {
            ele.value = item.value
          }
        })
      }
    },
    onInputChange(item) {
      if (item.duplicate == 'library') {
        this.associatedData[0].value = item.value
      } else if (item.duplicate == 'devicename') {
        this.equipmentData.forEach(ele => {
          if (ele.duplicate == 'devicename') {
            ele.value = item.value
          }
        })
      } else if (item.duplicate == 'component') {
        this.headData.forEach(ele => {
          if (ele.duplicate == 'component') {
            ele.value = item.value
          }
        })
      }
    },
    onSelectClick() {
      console.log('点击了')
    },
     thePager(page, pageSize) {
      this.pageIndex = page
      this.pageSize = pageSize
      this.queryList()
    },
    showSizeChange(page, pageSize) {
      this.pageIndex = page
      this.pageSize = pageSize
      this.queryList()
    }
  },
}
</script>

<style scoped lang="less">
.businessTransaction {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  padding: 20px;
  display: flex;
}
.businessTransaction-title {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.title-icon {
  width: 4px;
  height: 16px;
  background: #1890ff;
  display: inline-block;
  margin-right: 12px;
}
.query-button {
  padding: 0px 21px 0px 17px;
  background: #ffffff;
}
.businessTransaction-content {
  background: #ffffff;
  width: 100%;
  height: calc(100% - 185px);
  display: flex;
  padding: 32px 21px 36px 17px;
}
.flush {
  font-size: 14px;
  color: #1890ff;
  cursor: pointer;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-left {
  width: 220px;
  height: 100%;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  flex-shrink: 0;
  .content-left-title {
    height: 48px;
    width: 100%;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-btnGroup {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-hint {
    margin-top: 17px;
    font-size: 14px;
    font-weight: 400;
    color: #f87500;
    padding: 6px 7px;
    background: #fffbe6;
  }
  .content-left-tree  {
    height: calc(100% - 55px);
  }
  .content-left-tree2  {
    height: calc(100% - 115px);
  }
}
.content-right {
  height: 100%;
  width: 100%;
  margin-left: 22px;
}
.content-right-button {
  text-align: left;
  margin-bottom: 12px;
}
.marginb24 {
  margin-bottom: 24px;
}
.flex-leftName {
  font-size: 14px;
  text-align: right;
  color: #333333;
  flex: 1;
  margin-right: 24px;
  position: relative;
}
.flex-rightName {
  flex: 3;
}
.associatedTasks-head {
  background: #fffbe6;
  border-radius: 2px;
  padding: 12px;
  font-size: 14px;
  color: #f87500;
  display: flex;
  align-items: center;
}
.icon-img {
  width: 16px;
  height: 16px;
  margin-right: 8px;
}
.pagination-style {
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: end;
}
.information-flex {
  display: flex;
  justify-content: space-between;
  font-size: 14px;
  font-weight: 400;
  color: #333333;
  margin-bottom: 12px;
}
.information-flex1 {
  flex: 1;
  display: flex;
  align-items: center;
}
</style>

<template>
  <div class="safetyAccidentReport">
    <div class="safetyAccidentReport-title">
      <span class="title-icon"> </span>
      <span>安全事故报告</span>
    </div>
    <div class="safetyAccidentReport-content">
      <div class="content-left">
        <!-- 左侧标题 -->
        <div class="content-left-title">
          <div>报告类型</div>
          <div class="flush"
               @click="getOplTypeTree">
            <a href="javascript:;">
              <a-icon type="sync"
                      style="margin-right: 6px" />刷新
            </a>
          </div>
        </div>
        <!-- 左侧树按钮区域 -->
        <div class="content-left-btnGroup flex">
          <a-button-group>
            <a-button @click="addOplType"
                      name="addOplType">新增</a-button>
            <a-button @click="addOplType"
                      name="editOplType">
              修改
            </a-button>
            <a-button @click="delOplType"> 删除 </a-button>
          </a-button-group>
        </div>
        <!-- 左侧树区域 -->
        <div class="content-left-tree">
          <leftTree :replaceFields="replaceFields"
                    :treeData="treeData"
                    @treeSelect="treeSelect"></leftTree>
        </div>
      </div>
      <div class="content-right">
        <!-- 查询区域 -->
        <div class="content-right-form">
          <conditionQuery :headData="headData"
					:exportState="true"
                          @onTimeChange="onTimeChange"
													@exportExcel="exportExcel"
                          @onInputChange="onInputChange"
													@onHandleChange="onHandleChange"
                          @onSelectClick="getsafetyAccidentReport" />
        </div>
        <!-- 功能按钮区域 -->
        <div class="content-right-btnGroup">
          <a-button-group>
            <a-button @click="addOplType"
                      name="addsafetyAccidentReport">新增</a-button>
            <a-button @click="addOplType"
                      name="editsafetyAccidentReport">
              修改
            </a-button>
            <a-button @click="delsafetyAccidentReportBtn"> 删除 </a-button>
            <a-button @click="addOplType" name="importExecl">
							批量导入
						</a-button>
            <a-button @click="addOplType"
                      name="editsafetyAccidentReportType">
              关联类型
            </a-button>
          </a-button-group>
        </div>
        <!-- 表格区域 -->
        <div class="content-right-table">
          <a-table :columns="columns"
                   :row-selection="rowSelection"
                   :loading='tableloading'
                   :scroll="{ y: 400 }"
                   :pagination="false"
                   :data-source="dataSource">
            <span slot="time"
                  slot-scope="time">
              {{ $moment(time).format('YYYY-MM-DD HH:mm') }}
            </span>
            <span slot="securityGrade"
                  slot-scope="securityGrade">
              {{securityGrade===1? '有休': securityGrade === 2? '无休' : '事件'}}
            </span>
            <span slot="action"
                  slot-scope="scope">
              <a @click="openinfosafetyAccidentReport(scope)"
                 name="infosafetyAccidentReport">查看</a>
            </span>
          </a-table>
          <a-pagination size="small"
                        :total="total"
                        :defaultCurrent="pageIndex"
                        :defaultPageSize="pageSize"
                        show-size-changer
                        show-quick-jumper
                        :show-total="(total) => `总共 ${total} 条`" />
        </div>
      </div>
    </div>
    <!-- 弹窗区域 -->
    <jywsDialog :visible="visible"
                :title="modalTitle"
                :width="visibleWidth"
                :roger="jywsDialogBottomRoger"
                :typePrimary="typePrimary"
                :bottom-name="jywsDialogBottomName"
                @updataClose="closeModal"
                @updataWin="updataWin">
      <a-spin :spinning="spinning">
        <div class="modal-body">
          <!-- 新增/修改opl类型弹窗 -->
          <div v-if="
							visibleType === 'addOplType' ||
							visibleType === 'editOplType'
						"
               class="modal-body-box">
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="24"
                     class="flex">
                <div class="col-label must">报告类型</div>
                <div class="col-value">
                  <a-input v-model="oplTypeName"
                           placeholder="请输入报告类型" />
                </div>
              </a-col>
            </a-row>
          </div>
          <!-- 新增opl课程列表弹窗 -->
          <div v-else-if="
							visibleType === 'addsafetyAccidentReport' ||
							visibleType === 'editsafetyAccidentReport'
						"
               class="modal-body-box">
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label must">安全等级</div>
                <div class="col-value">
                  <a-select placeholder="请选择"
                            v-model="addoplForm.securityGrade"
                            style="width: 200px">
                    <a-select-option :value="1">
                      有休
                    </a-select-option>
                    <a-select-option :value="2">
                      无休
                    </a-select-option>
                    <a-select-option :value="3">
                      事件
                    </a-select-option>
                  </a-select>
                </div>
              </a-col>
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label must">事故时间</div>
                <div class="col-value">
                  <a-date-picker style="width: 200px"
                                 v-model="addoplForm.accidentTime" />
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label must">责任人</div>
                <div class="col-value">
                  <a-input v-model="addoplForm.liableUserName"
                           placeholder="请输入责任人" />
                </div>
              </a-col>
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label must">责任部门</div>
                <div class="col-value">
                  <a-tree-select v-model="addoplForm.organizationId"
                                 :defaultValue='addoplForm.organizationId'
                                 @change='deptChange'
                                 allowClear
                                 style="width: 100%"
                                 :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                                 :tree-data="deptSelectTree"
                                 placeholder="请选择责任部门"
                                 :treeDataSimpleMode='true'> </a-tree-select>
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label">事故描述</div>
                <div class="col-value">
                  <a-textarea v-model="addoplForm.accidentDescribe"
                              placeholder="请对事故描述进行简要描述"
                              :rows="4" />
                </div>
              </a-col>
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label">原因分析</div>
                <div class="col-value">
                  <a-textarea v-model="addoplForm.reasonAnalysis"
                              placeholder="请对原因分析进行简要描述"
                              :rows="4" />
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label">处置对策</div>
                <div class="col-value">
                  <a-textarea v-model="addoplForm.counterMeasures"
                              placeholder="请对处置对策进行简要描述"
                              :rows="4" />
                </div>
              </a-col>
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label">永久预防措施</div>
                <div class="col-value">
                  <a-textarea v-model="addoplForm.foreverMeasures"
                              placeholder="请对预防措施进行简要描述"
                              :rows="4" />
                </div>
              </a-col>
            </a-row>
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="12"
                     :push="1"
                     class="col-flex">
                <div class="col-label">附件</div>
                <div class="col-value">
                  <uploadOss @setFileList="setFileList"
                             ref="uploadOss"
                             @setFileListName="setFileListName"
                             :showList="true">
                    <a-button>
                      <a-icon type="upload" />上传附件
                    </a-button>
                  </uploadOss>
                </div>
              </a-col>
            </a-row>
            <!-- 富文本区域 -->
          </div>
          <!-- 关联课程弹窗 -->
          <div v-else-if="visibleType === 'editsafetyAccidentReportType'">
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="24"
                     class="flex">
                <div class="col-label must">报告类型</div>
                <div class="col-value">
                  <a-tree-select v-model="addoplForm.oplTypeId"
                                 style="width: 100%"
                                 :dropdown-style="{
											maxHeight: '400px',
											overflow: 'auto',
										}"
                                 placeholder="请选择类型"
                                 :tree-data="treeData"
                                 :replaceFields="replaceFields"
                                 @change="oplTypeChange"></a-tree-select>
                </div>
              </a-col>
            </a-row>
          </div>
          <!-- opl课程详情 -->
          <div v-else-if="visibleType === 'infosafetyAccidentReport'">
            <div>
              <a-descriptions>
                <a-descriptions-item label="更新人">
                  {{ this.infosafetyAccidentReport.updateTime }}
                </a-descriptions-item>
                <a-descriptions-item label="更新时间"
                                     :span='2'>
                  {{ this.infosafetyAccidentReport.updateName }}
                </a-descriptions-item>
                <a-descriptions-item label="安全等级">
                  {{
									this.infosafetyAccidentReport.securityGrade === 1 ? '有休' : this.infosafetyAccidentReport.securityGrade === 2? "无休" : '事件'
								}}
                </a-descriptions-item>
                <a-descriptions-item label="事故日期">
                  {{ $moment(this.infosafetyAccidentReport.accidentTime).format(
										'YYYY-MM-DD HH:mm'
									) }}
                </a-descriptions-item>
                <a-descriptions-item label="责任人">
                  {{ this.infosafetyAccidentReport.liableUserName }}
                </a-descriptions-item>
                <a-descriptions-item label="事故描述"
                                     :span='3'>
                  {{ this.infosafetyAccidentReport.accidentDescribe }}
                </a-descriptions-item>
                <a-descriptions-item label="原因分析"
                                     :span='3'>
                  {{ this.infosafetyAccidentReport.reasonAnalysis }}
                </a-descriptions-item>
                <a-descriptions-item label="处置对策"
                                     :span='3'>
                  {{ this.infosafetyAccidentReport.counterMeasures }}
                </a-descriptions-item>
                <a-descriptions-item label="永久预防措施"
                                     :span='3'>
                  {{ this.infosafetyAccidentReport.foreverMeasures }}
                </a-descriptions-item>
                <a-descriptions-item label="附件">
                  <a :href="this.infosafetyAccidentReport.pdfName"
                     target="_blank">查看</a>
                </a-descriptions-item>
              </a-descriptions>
            </div>
          </div>
        </div>
      </a-spin>
    </jywsDialog>
		<importDataDialog 
    :visible="importVisible" 
    :title="importTitle" 
    :url="importUrl" 
    :current="current"
     @cancel="cancelClick" 
     @submit="submitClick"/>
  </div>
</template>

<script>
import leftTree from '@/components/leftTree.vue'
import uploadOss from '@/components/uploadOss'
import jywsDialog from '@/components/jywsDialog.vue'
import importDataDialog from '@/components/importDataDialog.vue'
import * as util from '@/core/util'
import { urlDataDeal } from '@/core/date'
import conditionQuery from '@/components/conditionQuery.vue'
// import currency from '@/core/currency'

export default {
  components: {
    leftTree,
    conditionQuery,
    jywsDialog,
    uploadOss,
		importDataDialog
  },
  data() {
    return {
      selectedRows: [],
      selectedRowKeys: [],
			importVisible:false,
			importTitle: '批量导入',
      importUrl: 'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E5%AE%89%E5%85%A8%E4%BA%8B%E6%95%85%E6%8A%A5%E5%91%8A%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF%281%29.xls',
      // rowSelection,
      columns: [
        {
          title: '安全等级',
          dataIndex: 'securityGrade',
          key: 'securityGrade',
          scopedSlots: { customRender: 'securityGrade' },
        },
        {
          title: '事故日期',
          dataIndex: 'accidentTime',
          key: 'accidentTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '责任人',
          dataIndex: 'liableUserName',
          key: 'liableUserName',
        },
        {
          title: '责任部门',
          dataIndex: 'organizationName',
          key: 'organizationName',
        },
        {
          title: '事故描述',
          dataIndex: 'accidentDescribe',
          key: 'accidentDescribe',
        },
        {
          title: '原因分析',
          dataIndex: 'reasonAnalysis',
          key: 'reasonAnalysis',
        },
        {
          title: '处置对策',
          dataIndex: 'counterMeasures',
          key: 'counterMeasures',
        },
        {
          title: '永久预防措施',
          dataIndex: 'foreverMeasures',
          key: 'foreverMeasures',
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          key: 'cz',
        },
      ],
      headData: [
				{
					key: 5,
					name: '安全等级',
					component: 'a-select',
					value: undefined,
					duplicate: 'securityGrade',
					placeholder: '请选择',
					provinceData: [
						{
							name: '有休',
							value: 1,
						},
						{
							name: '无休',
							value: 2,
						},
						{
							name: '事件',
							value: 3,
						},
					],
				},
        {
          key: 1,
          name: '事故时间',
          component: 'a-range-picker',
          value: '',
        },
        {
          key: 2,
          name: '责任人',
          component: 'a-input',
          value: '',
          duplicate: 'liableUserName',
          placeholder: '请输入责任人',
        },
				{
          key: 3,
          name: '事故描述',
          component: 'a-input',
          value: '',
          duplicate: 'accidentDescribe',
          placeholder: '请输入事故描述',
        },
				{
          key: 4,
          name: '原因分析',
          component: 'a-input',
          value: '',
          duplicate: 'reasonAnalysis',
          placeholder: '请输入原因分析',
        },
				
      ],
      tenantId: '',
			updateName:'demo',
      // 新增opl课程参数
      addoplForm: {
        typeId: undefined,
        oplTypeName: '',
        pdfName: '',
        liableUserName: '',
        organizationId: undefined,
        organizationName: '',
        accidentDescribe: '',
        counterMeasures: '',
        foreverMeasures: '',
        reasonAnalysis: '',
        accidentTime: '',
        securityGrade: undefined,
      },
      // 查询opl课程列表参数
      searchForm: {
        liableUserName: '',
        accidentDescribe: '',
        reasonAnalysis: '',
        startTime: '',
        endTime: '',
				securityGrade:''
      },
      dataSource: [],
      tableloading: false,
      visible: false,
      visibleType: '',
      visibleWidth: '520px',
      typePrimary: 'primary',
      modalTitle: '',
      oplTypeName: '',
      spinning: false,
      // 树组件 键值对
      replaceFields: {
        children: 'children',
        title: 'reportName',
        key: 'reportTypeId',
        value: 'reportTypeId',
      },
      treeData: [],
      oplId: '',
      selectTreeData: {},
      pageIndex: 1,
      pageSize: 10,
      total: 10,
      jywsDialogBottomRoger: '取消',
      jywsDialogBottomName: '确认',
      editor: null,
      html: '<p></p>',
      toolbarConfig: {},
      editorConfig: { placeholder: '请输入内容...' },
      mode: 'default', // or 'simple'
			// 详情弹窗参数
      infosafetyAccidentReport: {},
      deptSelectTree: [],
    }
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
    this.addoplForm.tenantId = str[1].split('&')[0]
		this.addoplForm.updateName = str[2].split('&')[0]
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
  },
  mounted() {
    // 模拟 ajax 请求，异步渲染编辑器
    // setTimeout(() => {
    //   this.html = '<p>模拟 Ajax 异步设置内容 HTML</p>'
    // }, 1500)
    this.getDeptSelect()
    this.getOplTypeTree()
    this.getsafetyAccidentReport()
  },
  beforeDestroy() {
    const editor = this.editor
    if (editor == null) return
    editor.destroy() // 组件销毁时，及时销毁编辑器
  },
  methods: {
    onCreated(editor) {
      this.editor = Object.seal(editor) // 一定要用 Object.seal() ，否则会报错
    },
    // 获取部门树选择
    getDeptSelect() {
      let dataset = {
        dataset: this.tenantId,
      }
      util.post('department/search', dataset).then((res) => {
        let temp = res.dataset
        let datalist = []
        temp.forEach((ele) => {
          ele.value = ele.id
          ele.title = ele.name
          if (!ele.pId) {
            ele.pId = null
          }
          datalist.push(ele)
        })
        this.deptSelectTree = datalist
      })
    },
    // 选中部门
    deptChange(val, obj) {
      this.addoplForm.organizationName = obj[0]
      console.log(val)
      console.log(obj)
    },
    // 选择证书
    onSelectPeoEqus(val) {
      if (val.obj.length === 0) {
        return
      } else {
        // this.addForm.userId = val.obj[0].c_id
        this.addoplForm.liableUserName = val.obj[0].c_name
        this.addoplForm.organizationId = val.obj[0].c_organization_id
        // this.addForm.organizationName = val.obj[0].c_organization_name
      }
    },
    // 选择opl类型
    oplTypeChange(val, obj) {
      this.addoplForm.typeId = val
      this.addoplForm.oplTypeName = obj[0]
    },
    // 递归给树增加icon图标
    addIconSlot(val) {
      if (val.pId === '0') {
        val.slots = { icon: 'home' }
      } else {
        val.slots = { icon: 'hdd' }
      }
      if (val.children && val.children.length !== 0) {
        val.children.forEach((item) => {
          this.addIconSlot(item)
        })
      }
    },
    // 上传附件 -- 获取oss地址
    setFileList(val) {
      this.addoplForm.pdfName = val
    },
    setFileListName(val) {
      // this.addoplForm.accessoryName = val
      console.log(val)
    },
    // 查询区域 - 时间范围变动
    onTimeChange(val) {
      this.searchForm.startTime = val.dateString[0]
      this.searchForm.endTime = val.dateString[1]
    },
    // 查询区域 - 输入框变动
    onInputChange(item) {
      if (item.duplicate == 'liableUserName') {
        this.searchForm.liableUserName = item.value
      }
			if (item.duplicate == 'accidentDescribe') {
        this.searchForm.accidentDescribe = item.value
      }
			if (item.duplicate == 'reasonAnalysis') {
        this.searchForm.reasonAnalysis = item.value
      }
    },
		// 下拉框选中
		onHandleChange(item) {
			if (item.duplicate == 'securityGrade') {
				this.searchForm.securityGrade = item.value
			}
		},
    // 获取opl类型树结构
    getOplTypeTree() {
      let dataset = {
        reportName: '',
        tenantId: this.tenantId,
      }
      util.postform('reportRelation/getReportTypeList', dataset).then((res) => {
        res.data.forEach((item) => {
          this.addIconSlot(item)
        })
        this.treeData = res.data || []
      })
    },
    // 获取opl课程分页列表
    getsafetyAccidentReport() {
      let dataset = {
        pageIndex: this.pageIndex,
        pageSize: this.pageSize,
        tenantId: this.tenantId,
        reportTypeId: this.oplId,
        liableUserName: this.searchForm.liableUserName,
        accidentDescribe: this.searchForm.accidentDescribe,
        reasonAnalysis: this.searchForm.reasonAnalysis,
        securityGrade: this.searchForm.securityGrade,
        startTime: this.searchForm.startTime,
        endTime: this.searchForm.endTime,
      }
      this.tableloading = true
      util
        .postform('reportRelation/getReportSecurityAccidentList', dataset)
        .then((res) => {
          res.data.records.forEach((item) => {
            item.key = item.reportSecurityAccidentId
          })
          this.dataSource = res.data.records
          this.total = res.data.total
          this.tableloading = false
        })
    },
    // 选中树的回调
    treeSelect(val, data) {
      this.selectTreeData = data
      this.oplId = val.length !== 0 ? val[0] : ''
      this.getsafetyAccidentReport()
    },
    onChange(val) {
      console.log(val)
    },
    // 开启弹窗
    showModal(e) {
      let name = e.target.name
      switch (name) {
        case 'addOplType':
          this.modalTitle = '新 增'
          this.visibleType = 'addOplType'
          this.visible = true
          this.visibleWidth = '520px'
          break
        case 'editOplType':
          this.modalTitle = '修 改'
          this.visibleType = 'editOplType'
          this.visible = true
          this.visibleWidth = '520px'
          break
        case 'editsafetyAccidentReportType':
          this.modalTitle = '关联类型'
          this.visibleType = 'editsafetyAccidentReportType'
          this.visible = true
          this.visibleWidth = '520px'
          break
        case 'addsafetyAccidentReport':
          this.modalTitle = '新 增'
          this.visibleType = 'addsafetyAccidentReport'
          this.visible = true
          this.visibleWidth = '960px'
          break
				case 'importExecl':
					this.importVisible = true
					break
        case 'editsafetyAccidentReport':
          this.modalTitle = '修 改'
          this.visibleType = 'editsafetyAccidentReport'
          this.visible = true
          this.visibleWidth = '960px'
          break
        default:
          break
      }
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false
      this.oplTypeName = ''
      if (this.addoplForm.pdfName) {
        this.$refs.uploadOss.clearFileList()
      }
      this.addoplForm = {
        typeId: undefined,
        oplTypeName: '',
        pdfName: '',
        liableUserName: '',
        organizationId: undefined,
        organizationName: '',
        accidentDescribe: '',
        counterMeasures: '',
        foreverMeasures: '',
        reasonAnalysis: '',
        accidentTime: '',
        securityGrade: undefined,
      }
      this.jywsDialogBottomName = '确认'
      this.jywsDialogBottomRoger = '取消'
      this.typePrimary = 'primary'
      this.selectedRowKeys = []
    },
    // 打开弹窗按钮
    addOplType(e) {
      let name = e.target.name
      // 修改opl类型
      if (name === 'editOplType') {
        this.oplTypeName = this.selectTreeData.reportName
      }
      if (name === 'editsafetyAccidentReport') {
        if (this.selectedRows.length !== 1) {
          this.$warning({
            title: '请选择一条记录进行修改操作！',
          })
          return
        }
        this.addoplForm = this.selectedRows[0]
      }
      if (name === 'editsafetyAccidentReportType') {
        if (this.selectedRows.length === 0) {
          this.$warning({
            title: '请选择至少一条记录进行关联类型！',
          })
          return
        }
      }
      this.showModal(e)
    },
		// 关闭导入弹窗
		cancelClick() {
			this.importVisible = false
		},
		// 导出
		exportExcel() {
      this.searchForm.tenantId = this.tenantId
			let urldata = urlDataDeal(this.searchForm)
			let downUrl =
				util.getBaseURL() + 'reportRelation/excel/excelReportSecurityAccident?' + urldata
			window.open(downUrl)
		},
		// 确认导入
		submitClick(file) {
			const fd = new FormData()
			fd.append('tenantId', this.tenantId)
			fd.append('updateName', this.addoplForm.updateName)
			fd.append('excel', file[0])
			util.postformData('reportRelation/excel/excelImport', fd)
				.then((res) => {
					if (res.code == 200) {
						// this.importDataSource = res.data
						this.$message.success('导入成功')
            this.importVisible = false
						this.getsafetyAccidentReport()
					} else {
						this.$message.error(res.data.message)
					}
				})
				.catch((err) => {
					this.$message.error(err.response.data.message)
				})
		},
    // 查看课程详情弹窗
    openinfosafetyAccidentReport(scope) {
      this.modalTitle = '详 情'
      this.jywsDialogBottomName = '关闭'
      this.jywsDialogBottomRoger = ''
      this.typePrimary = ''
      this.visibleType = 'infosafetyAccidentReport'
      this.visibleWidth = '960px'
      this.visible = true
      this.infosafetyAccidentReport = scope
      console.log(scope)
    },
    updataWin() {
      if (this.visibleType === 'addOplType') {
        this.addOplTypeBtn()
      } else if (this.visibleType === 'editOplType') {
        this.editOplTypeBtn()
      } else if (this.visibleType === 'addsafetyAccidentReport') {
        this.addsafetyAccidentReportBtn()
      } else if (this.visibleType === 'editsafetyAccidentReport') {
        this.editsafetyAccidentReportBtn()
      } else if (this.visibleType === 'infosafetyAccidentReport') {
        this.closeModal()
      } else if (this.visibleType === 'editsafetyAccidentReportType') {
        this.editsafetyAccidentReportTypeBtn()
      }
    },
    // 新增类型弹窗 确认
    addOplTypeBtn() {
      let dataset = {
        reportName: this.oplTypeName,
        tenantId: this.tenantId,
      }
      if (this.oplId !== '') {
        dataset.pid = this.oplId
      }
      this.spinning = true
      util.postform('reportRelation/addReportType', dataset).then((res) => {
        if (res.code === 200) {
          this.$message.success('新增成功！')
          this.spinning = false
          this.closeModal()
          this.getOplTypeTree()
        }
      })
    },
    // 修改类型弹窗 确认
    editOplTypeBtn() {
      let dataset = {
        reportName: this.oplTypeName,
        id: this.selectTreeData.reportTypeId,
        tenantId: this.tenantId,
      }
      this.spinning = true
      util.postform('reportRelation/updateReportType', dataset).then((res) => {
        if (res.code === 200) {
          this.$message.success('修改成功！')
          this.spinning = false
          this.closeModal()
          this.getOplTypeTree()
        }
      })
    },
    // 删除opl类型接口
    delOplType() {
      let that = this
      if (!this.selectTreeData.reportTypeId) {
        this.$info({
          title: '提示',
          content: '请选择需要删除的类型！',
          onOk() {},
        })
        return
      }
      let dataset = {
        ids: that.selectTreeData.reportTypeId,
        tenantId: this.tenantId,
      }
      this.$confirm({
        title: '是否确认要删除此类型?',
        onOk() {
          util
            .postform('reportRelation/deleteReportType', dataset)
            .then((res) => {
              if (res.code === 200) {
                that.$message.success('删除成功！')
                that.closeModal()
                that.getOplTypeTree()
              }
            })
        },
        onCancel() {},
      })
    },
    // 新增opl课程接口
    addsafetyAccidentReportBtn() {
      if (!this.addoplForm.securityGrade) {
        this.$message.error('安全等级不能为空！')
        return
      }
      if (this.addoplForm.accidentTime === '') {
        this.$message.error('事故时间不能为空！')
        return
      }
      if (this.addoplForm.liableUserName === '') {
        this.$message.error('责任人不能为空！')
        return
      }
      if (this.addoplForm.organizationId === '') {
        this.$message.error('责任部门不能为空！')
        return
      }
      this.addoplForm.tenantId = this.tenantId
			this.addoplForm.updateName = this.updateName
			if(this.oplId){
				this.addoplForm.typeId = this.oplId
			}
      util
        .postform('reportRelation/addReportSecurityAccident', this.addoplForm)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('新增成功！')
            this.closeModal()
            this.getsafetyAccidentReport()
          }
        })
    },
    // 修改opl课程接口
    editsafetyAccidentReportBtn() {
			if (!this.addoplForm.securityGrade) {
        this.$message.error('安全等级不能为空！')
        return
      }
      if (this.addoplForm.accidentTime === '') {
        this.$message.error('事故时间不能为空！')
        return
      }
      if (this.addoplForm.liableUserName === '') {
        this.$message.error('责任人不能为空！')
        return
      }
      if (this.addoplForm.organizationId === '') {
        this.$message.error('责任部门不能为空！')
        return
      }
      this.addoplForm.id = this.selectedRowKeys[0]
      this.addoplForm.tenantId = this.tenantId
      this.addoplForm.updateName = this.updateName
      util
        .postform(
          'reportRelation/updateReportSecurityAccident',
          this.addoplForm
        )
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('修改成功！')
            this.closeModal()
            this.getsafetyAccidentReport()
          }
        })
    },
    // 删除opl课程接口
    delsafetyAccidentReportBtn() {
      let that = this
      if (this.selectedRows.length === 0) {
        this.$info({
          title: '提示',
          content: '请选择需要删除的课程记录！',
          onOk() {},
        })
        return
      }
      let dataset = {
        ids: that.selectedRowKeys.toString(),
        tenantId: this.tenantId,
      }
      this.$confirm({
        title: '是否确认要删除此课程?',
        onOk() {
          util
            .postform('reportRelation/deleteReportSecurityAccident', dataset)
            .then((res) => {
              if (res.code === 200) {
                that.$message.success('删除成功！')
                that.closeModal()
                that.getsafetyAccidentReport()
              }
            })
        },
        onCancel() {},
      })
    },
    // 下载附件
    downFile() {
      if (this.selectedRows.length !== 1) {
        this.$warning({
          title: '请选择一条记录进行附件下载！',
        })
        return
      }
      window.open(this.selectedRows[0].pdfName)
    },
    // 关联类型
    editsafetyAccidentReportTypeBtn() {
      let ids = []
      this.selectedRows.forEach((item) => {
        ids.push(item.reportSecurityAccidentId)
      })
      let dataset = {
        reportSecurityAccidentIdS: ids.toString(),
        reportTypeId: this.addoplForm.oplTypeId,
        tenantId: this.tenantId,
      }
      util.postform('reportRelation/addReportRelation', dataset).then((res) => {
        if (res.code === 200) {
          this.$message.success('关联成功！')
          this.getsafetyAccidentReport()
          this.closeModal()
        }
      })
    },
  },
}
</script>

<style scoped lang="less">
.safetyAccidentReport {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  // display: flex;
  // justify-content: space-between;
  padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.safetyAccidentReport-title {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.infosafetyAccidentReport-title {
  font-size: 24px;
  color: #333333;
  text-align: left;
}
.infosafetyAccidentReport-smtitle {
  display: flex;
  color: #999999;
  font-size: 12px;
  text-align: left;
  margin-top: 16px;
}
.infosafetyAccidentReport-content {
  margin-top: 24px;
}
.mg-l-5 {
  margin-left: 5px;
}
.mg-l-50 {
  margin-left: 50px;
}
.title-icon {
  width: 4px;
  height: 16px;
  background: #1890ff;
  display: inline-block;
  margin-right: 12px;
}
.col-value {
  width: 200px;
}
.safetyAccidentReport-content {
  background: #ffffff;
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
  padding-top: 32px;
}
.flush {
  font-size: 14px;
  color: #1890ff;
}
.content-right-btnGroup {
  height: 56px;
  width: 100%;
  line-height: 56px;
  margin-top: 10px;
}
.content-right-form {
  // width: 100%;
  background: #ffffff;
  padding: 0px 21px 0px 17px;
  .form-search {
    display: flex;
    align-items: center;
    width: 420px;
  }
}
.form-label {
  font-size: 14px;
  color: #333333;
  width: 70px;
  text-align: right;
  margin-right: 16px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-left {
  width: 220px;
  height: calc(100% - 30px);
  background: #ffffff;
  border: 1px solid #e8e8e8;
  margin-left: 12px;
  .content-left-title {
    height: 48px;
    width: 100%;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-btnGroup {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
  }
}
.content-right {
  height: calc(100% - 30px);
  width: calc(100% - 283px);
  margin-left: 27px;
}
</style>

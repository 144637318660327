<template>
  <div class="specialEqusRecords">
    <div class="specialEqusRecords-content">
      <div class="content-left">
        <!-- 左侧标题 -->
        <div class="content-left-title">
          <div>设备列表</div>
          <div class="flush">
            <a href="javascript:;"
               @click="reflush">
              <a-icon type="sync"
                      style="margin-right: 6px" />刷新
            </a>
          </div>
        </div>
        <!-- 左侧树区域 -->
        <div class="content-left-tree">
          <deviceTree v-if="oneTreeVisble"
                      :treeData="deviceTreeList"
                      @treeSelect="treeSelect"
                      @expandTree="expandTree"></deviceTree>
        </div>
      </div>
      <div class="content-right">
        <!-- 查询区域 -->
        <div class="content-right-form">
          <conditionQuery :headData="headData"
                          :exportState="true"
                          @exportExcel="exportExcel"
                          @onTimeChange="onTimeChange"
                          @onHandleChange="onHandleChange"
                          @onInputChange="onInputChange"
                          @onSelectClick="getspecialEqusRecords" />
        </div>
        <!-- 功能按钮区域 -->
        <div class="content-right-btnGroup">
          <a-button-group>
            <a-button @click="onbtnGroup"
                      name="editspecialEqusRecords">
              修改
            </a-button>
            <a-button @click="delBtn"> 删除 </a-button>
          </a-button-group>
        </div>
        <!-- 表格区域 -->
        <div class="content-right-table">
          <a-table :columns="columns"
                   :row-selection="rowSelection"
                   :data-source="dataSource"
                   :pagination="false"
                   class="borderTable"
                   :scroll="{ y: 380 }">
            <span slot="time"
                  slot-scope="time">
              {{
								time
									? $moment(time).format('YYYY-MM-DD HH:mm')
									: '-'
							}}
            </span>
            <span slot="cycle"
                  slot-scope="text, record">
              {{ record.cycle + record.cycleUnit }}
            </span>
            <span slot="executiveName"
                  slot-scope="text, record">
              {{
								record.execType === 0
									? record.executiveName
									: record.nowRecipientsName
							}}
            </span>
            <span slot="result"
                  slot-scope="text, record">
              <span v-if="record.result === 0"
                    style="color: #2fc25b">合格</span>
              <span v-else-if="record.result === 1"
                    style="color: red">不合格</span>
              <span v-else>-</span>
            </span>
            <span slot="fileName"
                  slot-scope="text, record">
              <a v-if="record.fileName"
                 :href="record.file"
                 target="_blank">{{
								record.fileName
							}}</a>
              <span v-else>-</span>
            </span>
            <span slot="execState"
                  slot-scope="text, record">
              <a-badge v-if="record.execState === 2"
                       status="success"
                       text="已完成" />
              <a-badge v-else-if="record.execState === 1"
                       status="processing"
                       text="执行中" />
              <a-badge v-else-if="record.execState === 0"
                       status="warning"
                       text="待执行" />
            </span>
            <span slot="action"
                  slot-scope="scope">
              <a @click="openinfospecialEqusRecords(scope)"
                 name="infospecialEqusRecords">查看</a>&nbsp;&nbsp;
              <a v-if="scope.result === 1 && scope.issue === 0"
                 href="javascript:;"
                 @click="replayPlan(scope)"
                 name="replayPlan">重新下发</a>
            </span>
          </a-table>
          <a-pagination @change="pageonChange"
                        @showSizeChange="showSizeChange"
                        size="small"
                        :total="searchForm.total"
                        :defaultCurrent="searchForm.pageIndex"
                        :defaultPageSize="searchForm.pageSize"
                        show-size-changer
                        show-quick-jumper
                        :show-total="(total) => `总共 ${total} 条`" />
        </div>
      </div>
    </div>
    <!-- 弹窗区域 -->
    <jywsDialog :visible="visible"
                :title="modalTitle"
                :width="visibleWidth"
                :roger="jywsDialogBottomRoger"
                :bottom-name="jywsDialogBottomName"
                @updataClose="closeModal"
                :typePrimary="typePrimary"
                @updataWin="updataWin">
      <a-spin :spinning="spinning">
        <!-- 修改特种设备检验 -->
        <div v-if="visibleType === 'editspecialEqusRecords'">
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">检验结果</div>
              <div class="col-value">
                <a-select placeholder="请选择"
                          v-model="addForm.result"
                          style="width: 200px">
                  <a-select-option :value="0">
                    合格
                  </a-select-option>
                  <a-select-option :value="1">
                    不合格
                  </a-select-option>
                </a-select>
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">结果描述</div>
              <div class="col-value">
                <a-input v-model="addForm.resultDescription"
                         placeholder="请输入" />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">完成时间</div>
              <div class="col-value">
                <a-date-picker style="width: 200px"
                               v-model="addForm.finishTime" />
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">有效期限</div>
              <div class="col-value">
                <a-date-picker style="width: 200px"
                               v-model="addForm.periodTime" />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">证书号</div>
              <div class="col-value">
                <a-input v-model="addForm.credentialsCode"
                         placeholder="请输入证书号" />
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex"> </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">证书附件</div>
              <div class="col-value">
                <uploadOss ref="uploadOss"
                           @setFileList="setFileList"
                           @setFileListName="setFileListName"
                           :showList="true">
                  <a-button>
                    <a-icon type="upload" />上传附件
                  </a-button>
                </uploadOss>
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex"> </a-col>
          </a-row>
        </div>
        <!-- 变更计划 -->
        <div v-else-if="visibleType === 'replayPlan'">
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="24"
                   :push="2"
                   class="col-flex">
              <div class="col-label must">下发日期</div>
              <div class="col-value">
                <a-date-picker style="width: 200px"
                               v-model="replayForm.issueTime" />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="24"
                   :push="2"
                   class="col-flex">
              <div class="col-label must">截止日期</div>
              <div class="col-value">
                <a-date-picker style="width: 200px"
                               v-model="replayForm.endTime" />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="24"
                   :push="2"
                   class="col-flex">
              <div class="col-label must">执行人/组</div>
              <div class="col-value">
                <selectPeo @onSelect="onSelectPeo"
                           ref="selectPeo1"
                           :showGroup="true"
                           :radioFlag="true"></selectPeo>
              </div>
            </a-col>
          </a-row>
        </div>
        <!-- 详情界面 -->
        <div v-else-if="visibleType === 'infospecialEqusRecords'">
          <div class="info-title">
            <div class="info-title-icon"></div>
            <div class="info-title-text">基本信息</div>
          </div>
          <div class="info-content">
            <a-descriptions>
              <a-descriptions-item label="所属设备">
                {{ this.infoList.objectName }}
              </a-descriptions-item>
              <a-descriptions-item label="组织部门">
                {{ this.infoList.organizationName }}
              </a-descriptions-item>
              <a-descriptions-item label="执行人/组">
                {{
									this.infoList.execType === 0
										? this.infoList.executiveName
										: this.infoList.nowRecipientsName
								}}
              </a-descriptions-item>
              <a-descriptions-item label="抄送人">
                {{ this.infoList.inspectPlan ? this.infoList.inspectPlan.ccName : '-' }}
              </a-descriptions-item>
              <a-descriptions-item label="工单状态">
                <a-badge v-if="this.infoList.execState === 2"
                         status="success"
                         text="已完成" />
                <a-badge v-else-if="this.infoList.execState === 1"
                         status="processing"
                         text="执行中" />
                <a-badge v-else-if="this.infoList.execState === 0"
                         status="warning"
                         text="待执行" />
              </a-descriptions-item>
            </a-descriptions>
          </div>
          <div class="info-title">
            <div class="info-title-icon"></div>
            <div class="info-title-text">年检记录</div>
          </div>
          <div>
            <a-descriptions>
              <a-descriptions-item label="年检项目">
                {{ this.infoList.project }}
              </a-descriptions-item>
              <a-descriptions-item label="年检内容">
                {{ this.infoList.inspectPlan.content }}
              </a-descriptions-item>
              <a-descriptions-item label="年检周期">
                {{
									this.infoList.inspectPlan.cycle +
									this.infoList.inspectPlan.cycleUnit
								}}
              </a-descriptions-item>
              <a-descriptions-item label="所属设备">
                {{ this.infoList.objectName }}
              </a-descriptions-item>
              <a-descriptions-item label="证书类型">
                {{ this.infoList.credentialsType }}
              </a-descriptions-item>
              <a-descriptions-item label="有效期限">
                {{
									this.infoList.periodTime
										? $moment(
												this.infoList.periodTime).format('YYYY-MM-DD HH:mm')
										: '-'
								}}
              </a-descriptions-item>
              <a-descriptions-item label="下次检验日期">
                {{
									$moment(
										this.infoList.nextReleaseTime
									).format('YYYY-MM-DD HH:mm')
								}}
              </a-descriptions-item>
              <a-descriptions-item label="证书状态">
                <a-badge v-if="
										this.infoList.inspectPlan
											.credentialsState === 0
									"
                         status="success"
                         text="正常" />
                <a-badge v-else-if="
										this.infoList.inspectPlan
											.credentialsState === 2
									"
                         status="error"
                         text="超期" />
                <a-badge v-else-if="
										this.infoList.inspectPlan
											.credentialsState === 1
									"
                         status="warning"
                         text="待检" />
              </a-descriptions-item>
              <a-descriptions-item label="执行人/组">
                {{
									this.infoList.execType === 0
										? this.infoList.executiveName
										: this.infoList.nowRecipientsName
								}}
              </a-descriptions-item>
              <a-descriptions-item label="抄送人">
                {{ this.infoList.inspectPlan.ccName }}
              </a-descriptions-item>
              <a-descriptions-item label="证书号">
                {{ this.infoList.credentialsCode }}
              </a-descriptions-item>
              <a-descriptions-item label="证书附件">
                <a :href="this.infoList.file">{{
									this.infoList.fileName
								}}</a>
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </div>
      </a-spin>
    </jywsDialog>
  </div>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import deviceTree from '@/components/deviceTree.vue'
import uploadOss from '@/components/uploadOss'
import selectPeo from '@/components/selectPeo.vue'
import { urlDataDeal } from '@/core/date'
import moment from 'moment'
import * as util from '@/core/util'

export default {
  components: {
    conditionQuery,
    jywsDialog,
    uploadOss,
    deviceTree,
    selectPeo,
  },
  data() {
    return {
      tenantId: '',
      // 导入弹窗参数
      importVisible: false,
      importTitle: '批量导入',
      importUrl:
        'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E6%A3%80%E9%AA%8C%E9%A1%B9%E7%9B%AE%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
      importDataSource: [],
      current: 0,
      // 弹窗参数
      visible: false,
      visibleType: '',
      modalTitle: '',
      visibleWidth: '960px',
      jywsDialogBottomRoger: '取消',
      jywsDialogBottomName: '确认',
      typePrimary: 'primary',
      spinning: false,
      infoList: {},
      // 新增接口传参
      addForm: {
        result: undefined,
        resultDescription: '',
        finishTime: '',
        periodTime: '',
        credentialsCode: '',
        file: '',
        fileName: '',
        type: 0,
      },
      replayForm: {
        execType: 0,
        nowRecipients: '',
        nowRecipientsName: '',
        executive: '',
        executiveName: '',
        endTime: '',
        issueTime: '',
      },
      editForm: {},
      infodataSource: [],
      // 详情记录表头
      infocolumns: [
        {
          title: '年检项目',
          dataIndex: 'project',
          key: 'project',
        },
        {
          title: '完成时间',
          dataIndex: 'finishTime',
          key: 'finishTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '有效期限',
          dataIndex: 'periodTime',
          key: 'periodTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '执行人/组',
          dataIndex: 'executiveName',
          key: 'executiveName',
          scopedSlots: { customRender: 'executiveName' },
        },
        {
          title: '任务状态',
          dataIndex: 'execState',
          key: 'execState',
          scopedSlots: { customRender: 'execState' },
        },
        {
          title: '检验结果',
          dataIndex: 'result',
          key: 'result',
          scopedSlots: { customRender: 'result' },
        },
        {
          title: '证书号',
          dataIndex: 'credentialsCode',
          key: 'credentialsCode',
        },
        {
          title: '证书附件',
          dataIndex: 'fileName',
          key: 'fileName',
        },
      ],
      // 表格表头
      columns: [
        {
          title: '年检项目',
          dataIndex: 'project',
          key: 'project',
        },
        {
          title: '类型',
          dataIndex: 'credentialsType',
          key: 'credentialsType',
        },
        {
          title: '所属设备',
          dataIndex: 'objectName',
          key: 'objectName',
        },
        {
          title: '下发日期',
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '执行人/组',
          dataIndex: 'executiveName',
          key: 'executiveName',
          scopedSlots: { customRender: 'executiveName' },
        },
        {
          title: '工单状态',
          dataIndex: 'execState',
          key: 'execState',
          scopedSlots: { customRender: 'execState' },
        },
        {
          title: '开始时间',
          dataIndex: 'startTime',
          key: 'startTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '完成时间',
          dataIndex: 'finishTime',
          key: 'finishTime',
          scopedSlots: { customRender: 'time' },
        },
        {
          title: '年检结果',
          dataIndex: 'result',
          key: 'result',
          scopedSlots: { customRender: 'result' },
        },
        {
          title: '有无附件',
          dataIndex: 'fileName',
          key: 'fileName',
          scopedSlots: { customRender: 'fileName' },
        },
        {
          title: '操作',
          scopedSlots: { customRender: 'action' },
          width: 150,
          key: 'cz',
        },
      ],
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      headData: [
        {
          key: 1,
          name: '下发日期',
          component: 'a-range-picker',
          value: '',
        },
        {
          key: 2,
          name: '年检项目',
          component: 'a-input',
          value: '',
          duplicate: 'project',
          placeholder: '请输入年检项目',
        },
        {
          key: 1,
          name: '证书类型',
          component: 'a-select',
          value: undefined,
          duplicate: 'credentialsType',
          placeholder: '请选择',
          provinceData: [
            {
              name: '起重机械',
              value: '起重机械',
            },
            {
              name: '压力容器',
              value: '压力容器',
            },
            {
              name: '压力管网',
              value: '压力管网',
            },
            {
              name: '特种车辆',
              value: '特种车辆',
            },
            {
              name: '电梯货梯',
              value: '电梯货梯',
            },
            {
              name: '其它设备',
              value: '其它设备',
            },
          ],
        },
        {
          key: 2,
          name: '执行人/组',
          component: 'a-input',
          value: '',
          duplicate: 'executiveName',
          placeholder: '请输入执行人/组',
        },
        {
          key: 1,
          name: '有无附件',
          component: 'a-select',
          value: undefined,
          duplicate: 'isFile',
          placeholder: '请选择',
          provinceData: [
            {
              name: '全部',
              value: '',
            },
            {
              name: '有',
              value: true,
            },
            {
              name: '无',
              value: false,
            },
          ],
        },
      ],
      // 查询特种设备列表参数
      searchForm: {
        project: '',
        isFile: '',
        credentialsType: '',
        executiveName: '',
        objectId: '',
        pageIndex: 1,
        pageSize: 10,
        total: 10,
        type: 0,
        startTime: '',
        endTime: '',
        isDept: false,
      },
      oneTreeVisble: true,
      deviceTreeList: [],
      importName: '下一步',
      userName: '',
    }
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
    this.userName = decodeURIComponent(str[3].split('&')[0])
    this.initTree()
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
    nextReleaseTime() {
      let timeText = ''
      if (this.addForm.periodTime && this.addForm.executiveDays) {
        timeText = moment(this.addForm.periodTime)
          .subtract(this.addForm.executiveDays, 'days')
          .format('YYYY-MM-DD')
      }
      return timeText
    },
  },
  mounted() {
    this.getspecialEqusRecords()
  },
  methods: {
    // 展开设备树
    expandTree(item) {
      this.dynamicLoadingTree(item)
    },
    // 刷新
    reflush() {
      this.initTree()
    },
    // 选中设备树的回调
    treeSelect(val, data) {
      if (val.length !== 0) {
        this.searchForm.objectId = data.id
        if (data.type === 'dep') {
          this.searchForm.isDept = true
        } else {
          this.searchForm.isDept = false
        }
      } else {
        this.searchForm.objectId = ''
        this.searchForm.isDept = false
      }
      this.getspecialEqusRecords()
    },
    // 设备树主级
    initTree() {
      let dataset = {
        tenantId: this.tenantId,
        id: '',
      }
      this.oneTreeVisble = false
      util.postform('departmentNew/queryLazyTrees', dataset).then((res) => {
        this.oneTreeVisble = true
        let data = res.data.pubTreeInfos
        data.forEach((element) => {
          element.key = element.id
          element.title = element.name
          if (element.isDep < 1 && element.isEqu < 1) {
            element.isLeaf = true
          } else {
            element.isLeaf = false
          }
          if (element.type === 'dep') {
            element.scopedSlots = { icon: 'smile' }
          } else {
            element.scopedSlots = { icon: 'custom' }
          }
        })
        this.deviceTreeList = data
      })
    },
    // 设备树子集
    dynamicLoadingTree(item) {
      let dataset = {
        id: item.id,
        // modeType: 4,
        tenantId: this.tenantId,
        special: '1',
        type: 'special',
      }
      util
        .post('pubTree/dynamicLoadingTree', dataset)
        .then((res) => {
          let data = res.dataset
          data.forEach((element) => {
            element.key = element.c_id
            element.title = element.name
            if (
              element.type == 'equ' ||
              (element.type == 'dep' && element.isDep < 1 && element.isEqu < 1)
            ) {
              element.isLeaf = true
            } else {
              element.isLeaf = false
            }
            if (element.type === 'dep') {
              element.scopedSlots = { icon: 'smile' }
            } else {
              element.scopedSlots = {
                icon: 'custom',
                selected: element.componentFlag > 0 ? true : false,
              }
            }
          })
          item.children = data
          this.deviceTreeList = [...this.deviceTreeList]
          this.$forceUpdate()
        })
        .catch((err) => {
          this.$message.error(err.response.data.message)
        })
    },
    // 选择执行人/组
    onSelectPeo(val) {
      if (val.obj.length === 0) {
        return
      } else {
        if (val.obj[0].groupId) {
          this.replayForm.execType = 1
          this.replayForm.nowRecipients = val.obj[0].groupId
          this.replayForm.nowRecipientsName = val.obj[0].groupName
        } else {
          this.replayForm.execType = 0
          this.replayForm.executive = val.obj[0].c_id
          this.replayForm.executiveName = val.obj[0].c_name
        }
      }
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false
      
      if(this.visibleType === 'replayPlan'){
				this.$refs.selectPeo1.clearName()
			}
      if (this.addForm.file) {
        this.$refs.uploadOss.clearFileList()
      }
      this.addForm = {
        project: '',
        content: '',
        cycle: '',
        cycleUnit: '天',
        credentialsType: undefined,
        periodTime: '',
        executiveDays: '',
        nextReleaseTime: '',
        credentialsCode: '',
        credentialsFile: '',
        fileName: '',
        executive: '',
        executiveName: '',
        nowRecipients: '',
        nowRecipientsName: '',
        execType: 0,
        cc: '',
        ccName: '',
        objectId: '',
        objectName: '',
        type: 0,
        peoName: '',
        file: '',
      }
      this.replayForm = {
        execType: 0,
        nowRecipients: '',
        nowRecipientsName: '',
        executive: '',
        executiveName: '',
        endTime: '',
        issueTime: '',
      }
      this.selectedRowKeys = []
      this.selectedRows = []
      this.getspecialEqusRecords()
    },
    // 弹窗确认按钮
    updataWin() {
      console.log(this.visibleType)
      if (this.visibleType === 'addspecialEqusRecords') {
        this.addspecialEqusRecordsBtn()
      } else if (this.visibleType === 'editspecialEqusRecords') {
        this.editspecialEqusRecordsBtn()
      } else if (this.visibleType === 'changePlan') {
        this.changePlanBtn()
      } else if (this.visibleType === 'replayPlan') {
        this.replayPlanBtn()
      } else if (this.visibleType === 'infospecialEqusRecords') {
        this.closeModal()
      }
    },
    replayPlan(val) {
      this.jywsDialogBottomRoger = '取消'
      this.typePrimary = 'primary'
      this.jywsDialogBottomName = '确认'
      this.modalTitle = '重新下发'
      this.visibleType = 'replayPlan'
      this.visible = true
      this.visibleWidth = '560px'
      this.replayForm.taskId = val.id
    },
    // 重新下发
    replayPlanBtn() {
      if (this.replayForm.issueTime === '') {
        this.$message.error('请选择下发日期！')
        return
      }
      if (this.replayForm.endTime === '') {
        this.$message.error('请选择截止日期！')
        return
      }
      if (
        this.replayForm.executiveName === '' &&
        this.replayForm.nowRecipientsName === ''
      ) {
        this.$message.error('请选择执行人/组！')
        return
      }
      this.replayForm.tenantId = this.tenantId
      this.replayForm.creater = this.userName
      util
        .postform('inspect/resetPlan', this.replayForm)
        .then((res) => {
          if (res.code === 200) {
            this.$message.success('重新下发成功！')
            this.closeModal()
            this.getspecialEqusRecords()
          }
        })
        .catch((err) => {
          this.$message.error(err.response.data.message)
        })
    },
    // 导出
    exportExcel() {
      let dataset = {
        type: 0,
        tenantId: this.tenantId,
        project: this.searchForm.project,
        isFile: this.searchForm.isFile,
        credentialsType: this.searchForm.credentialsType,
        executiveName: this.searchForm.executiveName,
      }
      let urldata = urlDataDeal(dataset)
      let downUrl = util.getBaseURL() + 'inspect/inspectExport?' + urldata
      window.open(downUrl)
    },
    // 获取特种设备列表
    getspecialEqusRecords() {
      this.searchForm.tenantId = this.tenantId
      util.postform('inspect/selectList', this.searchForm).then((res) => {
        let temp = res.data.records
        temp.forEach((item) => {
          item.key = item.id
        })
        this.dataSource = temp
        this.searchForm.total = res.data.total
      })
    },
    // 翻页
    pageonChange(page) {
      this.searchForm.pageIndex = page
      this.getspecialEqusRecords()
    },
    // 页数size改变
    showSizeChange(key, size) {
      this.searchForm.pageSize = size
      this.getspecialEqusRecords()
    },
    // 新增特种设备检验
    addspecialEqusRecordsBtn() {
      this.addForm.tenantId = this.tenantId
      this.addForm.nextReleaseTime = moment(this.addForm.periodTime)
        .subtract(this.addForm.executiveDays, 'days')
        .format('YYYY-MM-DD')
      util.postform('inspectPlan/add', this.addForm).then((res) => {
        if (res.code == 200) {
          this.$message.success('新增成功！')
          this.closeModal()
        }
      })
    },
    // 修改特种设备检验
    editspecialEqusRecordsBtn() {
      let dataset = {
        tenantId: this.tenantId,
        result: this.addForm.result,
        resultDescription: this.addForm.resultDescription,
        finishTime: this.addForm.finishTime,
        periodTime: this.addForm.periodTime,
        credentialsCode: this.addForm.credentialsCode,
        file: this.addForm.file,
        fileName: this.addForm.fileName,
        id: this.addForm.id,
      }
      util.postform('inspect/update', dataset).then((res) => {
        if (res.code == 200) {
          this.$message.success('修改成功！')
          this.closeModal()
        }
      })
    },
    // 变更下发计划
    changePlanBtn() {
      let dataset = {
        beforeDay: this.addForm.executiveDays,
        nextInspectTime: moment(this.addForm.periodTime)
          .subtract(this.addForm.executiveDays, 'days')
          .format('YYYY-MM-DD'),
        periodTime: this.addForm.periodTime,
        id: this.selectedRows[0].id,
      }
      util.postform('inspectPlan/changePlan', dataset).then((res) => {
        if (res.code == 200) {
          this.$message.success('变更成功！')
          this.closeModal()
        }
      })
    },
    // 删除
    delBtn() {
      let that = this
      if (this.selectedRowKeys.length === 0) {
        this.$info({
          title: '提示',
          content: '请选择需要删除的记录！',
          onOk() {},
        })
        return
      }
      this.$confirm({
        title: '是否确认要删除此记录?',
        onOk() {
          util
            .postform('inspect/removeBetch/', that.selectedRowKeys)
            .then((res) => {
              if (res.code === 200) {
                that.$message.success('删除成功！')
                that.getspecialEqusRecords()
                that.closeModal()
              }
            })
        },
        onCancel() {},
      })
    },
    // 功能按钮操作
    onbtnGroup(e) {
      let name = e.target.name
      if (name === 'editspecialEqusRecords') {
        if (this.selectedRows.length !== 1) {
          this.$warning({
            title: '请选择一条记录进行修改操作！',
          })
          return
        }
        let temp = this.selectedRows[0]
        this.addForm = temp
        if (temp.groupId) {
          this.addForm.peoName = temp.groupName
        } else {
          this.addForm.peoName = temp.executiveName
        }
      } else if (name == 'changePlan') {
        if (this.selectedRows.length !== 1) {
          this.$warning({
            title: '请选择一条记录进行修改操作！',
          })
          return
        }
      }
      this.showModal(e)
    },
    // 选择抄送人
    onSelectPeocc(val) {
      if (val.obj.length === 0) {
        return
      } else {
        this.addForm.cc = val.obj[0].c_id
        this.addForm.ccName = val.obj[0].c_name
      }
    },
    // 选择设备
    onSelectPeoEqus(val) {
      if (val.obj.length === 0) {
        return
      } else {
        this.addForm.objectId = val.obj[0].c_id
        this.addForm.objectName = val.obj[0].c_name
        this.addForm.organizationId = val.obj[0].c_organization_id
        this.addForm.organizationName = val.obj[0].depName
      }
    },
    // 上传附件 -- 获取oss地址
    setFileList(val) {
      this.addForm.file = val
    },
    setFileListName(val) {
      this.addForm.fileName = val
    },
    showModal(e) {
      let name = e.target.name
      this.visibleType = name
      this.jywsDialogBottomRoger = '取消'
      this.typePrimary = 'primary'
      this.jywsDialogBottomName = '确认'
      switch (name) {
        case 'addspecialEqusRecords':
          this.modalTitle = '新增'
          this.visible = true
          this.visibleWidth = '960px'
          break
        case 'editspecialEqusRecords':
          this.modalTitle = '修改'
          this.visible = true
          this.visibleWidth = '960px'
          break
        case 'infospecialEqusRecords':
          this.modalTitle = '详情'
          this.visible = true
          this.visibleWidth = '960px'
          break
        case 'changePlan':
          this.modalTitle = '变更下发计划'
          this.visible = true
          this.visibleWidth = '560px'
          break
        case 'replayPlan':
          this.modalTitle = '重新下发'
          this.visible = true
          this.visibleWidth = '560px'
          break
        case 'importExecl':
          this.importVisible = true
          break
        default:
          break
      }
    },
    cancelClick() {
      this.importVisible = false
      this.importTitle = ''
      this.current = 0
    },
    submitClick(file) {
      if (this.current == 0) {
        const fd = new FormData()
        fd.append('tenantId', this.tenantId)
        fd.append('file', file[0])
        fd.append('type', 0)
        util
          .postformData('inspectPlan/importEqu', fd)
          .then((res) => {
            console.log(res)
            if (res.code == 200) {
              this.importDataSource = res.data
              this.$message.success('导入成功')
            } else {
              this.$message.error(res.data.message)
            }
          })
          .catch((err) => {
            this.$message.error(err.response.data.message)
          })
        // fd.append('creater', this.userId)
      } else if (this.current == 1) {
        util
          .postform('inspectPlan/saveBetch', this.importDataSource)
          .then((res) => {
            console.log(res)
          })
        this.importName = '确认'
      }
      this.current++
    },
    // 查看详情
    openinfospecialEqusRecords(val) {
      util.postform('inspect/selectInfo/' + val.id).then((res) => {
        this.infodataSource = res.data
        this.infoList = res.data
        this.typePrimary = ''
        this.jywsDialogBottomName = '关闭'
        this.jywsDialogBottomRoger = ''
        this.modalTitle = '详情'
        this.visibleType = 'infospecialEqusRecords'
        this.visible = true
        this.visibleWidth = '960px'
      })
    },
    // 查询区域 - 时间范围变动
    onTimeChange(val) {
      this.searchForm.startTime = val.dateString[0]
      this.searchForm.endTime = val.dateString[1]
    },
    // 下拉框选中
    onHandleChange(item) {
      if (item.duplicate == 'credentialsType') {
        this.searchForm.credentialsType = item.value
      } else if (item.duplicate == 'isFile') {
        this.searchForm.isFile = item.value
      }
    },
    // 输入框
    onInputChange(item) {
      if (item.duplicate == 'project') {
        this.searchForm.project = item.value
      } else if (item.duplicate == 'executiveName') {
        this.searchForm.executiveName = item.value
      }
    },
  },
}
</script>

<style scoped lang="less">
.specialEqusRecords {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: auto;
}
.info-title {
  display: flex;
  height: 40px;
  align-items: center;
  .info-title-icon {
    width: 4px;
    height: 14px;
    background: #1890ff;
  }
  .info-title-text {
    margin-left: 8px;
    font-size: 16px;
    color: #333333;
  }
}
.specialEqusRecords-title {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.infospecialEqusRecords-title {
  font-size: 24px;
  color: #333333;
  text-align: left;
}
.infospecialEqusRecords-smtitle {
  display: flex;
  color: #999999;
  font-size: 12px;
  text-align: left;
  margin-top: 16px;
}
.infospecialEqusRecords-content {
  margin-top: 24px;
}
.mg-l-5 {
  margin-left: 5px;
}
.mg-l-50 {
  margin-left: 50px;
}
.title-icon {
  width: 4px;
  height: 16px;
  background: #1890ff;
  display: inline-block;
  margin-right: 12px;
}
.col-value {
  width: 200px;
}
.specialEqusRecords-content {
  background: #ffffff;
  width: 100%;
  height: calc(100% - 56px);
  display: flex;
}
.flush {
  font-size: 14px;
  color: #1890ff;
}
.content-right-btnGroup {
  height: 56px;
  width: 100%;
  line-height: 56px;
  margin-top: 10px;
}
.content-right-form {
  // width: 100%;
  background: #ffffff;
  padding: 0px 21px 0px 17px;
  .form-search {
    display: flex;
    align-items: center;
    width: 420px;
  }
}
.form-label {
  font-size: 14px;
  color: #333333;
  width: 70px;
  text-align: right;
  margin-right: 16px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-left {
  width: 220px;
  // height: calc(100% - 30px);
  height: 100%;
  background: #ffffff;
  border: 1px solid #e8e8e8;
  margin-left: 12px;
  overflow: auto;
  .content-left-title {
    height: 48px;
    width: 100%;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-btnGroup {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
  }
}
.content-right {
  height: calc(100% - 30px);
  width: calc(100% - 283px);
  margin-left: 27px;
}
.content-right-message {
  margin-top: 20px;
}
</style>

<template>
	<div class="outputBasicData2">
		<div class="outputBasicData2-content">
			<div class="content-right">
				<!-- 查询区域 -->
				<div class="content-right-form">
					<conditionQuery
						:headData="headData"
						@onTimeChange="onTimeChange"
						@exportExcel="exportExcel"
						@onHandleChange="onHandleChange"
						@onInputChange="onInputChange"
						@onSelectClick="getoutputBasicData2"
					/>
				</div>
				<!-- 功能按钮区域 -->
				<div class="content-right-btnGroup">
					<a-button-group>
						<a-button @click="onbtnGroup" name="addoutputBasicData2">新增</a-button>
						<a-button @click="onbtnGroup" name="editoutputBasicData2"> 修改 </a-button>
						<a-button @click="delBtn"> 删除 </a-button>
						<!-- <a-button @click="onbtnGroup" name="importExecl"> 批量导入 </a-button> -->
						<a-button @click="onbtnGroup" name="aboutLoad"> 关联产品 </a-button>
					</a-button-group>
				</div>
				<!-- 表格区域 -->
				<div class="content-right-table">
					<a-table
						:loading="loading"
						:columns="columns"
						:row-selection="rowSelection"
						:data-source="dataSource"
						:pagination="false"
						:scroll="{ y: 380 }"
					>
						<span slot="time" slot-scope="time">
							{{ time ? $moment(time).format('YYYY-MM-DD HH:mm') : '-' }}
						</span>
						<span slot="dateTime" slot-scope="dateTime">
							{{ dateTime ? $moment(dateTime).format('YYYY-MM-DD') : '-' }}
						</span>
						<span slot="cycle" slot-scope="text, record">
							{{ record.cycle + record.cycleUnit }}
						</span>
						<span slot="executiveName" slot-scope="text, record">
							{{ record.execType === 0 ? record.executiveName : record.nowRecipientsName }}
						</span>
						<span slot="credentialsState" slot-scope="text, record">
							<a-badge v-if="record.credentialsState === 0" status="success" text="正常" />
							<a-badge v-else-if="record.credentialsState === 2" status="error" text="超期" />
							<a-badge v-else-if="record.credentialsState === 1" status="warning" text="待检" />
						</span>
						<span slot="action" slot-scope="scope">
							<a @click="openinfooutputBasicData2(scope)" name="infooutputBasicData2">查看</a>
						</span>
					</a-table>
					<a-pagination
						@change="pageonChange"
						@showSizeChange="showSizeChange"
						size="small"
						:total="total"
						:defaultCurrent="searchForm.pageIndex"
						:defaultPageSize="searchForm.pageSize"
						show-size-changer
						show-quick-jumper
						:show-total="total => `总共 ${total} 条`"
					/>
				</div>
			</div>
		</div>
		<!-- 弹窗区域 -->
		<jywsDialog
			:visible="visible"
			:title="modalTitle"
			:width="visibleWidth"
			:roger="jywsDialogBottomRoger"
			:bottom-name="jywsDialogBottomName"
			@updataClose="closeModal"
			:typePrimary="typePrimary"
			@updataWin="updataWin"
		>
			<a-spin :spinning="spinning">
				<!-- 新增工艺路线 -->
				<div
					class="newTransfer-modal"
					v-if="visibleType === 'addoutputBasicData2' || visibleType === 'editoutputBasicData2'"
				>
					<div class="outputBasicData2-aboutLoad-head">
						<a-row :gutter="[32, 24]" justify="center" align="middle">
							<a-col :span="8" class="col-flex">
								<div class="col-label must">工艺路线名称</div>
								<div>
									<a-input v-model="addForm.name" placeholder="请输入工艺路线名称" />
								</div>
							</a-col>
						</a-row>
					</div>
					<div class="outputBasicData2-aboutLoad-transferTitle">
						<div class="transferTitle-left">
							<div class="transferTitle-name">工艺列表</div>
							<div>
								<a-button-group>
									<a-button name="addWorkmanship" @click="addWorkmanship">新增</a-button>
									<a-button name="editOplType" @click="editWorkmanship"> 修改 </a-button>
									<a-button @click="delWorkmanship"> 删除 </a-button>
								</a-button-group>
							</div>
						</div>
						<div class="transferTitle-right">
							<div class="transferTitle-name">工序步骤</div>
							<div>
								<a-alert message="从工艺列表勾选工艺后，点击向右蓝色剪头按钮即可添加工艺步骤" banner />
							</div>
						</div>
					</div>
					<div>
						<a-transfer
							v-if="visible"
							:data-source="mockData"
							:target-keys="targetKeys"
							:filter-option="(inputValue, item) => item.title.indexOf(inputValue) !== -1"
							:show-select-all="false"
							@change="ontransferChange"
						>
							<template
								slot="children"
								slot-scope="{
									props: { direction, filteredItems, selectedKeys, disabled: listDisabled },
									on: { itemSelectAll, itemSelect },
								}"
							>
								<a-table
									ref="nowTable"
									:row-selection="
										getRowSelection({
											disabled: listDisabled,
											selectedKeys,
											itemSelectAll,
											itemSelect,
										})
									"
									:columns="direction === 'left' ? leftColumns : rightColumns"
									:data-source="filteredItems"
									size="small"
									:style="{
										pointerEvents: listDisabled ? 'none' : null,
									}"
									:custom-row="
										({ key, disabled: itemDisabled }) => ({
											on: {
												click: () => {
													if (itemDisabled || listDisabled) return
													itemSelect(key, !selectedKeys.includes(key))
												},
											},
										})
									"
								>
									<span slot="nums" slot-scope="action, scope">
										<div>
											{{ getTableIndex(filteredItems, scope) }}
										</div>
									</span>
									<!-- <span slot="action" slot-scope="action, scope, index">
										<div>
											<a name="infooutputBasicData2" @click="upGo(filteredItems, index)">上移</a
											>&nbsp;
											<a name="infooutputBasicData2" @click="downGo(filteredItems, index)"
												>上移</a
											>
										</div>
									</span> -->
								</a-table>
							</template>
						</a-transfer>
					</div>
				</div>

				<!-- 关联产品 -->
				<div v-else-if="visibleType === 'aboutLoad'">
					<div class="outputBasicData2-aboutLoad-head">
						<a-row :gutter="[32, 24]" justify="center" align="middle">
							<a-col :span="16" class="col-flex">
								<div class="col-label">产品信息</div>
								<div>
									<a-input v-model="aboutLoadsearchForm.keyword" placeholder="请输入产品编码/名称" />
								</div>
							</a-col>
							<a-col :span="5" class="col-flex"> </a-col>
							<a-col :span="3" class="col-flex">
								<a-button class="marginl20" type="primary" icon="search" @click="getoutputBasicData"
									>查询</a-button
								>
							</a-col>
						</a-row>
					</div>
					<div class="outputBasicData2-aboutLoad-content">
						<a-table
							:loading="aboutLoadloading"
							:columns="aboutLoadcolumns"
							:row-selection="aboutLoadrowSelection"
							:data-source="aboutLoaddataSource"
							:pagination="false"
						>
						</a-table>
					</div>
					<div class="outputBasicData2-aboutLoad-bottom">
						<a-pagination
							@change="aboutLoadonChange"
							@showSizeChange="aboutLoadshowSizeChange"
							size="small"
							:total="aboutLoadtotal"
							:defaultCurrent="aboutLoadpageIndex"
							:defaultPageSize="aboutLoadpageSize"
							show-size-changer
							show-quick-jumper
							:show-total="aboutLoadtotal => `总共 ${aboutLoadtotal} 条`"
						/>
					</div>
				</div>
				<!-- 详情界面 -->
				<div v-else-if="visibleType === 'infooutputBasicData2'">
					<div class="info-content">
						<a-descriptions>
							<a-descriptions-item label="订单号">
								{{ this.infoList.orderId }}
							</a-descriptions-item>
							<a-descriptions-item label="产品名称" :span="2">
								{{ this.infoList.product }}
							</a-descriptions-item>
							<a-descriptions-item label="计划数">
								{{ this.infoList.planNum }}
							</a-descriptions-item>
							<a-descriptions-item label="生产数">
								{{ this.infoList.produceNum }}
							</a-descriptions-item>
							<a-descriptions-item label="合格数">
								{{ this.infoList.qualifiedNum }}
							</a-descriptions-item>
							<a-descriptions-item label="开始时间">
								{{ $moment(this.infoList.startTime).format('YYYY-MM-DD HH:mm') }}
							</a-descriptions-item>
							<a-descriptions-item label="完成时间">
								{{ $moment(this.infoList.finishTime).format('YYYY-MM-DD HH:mm') }}
							</a-descriptions-item>
						</a-descriptions>
					</div>
				</div>
			</a-spin>
		</jywsDialog>
		<!-- 次级弹窗 -->
		<jywsDialog
			:visible="othervisible"
			:title="othermodalTitle"
			:width="othervisibleWidth"
			:roger="otherjywsDialogBottomRoger"
			:bottom-name="otherjywsDialogBottomName"
			@updataClose="othercloseModal"
			:typePrimary="othertypePrimary"
			@updataWin="otherupdataWin"
		>
			<a-spin :spinning="otherspinning">
				<div>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="24" class="flex">
							<div class="col-label must">工艺名称</div>
							<div class="col-value">
								<a-input v-model="WorkmanshipName" placeholder="请输入工艺名称" />
							</div>
						</a-col>
					</a-row>
				</div>
			</a-spin>
		</jywsDialog>
		<importDataDialog
			:visible="importVisible"
			:title="importTitle"
			:url="importUrl"
			:current="current"
			@cancel="cancelClick"
			@submit="submitClick"
		/>
	</div>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import importDataDialog from '@/components/importDataDialog.vue'
import difference from 'lodash/difference'
import { urlDataDeal } from '@/core/date'
import moment from 'moment'
import * as util from '@/core/util'
const leftTableColumns = [
	{
		dataIndex: 'name',
		title: '工艺名称',
		key: 'name',
	},
]
const rightTableColumns = [
	// {
	// 	dataIndex: 'nums',
	// 	title: '排序',
	// 	key: 'nums',
	// 	scopedSlots: { customRender: 'nums' },
	// },
	{
		dataIndex: 'name',
		title: '工艺步骤',
		key: 'name',
	},
	// {
	// 	dataIndex: 'cz',
	// 	scopedSlots: { customRender: 'action' },
	// 	width: 100,
	// 	title: '操作',
	// 	key: 'cz',
	// },
]
export default {
	components: {
		conditionQuery,
		jywsDialog,
		// uploadOss,
		importDataDialog,
	},
	data() {
		return {
			tenantId: '',
			mockData: [],
			targetKeys: [],
			leftColumns: leftTableColumns,
			rightColumns: rightTableColumns,
			// 导入弹窗参数
			importVisible: false,
			importTitle: '批量导入',
			importUrl:
				'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E4%BA%A7%E9%87%8F%E7%AE%A1%E7%90%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
			importDataSource: [],
			current: 0,
			// 弹窗参数
			visible: false,
			visibleType: '',
			modalTitle: '',
			visibleWidth: '960px',
			jywsDialogBottomRoger: '取消',
			jywsDialogBottomName: '确认',
			typePrimary: 'primary',
			spinning: false,
			infoList: {},
			// 新增接口传参
			addForm: {
				name: '',
				processSteps: '',
			},
			editForm: {},
			infodataSource: [],
			// 表格表头
			columns: [
				{
					title: '工艺路线名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '工序步骤',
					dataIndex: 'processSteps',
					key: 'processSteps',
				},
				{
					title: '关联产品',
					dataIndex: 'associatedProducts',
					key: 'associatedProducts',
				},
			],
			dataSource: [],
			selectedRowKeys: [],
			selectedRows: [],
			headData: [
				{
					key: 1,
					name: '工艺路线名称',
					component: 'a-input',
					value: '',
					duplicate: 'productName',
					placeholder: '请输入工艺路线名称',
				},
			],
			// 查询特种设备列表参数
			searchForm: {
				name: '',
				startTime: '',
				endTime: '',
				pageIndex: 1,
				pageSize: 10,
				tenantId: '',
			},
			total: 10,
			oneTreeVisble: true,
			deviceTreeList: [],
			importName: '下一步',
			operUserId: '',
			operUserName: '',
			loading: false,
			aboutLoadselectedRowKeys: [],
			aboutLoadselectedRows: [],
			aboutLoaddataSource: [],
			aboutLoadsearchForm: {
				keyword: '',
			},
			aboutLoadpageIndex: 1,
			aboutLoadpageSize: 10,
			aboutLoadtotal: 10,
			aboutLoadcolumns: [
				{
					title: '产品编号',
					dataIndex: 'serialNumber',
					key: 'serialNumber',
				},
				{
					title: '产品名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '型号规格',
					dataIndex: 'specifications',
					key: 'specifications',
				},
				{
					title: '产品类型',
					dataIndex: 'type',
					key: 'type',
				},
				{
					title: '产品单位',
					dataIndex: 'unit',
					key: 'unit',
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
				},
			],
			othervisible: false,
			othermodalTitle: '新增工艺列表',
			othervisibleWidth: '540px',
			otherjywsDialogBottomRoger: '取消',
			othertypePrimary: 'primary',
			otherjywsDialogBottomName: '确认',
			otherspinning: false,
			WorkmanshipName: '',
			editWorkmanshipKeys:[]
		}
	},
	created() {
		var str = this.$route.fullPath.split('=')
		this.tenantId = str[1].split('&')[0]
		this.operUserId = str[2].split('&')[0]
		this.operUserName = decodeURIComponent(str[3].split('&')[0])
		
		
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},
		aboutLoadrowSelection() {
			return {
				type: 'checkbox',
				selectedRowKeys: this.aboutLoadselectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.aboutLoadselectedRowKeys = selectedRowKeys
					this.aboutLoadselectedRows = selectedRows
				},
			}
		},
		nextReleaseTime() {
			let timeText = ''
			if (this.addForm.periodTime && this.addForm.executiveDays) {
				timeText = moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD')
			}
			return timeText
		},
		getTableIndex(filteredItems, scope) {
			console.log(filteredItems)
			let newIndex = undefined
			filteredItems.forEach((item, index) => {
				if (item.title === scope.title) {
					newIndex = index + 1
				}
			})
			return newIndex
		},
	},
	mounted() {
		this.getoutputBasicData2()
	},
	methods: {
		// 新增工艺列表
		addWorkmanship() {
			this.othermodalTitle = '新增工艺列表'
			this.othervisibleWidth = '540px'
			this.otherjywsDialogBottomRoger = '取消'
			this.othertypePrimary = 'primary'
			this.otherjywsDialogBottomName = '确认'
			this.othervisible = true
		},
		// 修改工艺列表
		editWorkmanship(){
			console.log(this.$refs.nowTable);
			if(this.editWorkmanshipKeys.length !== 1 ){
				this.$message.error('请勾选一条需要修改的工艺！')
				return
			}
			this.WorkmanshipName = this.editWorkmanshipKeys[0].name
			this.othermodalTitle = '修改工艺列表'
			this.othervisibleWidth = '540px'
			this.otherjywsDialogBottomRoger = '取消'
			this.othertypePrimary = 'primary'
			this.otherjywsDialogBottomName = '确认'
			this.othervisible = true
		},
		// 获取工艺名称列表
		getWorkManShipList() {
			let dataset = {
				pageIndex: 1,
				pageSize: 99999,
				tenantId: this.tenantId,
			}
			util.postform('productCraftName/getPageList', dataset).then(res => {
				let data = res.data.records
				data.forEach(item => {
					item.key = item.id
				})
				this.mockData = data
			})
		},
		othercloseModal() {
			this.othervisible = false
		},
		// 新增/修改工艺名称  --- 确认按钮
		otherupdataWin() {
			if (!this.WorkmanshipName) {
				return this.$message.error('请输入工艺名称')
			}
			if (this.othermodalTitle === '新增工艺列表') {
				let dataset = {
					name: this.WorkmanshipName,
					tenantId: this.tenantId,
				}
				util.postform('productCraftName/add', dataset).then(res => {
					if (res.code == 200) {
						this.$message.success('新增成功！')
						this.getWorkManShipList()
						this.othervisible = false
						this.WorkmanshipName = ''
					}
				})
			}else {
				let dataset = {
					name: this.WorkmanshipName,
					tenantId: this.tenantId,
					id: this.editWorkmanshipKeys[0].id
				}
				util.postform('productCraftName/update', dataset).then(res => {
					if (res.code == 200) {
						this.$message.success('修改成功！')
						this.getWorkManShipList()
						this.othervisible = false
						this.WorkmanshipName = ''
					}
				})
			}
		},
		delWorkmanship(){
			let that = this
			if (this.editWorkmanshipKeys.length === 0) {
				this.$info({
					title: '提示',
					content: '请选择需要删除的记录！',
					onOk() {},
				})
				return
			}
			let arr = []
			this.editWorkmanshipKeys.forEach(item=>{
				arr.push(item.id)
			})
			this.$confirm({
				title: '删除提示！',
				content: '是否删除选中工艺？',
				onOk() {
					util.postform('productCraftName/delete', arr).then(res => {
						if (res.code === 200) {
							that.$message.success('删除成功！')
							that.getWorkManShipList()
							that.editWorkmanshipKeys = []
						}
					})
				},
				onCancel() {},
			})
		},
		upGo(arr, index) {
			// debugger
			// console.log(this.mockData)
			// console.log(index)
			// console.log(this.$refs.nowTable._props.dataSource)
			// const data = [this.$refs.nowTable._props.dataSource[index], this.$refs.nowTable._props.dataSource[index - 1]]
			// this.$set(this.$refs.nowTable._props.dataSource, index, data[1])
			// this.$set(this.$refs.nowTable._props.dataSource, index - 1, data[0])
			if (index != 0) {
				arr[index] = arr.splice(index - 1, 1, arr[index][0])
			} else {
				arr.push(arr.shift())
			}
			this.$forceUpdate()
		},
		downGo(arr, index) {
			if (index != arr.length - 1) {
				arr[index] = arr.splice(index + 1, 1, arr[index][0])
			} else {
				arr.unshift(arr.splice(index, 1)[0])
			}
		},
		ontransferChange(nextTargetKeys, direction, moveKeys) {
			this.editWorkmanshipKeys = []
			if (direction === 'right') {
				this.targetKeys = this.targetKeys.concat(moveKeys)
			} else if (direction === 'left') {
				moveKeys.forEach(item => {
					this.targetKeys.forEach((item2, index) => {
						if (item === item2) {
							this.targetKeys.splice(index, 1)
						}
					})
				})
			}
		},
		getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
			let that = this
			return {
				getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
				onSelectAll(selected, selectedRows) {
					that.editWorkmanshipKeys = selectedRows
					const treeSelectedKeys = selectedRows.filter(item => !item.disabled).map(({ key }) => key)
					const diffKeys = selected
						? difference(treeSelectedKeys, selectedKeys)
						: difference(selectedKeys, treeSelectedKeys)
					itemSelectAll(diffKeys, selected)
				},
				onSelect({ key }, selected,keys) {
					that.editWorkmanshipKeys = keys
					console.log(that.editWorkmanshipKeys);
					itemSelect(key, selected)
				},
				selectedRowKeys: selectedKeys,
			}
		},
		// 展开设备树
		expandTree(item) {
			this.dynamicLoadingTree(item)
		},
		// 刷新
		reflush() {
			this.initTree()
		},
		// 查询区域 - 时间范围变动
		onTimeChange(val) {
			this.searchForm.startTime = val.dateString[0]
			this.searchForm.endTime = val.dateString[1]
		},
		// 选中设备树的回调
		treeSelect(val, data) {
			if (val.length !== 0) {
				this.searchForm.id = data.id
				if (data.type === 'dep') {
					this.searchForm.isDept = true
				} else {
					this.searchForm.isDept = false
				}
			} else {
				this.searchForm.id = ''
				this.searchForm.isDept = false
			}
			this.getoutputBasicData2()
		},
		// 导出
		exportExcel() {
			this.searchForm.tenantId = this.tenantId
			let urldata = urlDataDeal(this.searchForm)
			let downUrl = util.getBaseURL() + 'product/exportProduct?' + urldata
			window.open(downUrl)
		},
		// 关闭弹窗
		closeModal() {
			this.othervisible = false
			this.visible = false
			this.mockData = []
			this.targetKeys = []
			this.addForm = {
				orderId: '',
				product: '',
				planNum: '',
				produceNum: '',
				qualifiedNum: '',
				startTime: '',
				finishTime: '',
				operUserId: '',
				operUserName: '',
			}
			this.selectedRowKeys = []
			this.selectedRows = []
			this.aboutLoadselectedRowKeys = []
			this.aboutLoadselectedRows = []
			this.aboutLoadsearchForm = {
				keyword: ''
			}
			this.aboutLoadpageIndex = 1
			this.aboutLoadpageSize = 10
			this.getoutputBasicData2()
		},
		// 弹窗确认按钮
		updataWin() {
			if (this.visibleType === 'addoutputBasicData2') {
				this.addoutputBasicData2Btn()
			} else if (this.visibleType === 'editoutputBasicData2') {
				this.editoutputBasicData2Btn()
			} else if (this.visibleType === 'changePlan') {
				this.changePlanBtn()
			} else if (this.visibleType === 'aboutLoad') {
				this.aboutLoadBtn()
			} else if (this.visibleType === 'infooutputBasicData2') {
				this.closeModal()
			}
		},
		// 获取工艺列表
		getoutputBasicData2() {
			this.loading = true
			this.searchForm.tenantId = this.tenantId
			util.postform('productCraftInfo/getPageList', this.searchForm).then(res => {
				let temp = res.data.records
				temp.forEach(item => {
					item.key = item.id
				})
				this.loading = false
				this.dataSource = temp
				this.total = res.data.total
			})
		},
		// 翻页
		pageonChange(page) {
			this.searchForm.pageIndex = page
			this.getoutputBasicData2()
		},
		aboutLoadonChange(page) {
			this.aboutLoadpageIndex = page
			this.getoutputBasicData()
		},
		// 页数size改变
		aboutLoadshowSizeChange(key, size) {
			this.aboutLoadpageSize = size
			this.getoutputBasicData()
		},
		// 页数size改变
		showSizeChange(key, size) {
			this.searchForm.pageSize = size
			this.getoutputBasicData2()
		},
		// 关联产品确认
		aboutLoadBtn() {
			if (this.aboutLoadselectedRowKeys.length === 0) {
				this.$message.error('请勾选需要关联的产品信息！')
				return
			}
			let dataset = {
				craftId: this.selectedRows[0].id,
				productIds: this.aboutLoadselectedRowKeys,
				tenantId: this.tenantId,
				updateUserId: this.operUserId,
				updateUserName: this.operUserName,
			}
			util.postform('productInfo/craftAssociateProducts', dataset)
				.then(res => {
					if (res.code == 200) {
						this.$message.success('关联成功！')
						this.closeModal()
					}
				})
				.catch(res => {
					this.$message.error(res.response.data.message)
				})
		},
		// 新增产量管理
		addoutputBasicData2Btn() {
			if (this.addForm.name === '' || !this.addForm.name) {
				this.$message.error('工艺路线名称不能为空！')
				return
			}
			let arr = this.$refs.nowTable._props.dataSource
			let newArr = []
			arr.forEach(item => {
				newArr.push(item.name)
			})
			if(newArr.length === 0){
				this.$message.error('工艺步骤不能为空！')
				return
			}
			this.addForm.tenantId = this.tenantId
			this.addForm.createUserId = this.operUserId
			this.addForm.updateUserId = this.operUserId
			this.addForm.createUserName = this.operUserName
			this.addForm.updateUserName = this.operUserName
			this.addForm.processSteps = newArr.join('→')
			util.postform('productCraftInfo/add', this.addForm)
				.then(res => {
					if (res.code == 200) {
						this.$message.success('新增成功！')
						this.closeModal()
					}
				})
				.catch(res => {
					this.$message.error(res.response.data.message)
				})
		},
		// 修改产量管理
		editoutputBasicData2Btn() {
			let arr = this.$refs.nowTable._props.dataSource
			let newArr = []
			arr.forEach(item => {
				newArr.push(item.name)
			})
			if(newArr.length === 0){
				this.$message.error('工艺步骤不能为空！')
				return
			}
			let dataset = {
				id: this.addForm.id,
				name: this.addForm.name,
				processSteps: newArr.join('→'),
				tenantId: this.tenantId,
				updateUserId: this.operUserId,
				updateUserName: this.operUserName
			}
			util.postform('productCraftInfo/update', dataset).then(res => {
				if (res.code == 200) {
					this.$message.success('修改成功！')
					this.closeModal()
				} else {
					this.$message.error('修改失败！')
				}
			})
		},
		// 变更下发计划
		changePlanBtn() {
			let dataset = {
				beforeDay: this.addForm.executiveDays,
				nextInspectTime: moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD'),
				periodTime: this.addForm.periodTime,
				id: this.selectedRows[0].id,
			}
			util.postform('inspectPlan/changePlan', dataset).then(res => {
				if (res.code == 200) {
					this.$message.success('变更成功！')
					this.closeModal()
				}
			})
		},
		// 删除
		delBtn() {
			let that = this
			if (this.selectedRowKeys.length === 0) {
				this.$info({
					title: '提示',
					content: '请选择需要删除的记录！',
					onOk() {},
				})
				return
			}
			this.$confirm({
				title: '删除提示！',
				content: '是否删除选中工艺信息？',
				onOk() {
					util.postform('productCraftInfo/delete', that.selectedRowKeys).then(res => {
						if (res.code === 200) {
							that.$message.success('删除成功！')
							that.getoutputBasicData2()
						}
					})
				},
				onCancel() {},
			})
		},
		// 功能按钮操作
		onbtnGroup(e) {
			let name = e.target.name
			if (name === 'editoutputBasicData2') {
				if (this.selectedRows.length !== 1) {
					this.$warning({
						title: '请选择一条记录进行修改操作！',
					})
					return
				}
				let temp = this.selectedRows[0]
				this.addForm = temp
			} else if (name == 'changePlan') {
				if (this.selectedRows.length !== 1) {
					this.$warning({
						title: '请选择一条记录进行修改操作！',
					})
					return
				}
			}
			// 关联产品
			else if (name == 'aboutLoad') {
				if (this.selectedRows.length !== 1) {
					this.$warning({
						title: '请选择一条记录进行修改操作！',
					})
					return
				}
				this.getoutputBasicData()
			}
			this.getWorkManShipList()
			this.showModal(e)
		},
		// 获取产品列表
		getoutputBasicData() {
			this.aboutLoadloading = true
			this.aboutLoadsearchForm.tenantId = this.tenantId
			this.aboutLoadsearchForm.pageIndex = this.aboutLoadpageIndex
			this.aboutLoadsearchForm.pageSize = this.aboutLoadpageSize
			util.postform('productInfo/getPageList', this.aboutLoadsearchForm).then(res => {
				let temp = res.data.records
				temp.forEach(item => {
					item.key = item.id
				})
				this.aboutLoadloading = false
				this.aboutLoaddataSource = temp
				this.aboutLoadtotal = res.data.total
			})
		},
		// 选择执行人/组
		onSelectPeo(val) {
			if (val.obj.length === 0) {
				return
			} else {
				if (val.obj[0].groupId) {
					this.addForm.execType = 1
					this.addForm.nowRecipients = val.obj[0].groupId
					this.addForm.nowRecipientsName = val.obj[0].groupName
				} else {
					this.addForm.execType = 0
					this.addForm.executive = val.obj[0].c_id
					this.addForm.executiveName = val.obj[0].c_name
				}
			}
		},
		// 选择抄送人
		onSelectPeocc(val) {
			if (val.obj.length === 0) {
				return
			} else {
				this.addForm.cc = val.obj[0].c_id
				this.addForm.ccName = val.obj[0].c_name
			}
		},
		// 选择设备
		onSelectPeoEqus(val) {
			if (val.obj.length === 0) {
				return
			} else {
				this.addForm.objectId = val.obj[0].c_id
				this.addForm.objectName = val.obj[0].c_name
				this.addForm.organizationId = val.obj[0].c_organization_id
				this.addForm.organizationName = val.obj[0].depName
			}
		},
		// 上传附件 -- 获取oss地址
		setFileList(val) {
			this.addForm.credentialsFile = val
		},
		setFileListName(val) {
			this.addForm.fileName = val
		},
		showModal(e) {
			let name = e.target.name

			this.visibleType = name
			console.log(this.visibleType)
			switch (name) {
				case 'addoutputBasicData2':
					this.modalTitle = '新增'
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					this.visible = true
					break
				case 'editoutputBasicData2':
					this.modalTitle = '修改'
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					this.visible = true
					break
				case 'infooutputBasicData2':
					this.modalTitle = '详情'
					this.visibleWidth = '960px'
					this.typePrimary = ''
					this.jywsDialogBottomName = '关闭'
					this.jywsDialogBottomRoger = ''
					this.visible = true
					break
				case 'aboutLoad':
					this.modalTitle = '关联产品'
					this.visible = true
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					break
				case 'importExecl':
					this.importVisible = true
					break
				default:
					break
			}
		},
		cancelClick() {
			this.importVisible = false
		},
		submitClick(file) {
			const fd = new FormData()
			fd.append('tenantId', this.tenantId)
			fd.append('operUserName', this.operUserId)
			fd.append('file', file[0])
			util.postformData('product/importExcel', fd)
				.then(res => {
					if (res.code == 200) {
						this.importDataSource = res.data
						this.$message.success('导入成功')
						this.importVisible = false
					} else {
						this.$message.error(res.data.message)
					}
				})
				.catch(err => {
					this.$message.error(err.response.data.message)
				})
		},
		// 查看详情
		openinfooutputBasicData2(val) {
			util.postform('inspect/getInspectByPlanIdList/' + val.id).then(res => {
				this.infodataSource = res.data
				this.infoList = val
				this.typePrimary = ''
				this.jywsDialogBottomName = '关闭'
				this.jywsDialogBottomRoger = ''
				this.modalTitle = '详情'
				this.visibleType = 'infooutputBasicData2'
				this.visible = true
				this.visibleWidth = '960px'
			})

			console.log(val)
		},

		// 下拉框选中
		onHandleChange(item) {
			if (item.duplicate == 'credentialsType') {
				this.searchForm.credentialsType = item.value
			} else if (item.duplicate == 'credentialsState') {
				this.searchForm.credentialsState = item.value
			}
		},
		// 输入框
		onInputChange(item) {
			if (item.duplicate == 'productName') {
				this.searchForm.name = item.value
			} else if (item.duplicate == 'executor') {
				this.searchForm.executor = item.value
			}
		},
	},
}
</script>

<style scoped lang="less">
.outputBasicData2 {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	// display: flex;
	// justify-content: space-between;
	box-sizing: border-box;
	overflow: auto;
}
.info-title {
	display: flex;
	height: 40px;
	align-items: center;
	.info-title-icon {
		width: 4px;
		height: 14px;
		background: #1890ff;
	}
	.info-title-text {
		margin-left: 8px;
		font-size: 16px;
		color: #333333;
	}
}
.outputBasicData2-title {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border-radius: 4px 4px 0px 0px;
	color: #333333;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.infooutputBasicData2-title {
	font-size: 24px;
	color: #333333;
	text-align: left;
}
.infooutputBasicData2-smtitle {
	display: flex;
	color: #999999;
	font-size: 12px;
	text-align: left;
	margin-top: 16px;
}
.infooutputBasicData2-content {
	margin-top: 24px;
}
.mg-l-5 {
	margin-left: 5px;
}
.mg-l-50 {
	margin-left: 50px;
}
.outputBasicData2-aboutLoad-transferTitle {
	display: flex;
	.transferTitle-left {
		margin-left: 15px;
		width: calc(46% - 15px);
	}
	.transferTitle-name {
		font-size: 16px;
		color: #333333;
		letter-spacing: 0.32px;
		margin-top: 24px;
		margin-bottom: 16px;
	}
}
.newTransfer-modal {
	/deep/ .ant-transfer-list {
		border: none !important;
		padding-top: 0 !important;
	}
	/deep/ .ant-transfer-list-header {
		display: none;
	}
}
.title-icon {
	width: 4px;
	height: 16px;
	background: #1890ff;
	display: inline-block;
	margin-right: 12px;
}
.col-value {
	width: 200px;
}
.outputBasicData2-aboutLoad-head {
	padding-bottom: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.outputBasicData2-content {
	background: #ffffff;
	width: 100%;
	height: 100%;
	display: flex;
}
.flush {
	font-size: 14px;
	color: #1890ff;
}
.content-right-btnGroup {
	height: 56px;
	width: 100%;
	line-height: 56px;
	margin-top: 10px;
}
.content-right-form {
	// width: 100%;
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
}
.form-label {
	font-size: 14px;
	color: #333333;
	width: 70px;
	text-align: right;
	margin-right: 16px;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-left {
	width: 220px;
	height: calc(100% - 30px);
	background: #ffffff;
	border: 1px solid #e8e8e8;
	margin-left: 12px;
	overflow: auto;
	.content-left-title {
		height: 48px;
		width: 100%;
		padding: 0 16px 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #e8e8e8;
	}
	.content-left-btnGroup {
		height: 48px;
		width: 100%;
		border-bottom: 1px solid #e8e8e8;
	}
}
.content-right {
	height: calc(100% - 30px);
	width: calc(100% - 60px);
	margin-left: 27px;
}
.content-right-message {
	margin-top: 20px;
}
</style>

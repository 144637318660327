import { render, staticRenderFns } from "./specialEqusRecords.vue?vue&type=template&id=5508000e&scoped=true&"
import script from "./specialEqusRecords.vue?vue&type=script&lang=js&"
export * from "./specialEqusRecords.vue?vue&type=script&lang=js&"
import style0 from "./specialEqusRecords.vue?vue&type=style&index=0&id=5508000e&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5508000e",
  null
  
)

export default component.exports
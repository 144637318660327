<template>
	<div class="outputManagementOrders">
		<div class="outputManagementOrders-content">
			<div class="content-right">
				<!-- 查询区域 -->
				<div class="content-right-form">
					<conditionQuery
						:headData="headData"
						@onTimeChange="onTimeChange"
						@onHandleChange="onHandleChange"
						@onInputChange="onInputChange"
						@onSelectClick="getoutputManagementOrders"
					/>
				</div>
				<!-- 功能按钮区域 -->
				<div class="content-right-btnGroup">
					<a-button-group>
						<a-button @click="onbtnGroup" name="addoutputManagementOrders">创建订单</a-button>
						<a-button @click="delBtn"> 批量删除 </a-button>
						<!-- <a-button @click="delBtn"> 结束订单 </a-button> -->
						<a-button @click="onbtnGroup" name="importExecl"> 批量导入 </a-button>
						<a-button @click="onbtnGroup" name="aboutLoad"> 关联产品 </a-button>
						<a-button @click="onbtnGroup" name="endWorkOrder"> 结束订单 </a-button>
					</a-button-group>
				</div>
				<!-- 表格区域 -->
				<div class="content-right-table">
					<a-table
						:loading="loading"
						:columns="columns"
						:row-selection="rowSelection"
						:data-source="dataSource"
						:pagination="false"
						:scroll="{ y: 380 }"
					>
						<span slot="time" slot-scope="time">
							{{ time ? $moment(time).format('YYYY-MM-DD HH:mm') : '-' }}
						</span>
						<span slot="dateTime" slot-scope="dateTime">
							{{ dateTime ? $moment(dateTime).format('YYYY-MM-DD') : '-' }}
						</span>
						<span slot="associatedProductNum" slot-scope="text, record">
							{{ record.associatedProductNum }} |
							<a @click="showInfoModal(record)" name="infooutputManagementOrders">查看</a>
						</span>
						<span slot="progress" slot-scope="progress"> {{ progress }}% </span>
						<span slot="remark" slot-scope="remark">
							{{ remark ? remark : '/' }}
						</span>
						<span slot="postStatus" slot-scope="text, record">
							<a-badge v-if="record.postStatus === 1" status="success" text="是" />
							<a-badge v-else-if="record.postStatus === 0" status="error" text="否" />
						</span>
						<span slot="orderStatus" slot-scope="text, record">
							<a-badge v-if="record.orderStatus === 0" status="success" text="正常" />
							<a-badge v-else-if="record.orderStatus === 1" status="error" text="已结束" />
						</span>
						<span slot="action" slot-scope="scope">
							<span v-if="scope.postStatus === 0">
								<a @click="showoneData(scope)">发布 </a>
								|
							</span>
							<a @click="editShowModal(scope)">修改 </a> |
							<a @click="deloneData(scope)">删除</a>
						</span>
					</a-table>
					<a-pagination
						@change="pageonChange"
						@showSizeChange="showSizeChange"
						size="small"
						:total="total"
						:defaultCurrent="searchForm.pageIndex"
						:defaultPageSize="searchForm.pageSize"
						show-size-changer
						show-quick-jumper
						:show-total="total => `总共 ${total} 条`"
					/>
				</div>
			</div>
		</div>
		<!-- 弹窗区域 -->
		<jywsDialog
			:visible="visible"
			:title="modalTitle"
			:width="visibleWidth"
			:roger="jywsDialogBottomRoger"
			:bottom-name="jywsDialogBottomName"
			@updataClose="closeModal"
			:typePrimary="typePrimary"
			@updataWin="updataWin"
		>
			<a-spin :spinning="spinning">
				<!-- 新增订单 -->
				<div
					class="newTransfer-modal"
					v-if="visibleType === 'addoutputManagementOrders' || visibleType === 'editoutputManagementOrders'"
				>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">订单号</div>
							<div class="col-value">
								<a-input v-model="addForm.orderNumber" placeholder="请输入订单号" />
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">客户名称</div>
							<div class="col-value">
								<a-input v-model="addForm.customerName" placeholder="请输入客户名称" />
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label">联系人</div>
							<div class="col-value">
								<a-input v-model="addForm.contact" placeholder="请输入联系人" />
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label">联系电话</div>
							<div class="col-value">
								<a-input v-model="addForm.contactPhone" placeholder="请输入联系电话" />
							</div>
						</a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="12" :push="1" class="col-flex">
							<div class="col-label must">交付日期</div>
							<div class="col-value">
								<a-date-picker style="width: 200px" v-model="addForm.deliveryDay" />
							</div>
						</a-col>
						<a-col :span="12" :push="1" class="col-flex"> </a-col>
					</a-row>
					<a-row :gutter="[32, 24]" justify="center" align="middle">
						<a-col :span="24" :push="1" class="col-flex">
							<div class="col-label">备注</div>
							<div style="width: calc(100% - 240px)">
								<a-textarea
									v-model="addForm.remark"
									placeholder="请输入"
									:auto-size="{ minRows: 3, maxRows: 5 }"
								/>
							</div>
						</a-col>
					</a-row>
				</div>

				<!-- 关联产品 -->
				<div v-else-if="visibleType === 'aboutLoad'">
					<div class="aboutLoadInfo-head">
						<a-descriptions :column="2">
							<a-descriptions-item label="订单号"> {{ aboutLoadInfo.orderNumber }} </a-descriptions-item>
							<a-descriptions-item label="客户名称">
								{{ aboutLoadInfo.customerName }}
							</a-descriptions-item>
							<a-descriptions-item label="联系人"> {{ aboutLoadInfo.contact }} </a-descriptions-item>
							<a-descriptions-item label="联系电话">
								{{ aboutLoadInfo.contactPhone }}
							</a-descriptions-item>
							<a-descriptions-item label="交付日期">
								{{ $moment(aboutLoadInfo.deliveryDay).format('YYYY-MM-DD') }}
							</a-descriptions-item>
							<a-descriptions-item label="备注">
								{{ aboutLoadInfo.remark }}
							</a-descriptions-item>
						</a-descriptions>
					</div>
					<div class="aboutLoadInfo-line"></div>
					<div class="aboutLoadInfo-btn">
						<a-button @click="addWorkmanship" name="addoutputBasicData">添加关联产品</a-button>
						<div class="aboutLoadInfo-btn-text">添加关联产品后请输入各产品订单数量</div>
					</div>
					<div class="aboutLoadInfo-table">
						<a-table
							class="borderTable"
							:columns="LoadInfocolumns"
							:data-source="LoadInfodataSource"
							:pagination="false"
							:scroll="{ y: 380 }"
						>
							<span slot="num" slot-scope="text, record">
								<a-input
									:min="0"
									:value="record.num"
									type="number"
									@change="onNumInputChange($event, record)"
								></a-input>
							</span>
							<span slot="cz" slot-scope="text, record">
								<a style="color: #ff4d4f" @click="delLoadInfodata(record)">删除</a>
							</span>
						</a-table>
					</div>
				</div>
				<!-- 详情界面 -->
				<div v-else-if="visibleType === 'infooutputManagementOrders'">
					<div class="aboutLoadInfo-head">
						<a-descriptions :column="2">
							<a-descriptions-item label="订单号"> {{ infoOrdersData.orderNumber }} </a-descriptions-item>
							<a-descriptions-item label="客户名称">
								{{ infoOrdersData.customerName }}
							</a-descriptions-item>
							<a-descriptions-item label="联系人"> {{ infoOrdersData.contact }} </a-descriptions-item>
							<a-descriptions-item label="联系电话">
								{{ infoOrdersData.contactPhone }}
							</a-descriptions-item>
							<a-descriptions-item label="交付日期">
								{{ $moment(infoOrdersData.deliveryDay).format('YYYY-MM-DD') }}
							</a-descriptions-item>
							<a-descriptions-item label="备注">
								{{ infoOrdersData.remark }}
							</a-descriptions-item>
						</a-descriptions>
					</div>
					<div class="aboutLoadInfo-line"></div>
					<div class="aboutLoadInfo-table">
						<a-table
							class="borderTable"
							:columns="newLoadInfocolumns"
							:data-source="newLoadInfodataSource"
							:pagination="false"
							:scroll="{ y: 380 }"
						></a-table>
					</div>
				</div>
			</a-spin>
		</jywsDialog>
		<!-- 次级弹窗 -->
		<jywsDialog
			:visible="othervisible"
			:title="othermodalTitle"
			:width="othervisibleWidth"
			:roger="otherjywsDialogBottomRoger"
			:bottom-name="otherjywsDialogBottomName"
			@updataClose="othercloseModal"
			:typePrimary="othertypePrimary"
			@updataWin="otherupdataWin"
		>
			<a-spin :spinning="otherspinning">
				<div v-if="othervisibleType === 'aboutLoad'">
					<div class="outputManagementOrders-aboutLoad-head">
						<a-row :gutter="[32, 24]" justify="center" align="middle">
							<a-col :span="8" class="col-flex">
								<div class="col-label">产品编码</div>
								<div>
									<a-input v-model="addForm.qualifiedNum" placeholder="请输入产品编码" />
								</div>
							</a-col>
							<a-col :span="8" class="col-flex">
								<div class="col-label">产品名称</div>
								<div>
									<a-input v-model="addForm.qualifiedNum" placeholder="请输入产品名称" />
								</div>
							</a-col>
							<a-col :span="5" class="col-flex"> </a-col>
							<a-col :span="3" class="col-flex">
								<a-button class="marginl20" type="primary" icon="search" @click="getoutputBasicData"
									>查询</a-button
								>
							</a-col>
						</a-row>
					</div>
					<div class="outputManagementOrders-aboutLoad-content">
						<a-table
							:columns="aboutLoadcolumns"
							:row-selection="aboutLoadrowSelection"
							:data-source="aboutLoaddataSource"
							:pagination="false"
						>
						</a-table>
					</div>
					<div class="outputManagementOrders-aboutLoad-bottom">
						<a-pagination
							@change="aboutLoadonChange"
							@showSizeChange="aboutLoadshowSizeChange"
							size="small"
							:total="aboutLoadtotal"
							:defaultCurrent="aboutLoadsearchForm.pageIndex"
							:defaultPageSize="aboutLoadsearchForm.pageSize"
							show-size-changer
							show-quick-jumper
							:show-total="aboutLoadtotal => `总共 ${aboutLoadtotal} 条`"
						/>
					</div>
				</div>
			</a-spin>
		</jywsDialog>
		<importDataDialog
			:visible="importVisible"
			:title="importTitle"
			:url="importUrl"
			:current="current"
			@cancel="cancelClick"
			@submit="submitClick"
		/>
	</div>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import importDataDialog from '@/components/importDataDialog.vue'
import difference from 'lodash/difference'
import { urlDataDeal } from '@/core/date'
import moment from 'moment'
import * as util from '@/core/util'
const leftTableColumns = [
	{
		dataIndex: 'description',
		title: '工艺名称',
		key: 'description',
	},
]
const rightTableColumns = [
	{
		dataIndex: 'title',
		title: '排序',
		key: 'title',
	},
	{
		dataIndex: 'description',
		title: '工艺步骤',
		key: 'description',
	},
	{
		dataIndex: 'cz',
		scopedSlots: { customRender: 'action' },
		width: 100,
		title: '操作',
		key: 'cz',
	},
]
export default {
	components: {
		conditionQuery,
		jywsDialog,
		// uploadOss,
		importDataDialog,
	},
	data() {
		return {
			tenantId: '',
			mockData: [
				{ title: '1', description: '下料', key: 0 },
				{ title: '2', description: '出货', key: 1 },
			],
			newLoadInfodataSource: [],
			newLoadInfocolumns: [
				{
					title: '产品编号',
					dataIndex: 'serialNumber',
					key: 'serialNumber',
				},
				{
					title: '产品名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '型号规格',
					dataIndex: 'specifications',
					key: 'specifications',
				},
				{
					title: '产品类型',
					dataIndex: 'type',
					key: 'type',
				},
				{
					title: '订单数量',
					dataIndex: 'num',
					key: 'num',
				},
			],
			LoadInfodataSource: [],
			LoadInfocolumns: [
				{
					title: '产品编号',
					dataIndex: 'serialNumber',
					key: 'serialNumber',
				},
				{
					title: '产品名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '型号规格',
					dataIndex: 'specifications',
					key: 'specifications',
				},
				{
					title: '产品类型',
					dataIndex: 'type',
					key: 'type',
				},
				{
					title: '订单数量',
					dataIndex: 'num',
					key: 'num',
					scopedSlots: { customRender: 'num' },
				},
				{
					title: '操作',
					dataIndex: 'cz',
					key: 'cz',
					scopedSlots: { customRender: 'cz' },
				},
			],
			targetKeys: [],
			leftColumns: leftTableColumns,
			rightColumns: rightTableColumns,
			// 导入弹窗参数
			importVisible: false,
			importTitle: '批量导入',
			importUrl:
				'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E8%AE%A2%E5%8D%95%E4%BF%A1%E6%81%AF%E5%AF%BC%E5%85%A5.xls',
			importDataSource: [],
			current: 0,
			// 弹窗参数
			visible: false,
			visibleType: '',
			modalTitle: '',
			visibleWidth: '960px',
			jywsDialogBottomRoger: '取消',
			jywsDialogBottomName: '确认',
			typePrimary: 'primary',
			spinning: false,
			infoList: {},
			// 新增接口传参
			addForm: {
				orderNumber: '',
				customerName: '',
				contact: '',
				contactPhone: '',
				deliveryDay: '',
				remark: '',
			},
			editForm: {},
			infodataSource: [],
			// 表格表头
			columns: [
				{
					title: '订单号',
					dataIndex: 'orderNumber',
					key: 'orderNumber',
				},
				{
					title: '客户名称',
					dataIndex: 'customerName',
					key: 'customerName',
				},
				{
					title: '联系人',
					dataIndex: 'contact',
					key: 'contact',
				},
				{
					title: '联系电话',
					dataIndex: 'contactPhone',
					key: 'contactPhone',
				},
				{
					title: '交付日期',
					dataIndex: 'deliveryDay',
					key: 'deliveryDay',
					scopedSlots: { customRender: 'dateTime' },
				},
				{
					title: '开始时间',
					dataIndex: 'startTime',
					key: 'startTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '完成时间',
					dataIndex: 'endTime',
					key: 'endTime',
					scopedSlots: { customRender: 'time' },
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
					scopedSlots: { customRender: 'remark' },
				},
				{
					title: '完成进度',
					dataIndex: 'progress',
					key: 'progress',
					scopedSlots: { customRender: 'progress' },
				},
				{
					title: '发布状态',
					dataIndex: 'postStatus',
					key: 'postStatus',
					scopedSlots: { customRender: 'postStatus' },
				},
				{
					title: '订单状态',
					dataIndex: 'orderStatus',
					key: 'orderStatus',
					scopedSlots: { customRender: 'orderStatus' },
				},
				{
					title: '关联产品',
					dataIndex: 'associatedProductNum',
					key: 'associatedProductNum',
					scopedSlots: { customRender: 'associatedProductNum' },
				},
				{
					title: '操作',
					scopedSlots: { customRender: 'action' },
					key: 'cz',
					width: 150,
				},
			],
			dataSource: [],
			selectedRowKeys: [],
			selectedRows: [],
			headData: [
				{
					key: 1,
					name: '订单号',
					component: 'a-input',
					value: '',
					duplicate: 'orderNumber',
					placeholder: '请输入订单号',
				},
				{
					key: 2,
					name: '客户名称',
					component: 'a-input',
					value: '',
					duplicate: 'customerName',
					placeholder: '请输入客户名称',
				},
			],
			// 查询特种设备列表参数
			searchForm: {
				orderNumber: '',
				customerName: '',
				pageIndex: 1,
				pageSize: 10,
				tenantId: '',
			},
			total: 10,
			oneTreeVisble: true,
			deviceTreeList: [],
			importName: '下一步',
			operUserId: '',
			operUserName: '',
			loading: false,
			aboutLoadInfo: {},
			aboutLoadselectedRowKeys: [],
			aboutLoadselectedRows: [],
			aboutLoaddataSource: [],
			aboutLoadcolumns: [
				{
					title: '产品编号',
					dataIndex: 'serialNumber',
					key: 'serialNumber',
				},
				{
					title: '产品名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '型号规格',
					dataIndex: 'specifications',
					key: 'specifications',
				},
				{
					title: '产品类型',
					dataIndex: 'type',
					key: 'type',
				},
				{
					title: '产品单位',
					dataIndex: 'unit',
					key: 'unit',
				},
				{
					title: '备注',
					dataIndex: 'remark',
					key: 'remark',
				},
			],
			othervisible: false,
			othermodalTitle: '选择产品',
			othervisibleWidth: '540px',
			otherjywsDialogBottomRoger: '取消',
			othertypePrimary: 'primary',
			otherjywsDialogBottomName: '确认',
			otherspinning: false,
			infoOrdersData: {},
			aboutLoadsearchForm: {
				pageIndex: 1,
				pageSize: 10,
			},
			aboutLoadtotal: 10,
			othervisibleType: '',
			WorkmanshipName: '',
		}
	},
	created() {
		var str = this.$route.fullPath.split('=')
		this.tenantId = str[1].split('&')[0]
		this.operUserId = str[2].split('&')[0]
		this.operUserName = decodeURIComponent(str[3].split('&')[0])
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},
		aboutLoadrowSelection() {
			return {
				selectedRowKeys: this.aboutLoadselectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.aboutLoadselectedRowKeys = selectedRowKeys
					this.aboutLoadselectedRows = selectedRows
				},
			}
		},
		nextReleaseTime() {
			let timeText = ''
			if (this.addForm.periodTime && this.addForm.executiveDays) {
				timeText = moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD')
			}
			return timeText
		},
	},
	mounted() {
		this.getoutputManagementOrders()
	},
	methods: {
		// 新增工艺列表
		addWorkmanship() {
			this.othermodalTitle = '选择产品'
			this.othervisibleWidth = '960px'
			this.otherjywsDialogBottomRoger = '取消'
			this.othertypePrimary = 'primary'
			this.otherjywsDialogBottomName = '确认'
			this.othervisibleType = 'aboutLoad'
			this.othervisible = true
		},
		// 获取产品列表
		getoutputBasicData() {
			this.otherspinning = true
			this.aboutLoadsearchForm.tenantId = this.tenantId
			util.postform('productInfo/getPageList', this.aboutLoadsearchForm).then(res => {
				let temp = res.data.records
				temp.forEach(item => {
					item.key = item.id
				})
				this.otherspinning = false
				this.aboutLoaddataSource = temp
				this.aboutLoadtotal = res.data.total
			})
		},
		othercloseModal() {
			this.othervisible = false
		},
		// 删除已关联产品列表
		delLoadInfodata(val) {
			this.LoadInfodataSource.forEach((item, index) => {
				if (item.id === val.id) {
					this.LoadInfodataSource.splice(index, 1)
				}
			})
		},
		// 确认关联产品按钮
		otherupdataWin() {
			if (this.aboutLoadselectedRowKeys.length === 0) {
				this.$message.error('请勾选需要关联的产品信息！')
				return
			}
			this.aboutLoadselectedRows.forEach(item => {
				item.num = ''
			})
			let data = this.LoadInfodataSource.concat(this.aboutLoadselectedRows)
			let obj = {}

			this.LoadInfodataSource = data.reduce((cur, next) => {
				obj[next.productId] ? '' : (obj[next.productId] = true && cur.push(next))
				return cur
			}, [])
			this.aboutLoadselectedRowKeys = []
			this.aboutLoadselectedRows = []
			// let dataset = {
			// 	craftId: this.selectedRows[0].id,
			// 	productIds: this.aboutLoadselectedRowKeys,
			// 	tenantId: this.tenantId,
			// 	updateUserId: this.operUserId,
			// 	updateUserName: this.operUserName,
			// }
			// util.postform('productOrderInfoDetail/add', dataset)
			// 	.then(res => {
			// 		if (res.code == 200) {
			// 			this.$message.success('关联成功！')
			// 			this.closeModal()
			// 		}
			// 	})
			// 	.catch(res => {
			// 		this.$message.error(res.response.data.message)
			// 	})
			this.othervisible = false
		},
		// 确认关联
		aboutLoadBtn() {
			let flag = false
			this.LoadInfodataSource.forEach(item => {
				if (item.num === 0 || item.num === '' || !item.num) {
					flag = true
				}
				item.orderId = this.selectedRows[0].id
				// item.productId = item.id
				item.tenantId = this.tenantId
			})
			if (flag) {
				this.$message.error('请输入关联产品的订单数量！')
				return
			}
			util.postform('productOrderInfoDetail/add', this.LoadInfodataSource)
				.then(res => {
					if (res.code == 200) {
						this.$message.success('关联成功！')
						this.closeModal()
					}
				})
				.catch(res => {
					this.$message.error(res.response.data.message)
				})
		},
		upGo(arr, index) {
			console.log(index)
			if (index != 0) {
				arr[index] = arr.splice(index - 1, 1, arr[index][0])
			} else {
				arr.push(arr.shift())
			}
			console.log(arr)
		},
		downGo(arr, index) {
			if (index != arr.length - 1) {
				arr[index] = arr.splice(index + 1, 1, arr[index][0])
			} else {
				arr.unshift(arr.splice(index, 1)[0])
			}
		},
		ontransferChange(nextTargetKeys) {
			this.targetKeys = nextTargetKeys
		},
		getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
			return {
				getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
				onSelectAll(selected, selectedRows) {
					const treeSelectedKeys = selectedRows.filter(item => !item.disabled).map(({ key }) => key)
					const diffKeys = selected
						? difference(treeSelectedKeys, selectedKeys)
						: difference(selectedKeys, treeSelectedKeys)
					itemSelectAll(diffKeys, selected)
				},
				onSelect({ key }, selected) {
					itemSelect(key, selected)
				},
				selectedRowKeys: selectedKeys,
			}
		},
		// 刷新
		reflush() {
			this.initTree()
		},
		// 查询区域 - 时间范围变动
		onTimeChange(val) {
			this.searchForm.startTime = val.dateString[0]
			this.searchForm.endTime = val.dateString[1]
		},
		// 导出
		exportExcel() {
			this.searchForm.tenantId = this.tenantId
			let urldata = urlDataDeal(this.searchForm)
			let downUrl = util.getBaseURL() + 'product/exportProduct?' + urldata
			window.open(downUrl)
		},
		// 关闭弹窗
		closeModal() {
			this.othervisible = false
			this.visible = false
			this.selectedRowKeys = []
			this.selectedRows = []
			this.LoadInfodataSource = []
			this.addForm = {
				orderId: '',
				product: '',
				planNum: '',
				produceNum: '',
				qualifiedNum: '',
				startTime: '',
				finishTime: '',
				operUserId: '',
				operUserName: '',
			}
			this.selectedRowKeys = []
			this.getoutputManagementOrders()
		},
		// 弹窗确认按钮
		updataWin() {
			if (this.visibleType === 'addoutputManagementOrders') {
				this.addoutputManagementOrdersBtn()
			} else if (this.visibleType === 'editoutputManagementOrders') {
				this.editoutputManagementOrdersBtn()
			} else if (this.visibleType === 'aboutLoad') {
				this.aboutLoadBtn()
			} else if (this.visibleType === 'infooutputManagementOrders') {
				this.closeModal()
			}
		},
		// 获取特种设备列表
		getoutputManagementOrders() {
			this.loading = true
			this.searchForm.tenantId = this.tenantId
			util.postform('productOrderInfo/getPageList', this.searchForm).then(res => {
				let temp = res.data.records
				temp.forEach(item => {
					item.key = item.id
				})
				this.loading = false
				this.dataSource = temp
				this.total = res.data.total
			})
		},
		// 翻页
		pageonChange(page) {
			this.searchForm.pageIndex = page
			this.getoutputManagementOrders()
		},
		aboutLoadonChange(page) {
			this.aboutLoadsearchForm.pageIndex = page
			this.getoutputBasicData()
		},
		// 页数size改变
		showSizeChange(key, size) {
			this.searchForm.pageSize = size
			this.getoutputManagementOrders()
		},
		aboutLoadshowSizeChange(key, size) {
			this.aboutLoadsearchForm.pageSize = size
			this.getoutputBasicData()
		},
		// 新增产量管理
		addoutputManagementOrdersBtn() {
			if (this.addForm.orderNumber === '') {
				this.$message.error('订单号不能为空！')
				return
			}
			if (this.addForm.customerName === '') {
				this.$message.error('客户名称不能为空！')
				return
			}
			if (this.addForm.deliveryDay === '') {
				this.$message.error('交付日期不能为空！')
				return
			}
			this.addForm.tenantId = this.tenantId
			this.addForm.createUserId = this.operUserId
			this.addForm.createUserName = this.operUserName
			this.addForm.updateUserId = this.operUserId
			this.addForm.updateUserName = this.operUserName
			util.postform('productOrderInfo/add', this.addForm)
				.then(res => {
					if (res.code == 200) {
						this.$message.success('新增成功！')
						this.closeModal()
					}
				})
				.catch(res => {
					this.$message.error(res.response.data.message)
				})
		},
		// 修改产量管理
		editoutputManagementOrdersBtn() {
			if (this.addForm.orderNumber === '' || !this.addForm.orderNumber) {
				this.$message.error('订单号不能为空！')
				return
			}
			if (this.addForm.customerName === '' || !this.addForm.customerName) {
				this.$message.error('客户名称不能为空！')
				return
			}
			if (this.addForm.deliveryDay === '' || !this.addForm.deliveryDay) {
				this.$message.error('交付日期不能为空！')
				return
			}
			let dataset = {
				tenantId: this.tenantId,
				updateUserId: this.operUserId,
				updateUserName: this.operUserName,
				orderNumber: this.addForm.orderNumber,
				customerName: this.addForm.customerName,
				contact: this.addForm.contact,
				contactPhone: this.addForm.contactPhone,
				deliveryDay: this.addForm.deliveryDay,
				remark: this.addForm.remark,
				id: this.addForm.id,
			}
			util.postform('productOrderInfo/update', dataset).then(res => {
				if (res.code == 200) {
					this.$message.success('修改成功！')
					this.closeModal()
				} else {
					this.$message.error('修改失败！')
				}
			})
		},
		// 删除
		delBtn() {
			let that = this
			if (this.selectedRowKeys.length === 0) {
				this.$info({
					title: '提示',
					content: '请选择需要删除的记录！',
					onOk() {},
				})
				return
			}
			this.$confirm({
				title: '删除提示！',
				content: '是否删除选中订单信息？',
				onOk() {
					util.postform('productOrderInfo/delete', that.selectedRowKeys)
						.then(res => {
							if (res.code === 200) {
								that.$message.success('删除成功！')
								that.getoutputManagementOrders()
								that.selectedRowKeys = []
								that.selectedRows = []
							}
						})
						.catch(err => {
							that.$message.error(err.response.data.message)
						})
				},
				onCancel() {},
			})
		},
		// 删除单条
		deloneData(val) {
			let that = this
			this.$confirm({
				title: '删除提示！',
				content: '是否删除选中订单信息？',
				onOk() {
					util.postform('productOrderInfo/delete', [val.id])
						.then(res => {
							if (res.code === 200) {
								that.$message.success('删除成功！')
								that.getoutputManagementOrders()
							}
						})
						.catch(err => {
							that.$message.error(err.response.data.message)
						})
				},
				onCancel() {},
			})
		},
		// 发布订单
		showoneData(val) {
			let that = this
			this.$confirm({
				title: '提示！',
				content: '是否确认发布选中订单？',
				onOk() {
					util.postform('productOrderInfo/issue', [val.id])
						.then(res => {
							if (res.code === 200) {
								that.$message.success('发布成功！')
								that.getoutputManagementOrders()
							}
						})
						.catch(err => {
							that.$message.error(err.response.data.message)
						})
				},
				onCancel() {},
			})
		},
		// 输入数量
		onNumInputChange(e, record) {
			record.num = e.target.value.replace(/^(0+)|[^\d]+/g,'')
			this.$forceUpdate()
		},
		// 功能按钮操作
		onbtnGroup(e) {
			let name = e.target.name
			if (name === 'editoutputManagementOrders') {
				if (this.selectedRows.length !== 1) {
					this.$warning({
						title: '请选择一条记录进行操作！',
					})
					return
				}
				let temp = this.selectedRows[0]
				this.addForm = temp
			} else if (name == 'aboutLoad') {
				if (this.selectedRows.length !== 1) {
					this.$warning({
						title: '请选择一条记录进行操作！',
					})
					return
				}
				if (this.selectedRows[0].postStatus === 1) {
					this.$warning({
						title: '已发布的订单无法新增关联产品！',
					})
					return
				}
				let temp = this.selectedRows[0]
				util.get('productOrderInfoDetail/info/' + this.selectedRows[0].id).then(res => {
					this.LoadInfodataSource = res.data
				})
				this.aboutLoadInfo = temp
			} else if (name == 'endWorkOrder') {
				// 结束订单
				let that = this
				if (this.selectedRowKeys.length === 0) {
					this.$info({
						title: '提示',
						content: '请选择需要结束的订单！',
						onOk() {},
					})
					return
				}
				this.$confirm({
					title: '提示！',
					content: '是否确认结束选中订单？',
					onOk() {
						util.postform('productOrderInfo/end', that.selectedRowKeys)
							.then(res => {
								if (res.code === 200) {
									that.$message.success('结束成功！')
									that.getoutputManagementOrders()
									that.selectedRowKeys = []
									that.selectedRows = []
								}
							})
							.catch(err => {
								that.$message.error(err.response.data.message)
							})
					},
					onCancel() {},
				})
			}
			this.showModal(e)
		},
		// 修改弹窗
		editShowModal(val) {
			this.addForm = JSON.parse(JSON.stringify(val))
			this.showModal({ target: { name: 'editoutputManagementOrders' } })
		},
		// 选择执行人/组
		onSelectPeo(val) {
			if (val.obj.length === 0) {
				return
			} else {
				if (val.obj[0].groupId) {
					this.addForm.execType = 1
					this.addForm.nowRecipients = val.obj[0].groupId
					this.addForm.nowRecipientsName = val.obj[0].groupName
				} else {
					this.addForm.execType = 0
					this.addForm.executive = val.obj[0].c_id
					this.addForm.executiveName = val.obj[0].c_name
				}
			}
		},
		// 选择抄送人
		onSelectPeocc(val) {
			if (val.obj.length === 0) {
				return
			} else {
				this.addForm.cc = val.obj[0].c_id
				this.addForm.ccName = val.obj[0].c_name
			}
		},
		// 选择设备
		onSelectPeoEqus(val) {
			if (val.obj.length === 0) {
				return
			} else {
				this.addForm.objectId = val.obj[0].c_id
				this.addForm.objectName = val.obj[0].c_name
				this.addForm.organizationId = val.obj[0].c_organization_id
				this.addForm.organizationName = val.obj[0].depName
			}
		},
		// 上传附件 -- 获取oss地址
		setFileList(val) {
			this.addForm.credentialsFile = val
		},
		setFileListName(val) {
			this.addForm.fileName = val
		},
		showModal(e) {
			let name = e.target.name

			this.visibleType = name
			console.log(this.visibleType)
			switch (name) {
				case 'addoutputManagementOrders':
					this.modalTitle = '新增'
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					this.visible = true
					break
				case 'editoutputManagementOrders':
					this.modalTitle = '修改'
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					this.visible = true
					break
				case 'infooutputManagementOrders':
					this.modalTitle = '查看详情'
					this.visibleWidth = '960px'
					this.typePrimary = ''
					this.jywsDialogBottomName = '关闭'
					this.jywsDialogBottomRoger = ''
					this.visible = true
					break
				case 'aboutLoad':
					this.modalTitle = '关联产品'
					this.visible = true
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					break
				case 'importExecl':
					this.importVisible = true
					break
				default:
					break
			}
		},
		// 查看关联详情
		showInfoModal(val) {
			this.infoOrdersData = val
			util.get('productOrderInfoDetail/info/' + val.id).then(res => {
				console.log(res)
				this.newLoadInfodataSource = res.data
			})
			this.showModal({ target: { name: 'infooutputManagementOrders' } })
		},
		cancelClick() {
			this.importVisible = false
		},
		submitClick(file) {
			const fd = new FormData()
			fd.append('tenantId', this.tenantId)
			fd.append('createUserId', this.operUserId)
			fd.append('createUserName', this.operUserName)
			fd.append('updateUserId', this.operUserId)
			fd.append('updateUserName', this.operUserName)
			fd.append('file', file[0])
			util.postformData('productOrderInfo/importExcel', fd)
				.then(res => {
					if (res.code == 200) {
						this.importDataSource = res.data
						this.$message.success('导入成功')
						this.getoutputManagementOrders()
						this.importVisible = false
					} else {
						this.$message.error(res.data.message)
					}
				})
				.catch(err => {
					this.$message.error(err.response.data.message)
				})
		},
		// 查看详情
		openinfooutputManagementOrders(val) {
			util.postform('inspect/getInspectByPlanIdList/' + val.id).then(res => {
				this.infodataSource = res.data
				this.infoList = val
				this.typePrimary = ''
				this.jywsDialogBottomName = '关闭'
				this.jywsDialogBottomRoger = ''
				this.modalTitle = '详情'
				this.visibleType = 'infooutputManagementOrders'
				this.visible = true
				this.visibleWidth = '960px'
			})

			console.log(val)
		},

		// 下拉框选中
		onHandleChange(item) {
			if (item.duplicate == 'credentialsType') {
				this.searchForm.credentialsType = item.value
			} else if (item.duplicate == 'credentialsState') {
				this.searchForm.credentialsState = item.value
			}
		},
		// 输入框
		onInputChange(item) {
			if (item.duplicate == 'orderNumber') {
				this.searchForm.orderNumber = item.value
			} else if (item.duplicate == 'customerName') {
				this.searchForm.customerName = item.value
			}
		},
	},
}
</script>

<style scoped lang="less">
.outputManagementOrders {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	// display: flex;
	// justify-content: space-between;
	box-sizing: border-box;
	overflow: auto;
}
.info-title {
	display: flex;
	height: 40px;
	align-items: center;
	.info-title-icon {
		width: 4px;
		height: 14px;
		background: #1890ff;
	}
	.info-title-text {
		margin-left: 8px;
		font-size: 16px;
		color: #333333;
	}
}
.outputManagementOrders-title {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border-radius: 4px 4px 0px 0px;
	color: #333333;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.infooutputManagementOrders-title {
	font-size: 24px;
	color: #333333;
	text-align: left;
}
.infooutputManagementOrders-smtitle {
	display: flex;
	color: #999999;
	font-size: 12px;
	text-align: left;
	margin-top: 16px;
}
.infooutputManagementOrders-content {
	margin-top: 24px;
}
.mg-l-5 {
	margin-left: 5px;
}
.mg-l-50 {
	margin-left: 50px;
}
.outputManagementOrders-aboutLoad-transferTitle {
	display: flex;
	.transferTitle-left {
		margin-left: 15px;
		width: calc(46% - 15px);
	}
	.transferTitle-name {
		font-size: 16px;
		color: #333333;
		letter-spacing: 0.32px;
		margin-top: 24px;
		margin-bottom: 16px;
	}
}
.aboutLoadInfo-line {
	width: 100%;
	height: 1px;
	background: #e8e8e8;
}
.newTransfer-modal {
	/deep/ .ant-transfer-list {
		border: none !important;
		padding-top: 0 !important;
	}
	/deep/ .ant-transfer-list-header {
		display: none;
	}
}
.aboutLoadInfo-btn {
	margin-top: 16px;
	display: flex;
	.aboutLoadInfo-btn-text {
		font-size: 14px;
		color: #b2b2b2;
		margin-left: 12px;
		padding-top: 8px;
	}
}
.aboutLoadInfo-table {
	margin-top: 12px;
}
.title-icon {
	width: 4px;
	height: 16px;
	background: #1890ff;
	display: inline-block;
	margin-right: 12px;
}
.col-value {
	width: 200px;
}
.outputManagementOrders-aboutLoad-head {
	padding-bottom: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.outputManagementOrders-content {
	background: #ffffff;
	width: 100%;
	height: 100%;
	display: flex;
}
.flush {
	font-size: 14px;
	color: #1890ff;
}
.content-right-btnGroup {
	height: 56px;
	width: 100%;
	line-height: 56px;
	margin-top: 10px;
}
.content-right-form {
	// width: 100%;
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
}
.form-label {
	font-size: 14px;
	color: #333333;
	width: 70px;
	text-align: right;
	margin-right: 16px;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-left {
	width: 220px;
	height: calc(100% - 30px);
	background: #ffffff;
	border: 1px solid #e8e8e8;
	margin-left: 12px;
	overflow: auto;
	.content-left-title {
		height: 48px;
		width: 100%;
		padding: 0 16px 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #e8e8e8;
	}
	.content-left-btnGroup {
		height: 48px;
		width: 100%;
		border-bottom: 1px solid #e8e8e8;
	}
}
.content-right {
	height: calc(100% - 30px);
	width: calc(100% - 60px);
	margin-left: 27px;
}
.content-right-message {
	margin-top: 20px;
}
</style>

/*
 * @Author: your name
 * @Date: 2021-05-19 10:19:41
 * @LastEditTime: 2022-08-18 14:14:47
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: In User Settings Edit
 * @FilePath: /jingyiweishi/vue-src/src/core/util.js
 */
import axios from 'axios'
import currency from '@/core/currency'
// import { Toast } from 'vant'
// import QS from 'qs'
// import store from '../store/index'
// 3.0请求封装 formData
var newinstance = axios.create()
// 环境的切换
if (process.env.NODE_ENV === 'development') {
	// axios.defaults.baseURL = 'http://192.168.110.57:8085/' // 徐志方
	// axios.defaults.baseURL = 'http://192.168.110.94:8087/' // 测试环境
	axios.defaults.baseURL = 'https://webapi.gofrontfm.com/' // 正式环境
	// 3.0
	newinstance.defaults.baseURL = 'http://192.168.110.160:9990/' //可视化大屏
	// newinstance.defaults.baseURL = 'http://39.108.6.147:9990/' // 测试环境3.0
} else if (process.env.NODE_ENV === 'debug') {
	axios.defaults.baseURL = ''
} else if (process.env.NODE_ENV === 'production') {
	axios.defaults.baseURL = 'https://webapi.gofrontfm.com/' // 正式环境
	// axios.defaults.baseURL = 'http://192.168.110.94:8087/' // 测试环境
	// 3.0
	newinstance.defaults.baseURL = 'http://39.108.6.147:9990/'
	// axios.defaults.baseURL = 'http://39.108.6.147:9990/' // 测试环境3.0
}
// 请求超时时间
axios.defaults.timeout = 1000000

// post请求头 2.0请求封装 formData
axios.defaults.headers.post['Content-Type'] =
	'application/x-www-form-urlencoded; charset=UTF-8'
axios.defaults.headers.get['Content-Type'] = 'multipart/form-data'
// 2.0请求封装 Json
var instance = axios.create({
	headers: { 'content-type': 'application/json' },
})

// 2.0请求封装 formData
var formInstance = axios.create()

// 3.0请求封装 formData
// newinstance.defaults.baseURL = 'http://192.168.110.160:9990/'
// newinstance.defaults.headers.get['Content-Type'] = 'multipart/form-data'

// 请求拦截器
instance.interceptors.request.use(
	(config) => {
		// 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
		// 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
		// const token = currency.getLocalStorageItem('token') || ''
		// token && (config.headers['authorization'] = token)
		return config
	},
	(error) => {
		return Promise.error(error)
	}
)

// 请求拦截器
axios.interceptors.request.use(
	(config) => {
		// 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
		// 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
		// const token = currency.getLocalStorageItem('token') || ''
		// token && (config.headers['authorization'] = token)
		if (config && config.method && config.method === 'get') {
			config.params = config.params || {}
		} else if (config && config.method && config.method === 'post') {
			config.data = config.data || {}
		}

		if (config && config.method && config.method === 'post') {
			config.params = Object.assign(config.data) || {}
			config.data = null
		}
		// config.params['appFlat'] = 'true'
		// config.params['userId'] = currency.getLocalStorageItem('userId') || 'weixin'
		// config.params['userName'] = currency.getLocalStorageItem('userName')
		if (!config.params['tenantId']) {
			config.params['tenantId'] = currency.getLocalStorageItem('tenantId')
		}
		// config.params['sessionId'] = currency.getLocalStorageItem('sessionId') || 'weixin'
		// config.params['equType'] = currency.isAndroidOrIOS()
		return config
	},
	(error) => {
		return Promise.error(error)
	}
)
// 请求拦截器
newinstance.interceptors.request.use(
	(config) => {
		// 每次发送请求之前判断是否存在token，如果存在，则统一在http请求的header都加上token，不用每次请求都手动添加了
		// 即使本地存在token，也有可能token是过期的，所以在响应拦截器中要对返回状态进行判断
		const token = currency.getLocalStorageItem('token') || ''
		token && (config.headers['authorization'] = token)
		if (config && config.method && config.method === 'get') {
			config.params = config.params || {}
		}
		return config
	},
	(error) => {
		return Promise.error(error)
	}
)
// 响应拦截器
axios.interceptors.response.use(
	(response) => {
		return Promise.resolve(response)
		// if (response.data.code === 200) {
		//   return Promise.resolve(response)
		// } else if (response.data.code === 403) {
		//   // alert('响应拦截器报的')
		//   Toast({
		//     message: '登录过期，请重新登录',
		//     position: 'bottom',
		//     duration: 1500
		//   })
		//   cordova.exec(success1, fail1, 'AppPlugin', 'expiredToken', [])
		//   currency.removeLocalStorageItem('token')
		//   currency.removeLocalStorageItem('id')
		//   currency.removeLocalStorageItem('name')
		//   currency.removeLocalStorageItem('photo')
		//   currency.removeLocalStorageItem('tenantId')
		//   // setTimeout(() => {
		//   //   alert('响应拦截器跳转')
		//   //   alert(window.location.href)
		//   //   return window.location.replace(window.location.href + 'Login')
		//   // }, 1500)
		// } else {
		//   return Promise.reject(response)
		// }
	},
	// 服务器状态码不是200的情况
	(error) => {
		console.log('1=' + error)
		return Promise.reject(error.response)
		// if (error.response.status) {
		//   switch (error.response.status) {
		//     // 401: 未登录
		//     // 未登录则跳转登录页面，并携带当前页面的路径
		//     // 在登录成功后返回当前页面，这一步需要在登录页操作。
		//     // case 401:
		//     // router.replace({
		//     //     path: '/login',
		//     //     query: { redirect: router.currentRoute.fullPath }
		//     // });
		//     // break
		//     // 403 token过期
		//     // 登录过期对用户进行提示
		//     // 清除本地token和清空vuex中token对象
		//     // 跳转登录页面
		//     case 403:
		//       // alert('服务器状态码报的')
		//       Toast({
		//         message: '登录过期，请重新登录',
		//         position: 'bottom',
		//         duration: 1000
		//       })
		//       cordova.exec(success1, fail1, 'AppPlugin', 'expiredToken', [])
		//       currency.removeLocalStorageItem('token')
		//       currency.removeLocalStorageItem('id')
		//       currency.removeLocalStorageItem('name')
		//       currency.removeLocalStorageItem('photo')
		//       currency.removeLocalStorageItem('tenantId')
		//       // setTimeout(() => {
		//       //   alert('服务器状态码跳转')
		//       //   alert(window.location.href)
		//       //   return window.location.replace(window.location.href + 'Login')
		//       // }, 1500)
		//       break
		//       // 404请求不存在
		//     case 404:
		//       Toast({
		//         message: '网页请求不存在',
		//         position: 'bottom',
		//         duration: 1500
		//       })
		//       break
		//       // 其他错误，直接抛出错误提示
		//     default:
		//       Toast({
		//         message: error.response.data.message,
		//         duration: 1500,
		//         position: 'bottom'
		//       })
		//   }
		//   return Promise.reject(error.response)
		// }
	}
)
/**
 * get方法，对应get请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function get(url, params) {
	return new Promise((resolve, reject) => {
		axios
			.get(url, { params: params })
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err && err.data)
			})
	})
}
/**
 * post方法，对应post请求
 * @param {String} url [请求的url地址]
 * @param {Object} params [请求时携带的参数]
 */
export function post(url, params = {}) {
	return new Promise((resolve, reject) => {
		// axios.post(url, QS.parse(params))
		axios
			.post(url, params)
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}
export function postform(url, params = {}) {
	return new Promise((resolve, reject) => {
		instance
			.post(url, params)
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}
export function postformData(url, params = {}) {
	return new Promise((resolve, reject) => {
		formInstance
			.post(url, params, {
				headers: {
					'content-type':
						'application/x-www-form-urlencoded; charset=UTF-8',
				},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}
export function postFormData3(url, params) {
	return new Promise((resolve, reject) => {
		newinstance
			.post(url, params, {
				headers: {
					'content-type':
						'application/x-www-form-urlencoded; charset=UTF-8',
				},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}
export function getFormData3(url, params) {
	return new Promise((resolve, reject) => {
		newinstance
			.get(url, { params: params })
			.then((res) => {
				resolve(res.data)
			})
			.catch((err) => {
				reject(err && err.data)
			})
	})
}
export function postJson3(url, params) {
	return new Promise((resolve, reject) => {
		newinstance
			.post(url, params, {
				headers: {
					'content-type': 'application/json',
				},
			})
			.then((response) => {
				resolve(response.data)
			})
			.catch((err) => {
				reject(err)
			})
	})
}

export function success1(result) {
	// 获取到result
	alert('1=' + JSON.stringify(result))
}
export function fail1(error) {
	console.log(error)
	// alert('error1=' + error)
}
export function getBaseURL() {
	return axios.defaults.baseURL
	// alert('error1=' + error)
}

<template>
	<div class="outputBasicTabs">
    <div class="outputBasicTabs-title">
      <a-tabs :default-active-key="activeKeys" @change="callback">
			<a-tab-pane :key="1" tab="产品列表">
			</a-tab-pane>
			<a-tab-pane :key="2" tab="工艺列表" force-render>
			</a-tab-pane>
		</a-tabs>
    </div>
    <div class="outputBasicTabs-content">
				<outputBasicData v-if="activeKeys === 1"></outputBasicData>
				<outputBasicData2 v-if='activeKeys === 2'></outputBasicData2>
    </div>
	</div>
</template>

<script>
import outputBasicData from '@/views/outputBasicData/outputBasicData.vue'
import outputBasicData2 from '@/views/outputBasicData2/outputBasicData2.vue'
export default {
	components: {
		outputBasicData2,
    outputBasicData
	},
	data() {
		return {
      activeKeys: 1
    }
	},
	created() {},
	mounted() {},
	methods: {
		callback(val) {
      this.activeKeys = val
    },
	},
}
</script>

<style scoped>
.outputBasicTabs {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	padding: 20px;
	box-sizing: border-box;
	overflow: auto;
}
.outputBasicTabs-content {
  background-color: #fff;
  height: calc(100% - 80px);
}
.outputBasicTabs-title {
  background-color: #fff;
}
.outputBasicTabs-title >>> .ant-tabs-ink-bar {
  width: 60px !important;
  left:15px !important
}
</style>

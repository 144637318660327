<template>
  <div class="outputBasicData">
    <div class="outputBasicData-content">
      <div class="content-right">
        <!-- 查询区域 -->
        <div class="content-right-form">
          <conditionQuery :headData="headData"
                          :exportState="true"
                          @onTimeChange="onTimeChange"
                          @exportExcel="exportExcel"
                          @onHandleChange="onHandleChange"
                          @onInputChange="onInputChange"
                          @onSelectClick="getoutputBasicData" />
        </div>
        <!-- 功能按钮区域 -->
        <div class="content-right-btnGroup">
          <a-button-group>
            <a-button @click="onbtnGroup"
                      name="addoutputBasicData">新增</a-button>
            <a-button @click="onbtnGroup"
                      name="editoutputBasicData">
              修改
            </a-button>
            <a-button @click="delBtn"> 删除 </a-button>
            <a-button @click="onbtnGroup"
                      name="importExecl">
              批量导入
            </a-button>
            <a-button @click="onbtnGroup"
                      name="aboutLoad">
              关联工艺路线
            </a-button>
          </a-button-group>
        </div>
        <!-- 表格区域 -->
        <div class="content-right-table">
          <a-table :loading="loading"
                   :columns="columns"
                   :row-selection="rowSelection"
                   :data-source="dataSource"
                   :pagination="false"
                   :scroll="{ y: 380 }">
            <span slot="time"
                  slot-scope="time">
              {{
								time
									? $moment(time).format('YYYY-MM-DD HH:mm')
									: '-'
							}}
            </span>
            <span slot="dateTime"
                  slot-scope="dateTime">
              {{
								dateTime
									? $moment(dateTime).format('YYYY-MM-DD')
									: '-'
							}}
            </span>
            <span slot="cycle"
                  slot-scope="text, record">
              {{ record.cycle + record.cycleUnit }}
            </span>
            <span slot="executiveName"
                  slot-scope="text, record">
              {{
								record.execType === 0
									? record.executiveName
									: record.nowRecipientsName
							}}
            </span>
            <span slot="credentialsState"
                  slot-scope="text, record">
              <a-badge v-if="record.credentialsState === 0"
                       status="success"
                       text="正常" />
              <a-badge v-else-if="record.credentialsState === 2"
                       status="error"
                       text="超期" />
              <a-badge v-else-if="record.credentialsState === 1"
                       status="warning"
                       text="待检" />
            </span>
            <span slot="action"
                  slot-scope="scope">
              <a @click="openinfooutputBasicData(scope)"
                 name="infooutputBasicData">查看</a>
            </span>
          </a-table>
          <a-pagination @change="pageonChange"
                        @showSizeChange="showSizeChange"
                        size="small"
                        :total="total"
                        :defaultCurrent="searchForm.pageIndex"
                        :defaultPageSize="searchForm.pageSize"
                        show-size-changer
                        show-quick-jumper
                        :show-total="(total) => `总共 ${total} 条`" />
        </div>
      </div>
    </div>
    <!-- 弹窗区域 -->
    <jywsDialog :visible="visible"
                :title="modalTitle"
                :width="visibleWidth"
                :roger="jywsDialogBottomRoger"
                :bottom-name="jywsDialogBottomName"
                @updataClose="closeModal"
                :typePrimary="typePrimary"
                @updataWin="updataWin">
      <a-spin :spinning="spinning">
        <!-- 新增产量管理 -->
        <div v-if="visibleType === 'addoutputBasicData' || visibleType === 'editoutputBasicData'">
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">产品编号</div>
              <div class="col-value">
                <a-input v-model="addForm.serialNumber"
                         placeholder="请输入产品编号" />
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label must">产品名称</div>
              <div class="col-value">
                <a-input v-model="addForm.name"
                         placeholder="请输入产品名称" />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">型号规格</div>
              <div class="col-value">
                <a-input v-model="addForm.specifications"
                         placeholder="请输入型号规格" />
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">产品类型</div>
              <div class="col-value">
                <a-input v-model="addForm.type"
                         placeholder="请输入产品类型" />
              </div>
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
              <div class="col-label">产品单位</div>
              <div class="col-value">
                <a-input v-model="addForm.unit"
                         placeholder="请输入产品单位" />
              </div>
            </a-col>
            <a-col :span="12"
                   :push="1"
                   class="col-flex">
            </a-col>
          </a-row>
          <a-row :gutter="[32, 24]"
                 justify="center"
                 align="middle">
            <a-col :span="24"
                   :push="1"
                   class="col-flex">
              <div class="col-label">备注</div>
              <div style="width:calc(100% - 240px)">
                <a-textarea v-model="addForm.remark"
                            placeholder="请输入"
                            :auto-size="{ minRows: 3, maxRows: 5 }" />
              </div>
            </a-col>

          </a-row>
        </div>

        <!-- 关联工艺路线 -->
        <div v-else-if="visibleType === 'aboutLoad'">
          <div class="outputBasicData-aboutLoad-head">
            <a-row :gutter="[32, 24]"
                   justify="center"
                   align="middle">
              <a-col :span="8"
                     class="col-flex">
                <div class="col-label">工艺路线名称</div>
                <div>
                  <a-input v-model="aboutLoadsearchForm.name"
                           placeholder="请输入工艺路线名称" />
                </div>
              </a-col>
              <a-col :span="8"
                     class="col-flex">
              </a-col>
              <a-col :span="5"
                     class="col-flex">
              </a-col>
              <a-col :span="3"
                     class="col-flex">
                <a-button class="marginl20"
                          type="primary"
                          icon="search" @click="getoutputBasicData2">查询</a-button>
              </a-col>
            </a-row>
          </div>
          <div class="outputBasicData-aboutLoad-content">
            <a-table :columns="aboutLoadcolumns"
                     :row-selection="aboutLoadrowSelection"
                     :data-source="aboutLoaddataSource"
                     :pagination="false">
            </a-table>
          </div>
          <div class="outputBasicData-aboutLoad-bottom">
            <a-pagination @change="aboutLoadonChange"
                          @showSizeChange="aboutLoadshowSizeChange"
                          size="small"
                          :total="aboutLoadtotal"
                          :defaultCurrent="aboutLoadsearchForm.pageIndex"
                          :defaultPageSize="aboutLoadsearchForm.pageSize"
                          show-size-changer
                          show-quick-jumper
                          :show-total="(aboutLoadtotal) => `总共 ${aboutLoadtotal} 条`" />
          </div>
        </div>
        <!-- 详情界面 -->
        <div v-else-if="visibleType === 'infooutputBasicData'">
          <div class="info-content">
            <a-descriptions>
              <a-descriptions-item label="订单号">
                {{ this.infoList.orderId }}
              </a-descriptions-item>
              <a-descriptions-item label="产品名称"
                                   :span='2'>
                {{ this.infoList.product }}
              </a-descriptions-item>
              <a-descriptions-item label="计划数">
                {{
									this.infoList.planNum
								}}
              </a-descriptions-item>
              <a-descriptions-item label="生产数">
                {{ this.infoList.produceNum }}
              </a-descriptions-item>
              <a-descriptions-item label="合格数">
                {{ this.infoList.qualifiedNum }}
              </a-descriptions-item>
              <a-descriptions-item label="开始时间">
                {{
									$moment(this.infoList.startTime).format(
										'YYYY-MM-DD HH:mm'
									)
								}}
              </a-descriptions-item>
              <a-descriptions-item label="完成时间">
                {{
									$moment(
										this.infoList.finishTime
									).format('YYYY-MM-DD HH:mm')
								}}
              </a-descriptions-item>
            </a-descriptions>
          </div>
        </div>
      </a-spin>
    </jywsDialog>
    <importDataDialog :visible="importVisible"
                      :spinning="spinning"
                      :title="importTitle"
                      :url="importUrl"
                      :current="current"
                      @cancel="cancelClick"
                      @submit="submitClick" />
  </div>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import importDataDialog from '@/components/importDataDialog.vue'
import { urlDataDeal } from '@/core/date'
import moment from 'moment'
import * as util from '@/core/util'

export default {
  components: {
    conditionQuery,
    jywsDialog,
    // uploadOss,
    importDataDialog,
  },
  data() {
    return {
      tenantId: '',
      // 导入弹窗参数
      spinning: false,
      importVisible: false,
      importTitle: '批量导入',
      importUrl:
        'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E4%BA%A7%E5%93%81%E4%BF%A1%E6%81%AF%E5%AF%BC%E5%85%A5.xls',
      importDataSource: [],
      current: 0,
      // 弹窗参数
      visible: false,
      visibleType: '',
      modalTitle: '',
      visibleWidth: '960px',
      jywsDialogBottomRoger: '取消',
      jywsDialogBottomName: '确认',
      typePrimary: 'primary',
      infoList: {},
      // 新增接口传参
      addForm: {
        serialNumber: '',
        name: '',
        specifications: '',
        type: '',
        unit: '',
        remark: '',
      },
      editForm: {},
      infodataSource: [],
      // 表格表头
      columns: [
        {
          title: '产品编号',
          dataIndex: 'serialNumber',
          key: 'serialNumber',
        },
        {
          title: '产品名称',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '型号规格',
          dataIndex: 'specifications',
          key: 'specifications',
        },
        {
          title: '产品类型',
          dataIndex: 'type',
          key: 'type',
        },
        {
          title: '产品单位',
          dataIndex: 'unit',
          key: 'unit',
        },
        {
          title: '工艺路线',
          dataIndex: 'craftName',
          key: 'craftName',
        },
        {
          title: '备注',
          dataIndex: 'remark',
          key: 'remark',
        },
        {
          title: '创建时间',
          dataIndex: 'createTime',
          key: 'createTime',
          scopedSlots: { customRender: 'time' },
        },
        // {
        //   title: '二维码',
        //   scopedSlots: { customRender: 'action' },
        //   key: 'cz',
        // },
      ],
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      headData: [
        {
          key: 1,
          name: '产品信息',
          component: 'a-input',
          value: '',
          duplicate: 'keyword',
          placeholder: '请输入产品编码/名称',
        },
        {
          key: 2,
          name: '产品类型',
          component: 'a-input',
          value: '',
          duplicate: 'type',
          placeholder: '请输入产品类型',
        },
      ],
      // 查询特种设备列表参数
      searchForm: {
        type: '',
        keyword:'',
        pageIndex: 1,
        pageSize: 10,
        tenantId: '',
      },
      total: 10,
      oneTreeVisble: true,
      deviceTreeList: [],
      importName: '下一步',
      operUserId: '',
      operUserName: '',
      loading: false,
      aboutLoadselectedRowKeys: [],
      aboutLoadselectedRows: [],
      aboutLoaddataSource: [],
      aboutLoadsearchForm: {
        name: '',
        keyword:'',
        pageIndex: 1,
        pageSize: 10,
        tenantId: '',
      },
      aboutLoadtotal:10,
      aboutLoadcolumns: [
        {
          title: '工艺路线名称',
          dataIndex: 'name',
          key: 'name',
        },
        {
          title: '工艺步骤',
          dataIndex: 'processSteps',
          key: 'processSteps',
        },
      ],
    }
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
    this.operUserId = str[2].split('&')[0]
    this.operUserName = decodeURIComponent(str[3].split('&')[0])
  },
  computed: {
    rowSelection() {
      return {
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
    aboutLoadrowSelection() {
      return {
        type: 'radio',
        selectedRowKeys: this.aboutLoadselectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.aboutLoadselectedRowKeys = selectedRowKeys
          this.aboutLoadselectedRows = selectedRows
        },
      }
    },
    nextReleaseTime() {
      let timeText = ''
      if (this.addForm.periodTime && this.addForm.executiveDays) {
        timeText = moment(this.addForm.periodTime)
          .subtract(this.addForm.executiveDays, 'days')
          .format('YYYY-MM-DD')
      }
      return timeText
    },
  },
  mounted() {
    this.getoutputBasicData()
  },
  methods: {
    // 展开设备树
    expandTree(item) {
      this.dynamicLoadingTree(item)
    },
    // 刷新
    reflush() {
      this.initTree()
    },
    // 查询区域 - 时间范围变动
    onTimeChange(val) {
      this.searchForm.startTime = val.dateString[0]
      this.searchForm.endTime = val.dateString[1]
    },
    // 选中设备树的回调
    treeSelect(val, data) {
      if (val.length !== 0) {
        this.searchForm.id = data.id
        if (data.type === 'dep') {
          this.searchForm.isDept = true
        } else {
          this.searchForm.isDept = false
        }
      } else {
        this.searchForm.id = ''
        this.searchForm.isDept = false
      }
      this.getoutputBasicData()
    },
    // 导出
    exportExcel() {
      this.searchForm.tenantId = this.tenantId
      let urldata = urlDataDeal(this.searchForm)
      let downUrl = util.getBaseURL() + 'productInfo/exportProductInfo?' + urldata
      window.open(downUrl)
      // let form = {
      //       tenantId: Store.get("tenantId") || '',
      //       state: this.state.state || null,
      //       orgIds: this.state.deps,
      //       executor: this.state.sendUserId,
      //       receiver: this.state.haveUserId,
      //       type: this.state.type || null,
      //       theme: this.state.title,
      //       beginTime: (this.state.startTime && this.state.startTime != '') ? this.state.startTime.format('YYYY-MM-DD') : '', //开始时间
      //       endTime: (this.state.endTime && this.state.endTime != '') ? this.state.endTime.format('YYYY-MM-DD') : '',         //结束时间
      //       pageIndex: this.state.page,
      //       pageSize: this.state.limit
      //   }
        // let downUrl = util.getBaseURL() + 'productInfo/exportProductInfo'
        // exportFile(downUrl, this.searchForm).then(res => {
        // })
    },
    // 关闭弹窗
    closeModal() {
      this.visible = false
      this.addForm = {
        orderId: '',
        product: '',
        planNum: '',
        produceNum: '',
        qualifiedNum: '',
        startTime: '',
        finishTime: '',
        operUserId: '',
        operUserName: '',
      }
      this.searchForm.pageIndex = 1
      this.selectedRowKeys = []
      this.selectedRows = []
      this.getoutputBasicData()
    },
    // 弹窗确认按钮
    updataWin() {
      if (this.visibleType === 'addoutputBasicData') {
        this.addoutputBasicDataBtn()
      } else if (this.visibleType === 'editoutputBasicData') {
        this.editoutputBasicDataBtn()
      } else if (this.visibleType === 'aboutLoad') {
        this.aboutLoadBtn()
      } else if (this.visibleType === 'infooutputBasicData') {
        this.closeModal()
      }
    },
    // 关联工艺路线确认
		aboutLoadBtn() {
			if (this.aboutLoadselectedRowKeys.length === 0) {
				this.$message.error('请勾选需要关联的工艺路线！')
				return
			}
			let dataset = {
				productIds: [this.selectedRows[0].id],
				craftId: this.aboutLoadselectedRowKeys[0],
				tenantId: this.tenantId,
				updateUserId: this.operUserId,
				updateUserName: this.operUserName,
			}
			util.postform('productInfo/craftAssociateProducts', dataset)
				.then(res => {
					if (res.code == 200) {
						this.$message.success('关联成功！')
						this.closeModal()
					}
				})
				.catch(res => {
					this.$message.error(res.response.data.message)
				})
		},
    // 获取产品列表
    getoutputBasicData() {
      this.loading = true
      this.searchForm.tenantId = this.tenantId
      util.postform('productInfo/getPageList', this.searchForm).then((res) => {
        let temp = res.data.records
        temp.forEach((item) => {
          item.key = item.id
        })
        this.loading = false
        this.dataSource = temp
        this.total = res.data.total
      })
    },
    // 获取工艺列表
    getoutputBasicData2() {
			this.spinning = true
			this.aboutLoadsearchForm.tenantId = this.tenantId
			util.postform('productCraftInfo/getPageList', this.aboutLoadsearchForm).then(res => {
				let temp = res.data.records
				temp.forEach(item => {
					item.key = item.id
				})
				this.spinning = false
				this.aboutLoaddataSource = temp
				this.aboutLoadtotal = res.data.total
			})
		},
    // 翻页
    pageonChange(page) {
      this.searchForm.pageIndex = page
      this.getoutputBasicData()
    },
    aboutLoadonChange(page) {
      this.aboutLoadsearchForm.pageIndex = page
      this.getoutputBasicData2()
    },
    // 页数size改变
    showSizeChange(key, size) {
      this.searchForm.pageSize = size
      this.getoutputBasicData()
    },
    aboutLoadshowSizeChange(key, size) {
      this.aboutLoadsearchForm.pageSize = size
      this.getoutputBasicData2()
    },
    // 新增产品数据
    addoutputBasicDataBtn() {
      if (this.addForm.serialNumber === '' || !this.addForm.serialNumber) {
        this.$message.error('产品编号不能为空！')
        return
      }
      if (this.addForm.name === '' || !this.addForm.name) {
        this.$message.error('产品名称不能为空！')
        return
      }
      this.addForm.tenantId = this.tenantId
      this.addForm.createUserId = this.operUserId
      this.addForm.updateUserId = this.operUserId
      this.addForm.createUserName = this.operUserName
      this.addForm.updateUserName = this.operUserName
      util
        .postform('productInfo/add', this.addForm)
        .then((res) => {
          if (res.code == 200) {
            this.$message.success('新增成功！')
            this.closeModal()
          }
        })
        .catch((res) => {
          this.$message.error(res.response.data.message)
        })
    },
    // 修改产量管理
    editoutputBasicDataBtn() {
      if (this.addForm.serialNumber === '' || !this.addForm.serialNumber) {
        this.$message.error('产品编号不能为空！')
        return
      }
      if (this.addForm.name === '' || !this.addForm.name) {
        this.$message.error('产品名称不能为空！')
        return
      }
      let dataset = {
        tenantId: this.tenantId,
        updateUserId: this.operUserId,
        updateUserName: this.operUserName,
        serialNumber: this.addForm.serialNumber,
        name: this.addForm.name,
        specifications: this.addForm.specifications,
        type: this.addForm.type,
        unit: this.addForm.unit,
        remark: this.addForm.remark,
        id: this.addForm.id,
      }
      util.postform('productInfo/update', dataset).then((res) => {
        if (res.code == 200) {
          this.$message.success('修改成功！')
          this.closeModal()
        } else {
          this.$message.error('修改失败！')
        }
      })
    },
    // 变更下发计划
    changePlanBtn() {
      let dataset = {
        beforeDay: this.addForm.executiveDays,
        nextInspectTime: moment(this.addForm.periodTime)
          .subtract(this.addForm.executiveDays, 'days')
          .format('YYYY-MM-DD'),
        periodTime: this.addForm.periodTime,
        id: this.selectedRows[0].id,
      }
      util.postform('inspectPlan/changePlan', dataset).then((res) => {
        if (res.code == 200) {
          this.$message.success('变更成功！')
          this.closeModal()
        }
      })
    },
    // 删除
    delBtn() {
      let that = this
      if (this.selectedRowKeys.length === 0) {
        this.$info({
          title: '提示',
          content: '请选择需要删除的记录！',
          onOk() {},
        })
        return
      }
      this.$confirm({
        title: '删除提示！',
        content: '是否删除选中产品信息？',
        onOk() {
          util
            .postform('productInfo/delete', that.selectedRowKeys)
            .then((res) => {
              if (res.code === 200) {
                that.$message.success('删除成功！')
                that.closeModal()
              }
            })
        },
        onCancel() {},
      })
    },
    // 功能按钮操作
    onbtnGroup(e) {
      let name = e.target.name
      if (name === 'editoutputBasicData') {
        if (this.selectedRows.length !== 1) {
          this.$warning({
            title: '请选择一条记录进行修改操作！',
          })
          return
        }
        let temp = this.selectedRows[0]
        this.addForm = temp
      } else if (name == 'aboutLoad') {
        if (this.selectedRows.length !== 1) {
          this.$warning({
            title: '请选择一条记录进行关联操作！',
          })
          return
        }
      }
      this.showModal(e)
    },
    // 选择执行人/组
    onSelectPeo(val) {
      if (val.obj.length === 0) {
        return
      } else {
        if (val.obj[0].groupId) {
          this.addForm.execType = 1
          this.addForm.nowRecipients = val.obj[0].groupId
          this.addForm.nowRecipientsName = val.obj[0].groupName
        } else {
          this.addForm.execType = 0
          this.addForm.executive = val.obj[0].c_id
          this.addForm.executiveName = val.obj[0].c_name
        }
      }
    },
    // 选择抄送人
    onSelectPeocc(val) {
      if (val.obj.length === 0) {
        return
      } else {
        this.addForm.cc = val.obj[0].c_id
        this.addForm.ccName = val.obj[0].c_name
      }
    },
    // 选择设备
    onSelectPeoEqus(val) {
      if (val.obj.length === 0) {
        return
      } else {
        this.addForm.objectId = val.obj[0].c_id
        this.addForm.objectName = val.obj[0].c_name
        this.addForm.organizationId = val.obj[0].c_organization_id
        this.addForm.organizationName = val.obj[0].depName
      }
    },
    // 上传附件 -- 获取oss地址
    setFileList(val) {
      this.addForm.credentialsFile = val
    },
    setFileListName(val) {
      this.addForm.fileName = val
    },
    showModal(e) {
      let name = e.target.name

      this.visibleType = name
      console.log(this.visibleType)
      switch (name) {
        case 'addoutputBasicData':
          this.modalTitle = '新增'
          this.visibleWidth = '960px'
          this.jywsDialogBottomRoger = '取消'
          this.typePrimary = 'primary'
          this.jywsDialogBottomName = '确认'
          this.visible = true
          break
        case 'editoutputBasicData':
          this.modalTitle = '修改'
          this.visibleWidth = '960px'
          this.jywsDialogBottomRoger = '取消'
          this.typePrimary = 'primary'
          this.jywsDialogBottomName = '确认'
          this.visible = true
          break
        case 'infooutputBasicData':
          this.modalTitle = '详情'
          this.visibleWidth = '960px'
          this.typePrimary = ''
          this.jywsDialogBottomName = '关闭'
          this.jywsDialogBottomRoger = ''
          this.visible = true
          break
        case 'aboutLoad':
          this.modalTitle = '关联工艺路线'
          this.visible = true
          this.visibleWidth = '960px'
          this.jywsDialogBottomRoger = '取消'
          this.typePrimary = 'primary'
          this.jywsDialogBottomName = '确认'
          break
        case 'importExecl':
          this.importVisible = true
          break
        default:
          break
      }
    },
    cancelClick() {
      this.importVisible = false
    },
    submitClick(file) {
      this.spinning = true
      const fd = new FormData()
      fd.append('tenantId', this.tenantId)
      fd.append('createUserId', this.operUserId)
      fd.append('createUserName', this.operUserName)
      fd.append('updateUserId', this.operUserId)
      fd.append('updateUserName	', this.operUserName)
      fd.append('file', file[0])
      util
        .postformData('productInfo/importExcel', fd)
        .then((res) => {
          if (res.code == 200) {
            this.importDataSource = res.data
            this.$message.success('导入成功')
            this.importVisible = false
            this.getoutputBasicData()
          } else {
            this.$message.error(res.data.message)
          }
          this.$nextTick(() => {
            this.spinning = false
          })
        })
        .catch((err) => {
          this.$message.error(err.response.data.message)
          this.$nextTick(() => {
            this.spinning = false
          })
        })
    },
    // 查看详情
    openinfooutputBasicData(val) {
      util.postform('inspect/getInspectByPlanIdList/' + val.id).then((res) => {
        this.infodataSource = res.data
        this.infoList = val
        this.typePrimary = ''
        this.jywsDialogBottomName = '关闭'
        this.jywsDialogBottomRoger = ''
        this.modalTitle = '详情'
        this.visibleType = 'infooutputBasicData'
        this.visible = true
        this.visibleWidth = '960px'
      })

      console.log(val)
    },

    // 下拉框选中
    onHandleChange(item) {
      if (item.duplicate == 'credentialsType') {
        this.searchForm.credentialsType = item.value
      } else if (item.duplicate == 'credentialsState') {
        this.searchForm.credentialsState = item.value
      }
    },
    // 输入框
    onInputChange(item) {
      if (item.duplicate == 'keyword') {
        this.searchForm.keyword = item.value
      } else if (item.duplicate == 'type') {
        this.searchForm.type = item.value
      }
    },
  },
}
</script>

<style scoped lang="less">
.outputBasicData {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  // display: flex;
  // justify-content: space-between;
  // padding: 20px;
  box-sizing: border-box;
  overflow: auto;
}
.info-title {
  display: flex;
  height: 40px;
  align-items: center;
  .info-title-icon {
    width: 4px;
    height: 14px;
    background: #1890ff;
  }
  .info-title-text {
    margin-left: 8px;
    font-size: 16px;
    color: #333333;
  }
}
.outputBasicData-title {
  width: 100%;
  height: 56px;
  background: #ffffff;
  border-radius: 4px 4px 0px 0px;
  color: #333333;
  font-size: 16px;
  display: flex;
  align-items: center;
  padding-left: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.infooutputBasicData-title {
  font-size: 24px;
  color: #333333;
  text-align: left;
}
.infooutputBasicData-smtitle {
  display: flex;
  color: #999999;
  font-size: 12px;
  text-align: left;
  margin-top: 16px;
}
.infooutputBasicData-content {
  margin-top: 24px;
}
.mg-l-5 {
  margin-left: 5px;
}
.mg-l-50 {
  margin-left: 50px;
}
.title-icon {
  width: 4px;
  height: 16px;
  background: #1890ff;
  display: inline-block;
  margin-right: 12px;
}
.col-value {
  width: 200px;
}
.outputBasicData-aboutLoad-head {
  padding-bottom: 20px;
  border-bottom: 1px solid #e8e8e8;
}
.outputBasicData-content {
  background: #ffffff;
  width: 100%;
  height: 100%;
  display: flex;
}
.flush {
  font-size: 14px;
  color: #1890ff;
}
.content-right-btnGroup {
  height: 56px;
  width: 100%;
  line-height: 56px;
  margin-top: 10px;
}
.content-right-form {
  // width: 100%;
  background: #ffffff;
  padding: 0px 21px 0px 17px;
  .form-search {
    display: flex;
    align-items: center;
    width: 420px;
  }
}
.form-label {
  font-size: 14px;
  color: #333333;
  width: 70px;
  text-align: right;
  margin-right: 16px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.content-left {
  width: 220px;
  height: calc(100% - 30px);
  background: #ffffff;
  border: 1px solid #e8e8e8;
  margin-left: 12px;
  overflow: auto;
  .content-left-title {
    height: 48px;
    width: 100%;
    padding: 0 16px 0 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 16px;
    border-bottom: 1px solid #e8e8e8;
  }
  .content-left-btnGroup {
    height: 48px;
    width: 100%;
    border-bottom: 1px solid #e8e8e8;
  }
}
.content-right {
  height: calc(100% - 30px);
  width: calc(100% - 60px);
  margin-left: 27px;
}
.content-right-message {
  margin-top: 20px;
}
</style>

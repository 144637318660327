<template>
	<div class="uploadOss">
		<a-upload
			:show-upload-list="showList"
			:accept="accept"
			:data="this.fileData"
			:action="this.fileAction"
			:fileList="this.fileList"
			:before-upload="beforeUpload"
			@change="handleChange"
		>
			<slot></slot>
		</a-upload>
	</div>
</template>

<script>
import * as util from '@/core/util'
import UUID from 'es6-uuid'
export default {
	name: 'uploadOss',
	props: {
		accept: {
			type: String,
			default: '.png,.jpg,.jpeg,.ico,.pdf,.docx,.doc,.xls,.xlxs',
		},
		showList: {
			type: Boolean,
			default: false,
		},
	},
	data() {
		return {
			fileData: {},
			fileAction: '',
			fileList: [],
			fileDir: '',
			fileName: '',
			fileUUID: '',
			fileNameEnd: '',
			fileUpdateState: '',
		}
	},
	created() {},
	mounted() {},
	methods: {
		// 上传之前
		beforeUpload(file) {
			return new Promise((resolve) => {
				util.post('ossService/webUpdateFile', '').then((reg) => {
					let uuid = UUID(32)
					let fileName = file.name //文件名
					let fileNameEnd = fileName.substring(
						fileName.lastIndexOf('.'),
						fileName.length
					) //文件后缀
					let temp = {
						OSSAccessKeyId: reg.accessid,
						callback: reg.callback,
						key: reg.dir + uuid + fileNameEnd,
						policy: reg.policy,
						signature: reg.signature,
						success_action_status: '200', //让服务端返回200,不然，默认会返回204
					}
					this.fileData = temp
					this.fileAction = reg.host
					this.fileDir = reg.dir
					this.fileUUID = uuid
					this.fileName = fileName
					this.fileNameEnd = fileNameEnd
					resolve(file)
				})
			})
		},
		clearFileList() {
			this.fileList = []
			this.fileDir = ''
			this.fileName = ''
			this.fileUUID = ''
			this.fileNameEnd = ''
			this.fileUpdateState = ''
		},
		// 上传文件变动
		handleChange(info) {
			let fileList = [...info.fileList]
			if (fileList.length > 1) {
				//如果上传第二个文件
				fileList = fileList.slice(-1)
			}
			this.fileList = fileList
			const { status } = info.file
			if (status == 'removed') {
				this.fileUpdateState = 'end' //文件上传结束状态
			}
			if (status == 'uploading') {
				this.fileUpdateState = 'ing' //文件上传中状态
			}
			if (status === 'done') {
				// message.success(`${info.file.name} 文件上传成功。`)
				console.log('上传成功')
				this.fileUpdateState = 'end' //文件上传结束状态
				let val =
					'http://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/' +
					this.fileDir +
					this.fileUUID +
					this.fileNameEnd
				this.$emit('setFileList', val)
				this.$emit('setFileListName', this.fileName)
				// this.logoImg =
				//   'http://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/' +
				//   this.fileDir +
				//   this.fileUUID +
				//   this.fileNameEnd
			} else if (status === 'error') {
				this.fileUpdateState = 'end' //文件上传结束状态
			}
		},
		// 上传文件变动
		centerhandleChange(info) {
			let fileList = [...info.fileList]
			if (fileList.length > 1) {
				//如果上传第二个文件
				fileList = fileList.slice(-1)
			}
			this.fileList = fileList
			const { status } = info.file
			if (status == 'removed') {
				this.fileUpdateState = 'end' //文件上传结束状态
			}
			if (status == 'uploading') {
				this.fileUpdateState = 'ing' //文件上传中状态
			}
			if (status === 'done') {
				// message.success(`${info.file.name} 文件上传成功。`)
				this.fileUpdateState = 'end' //文件上传结束状态
				this.centerImg =
					'http://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/' +
					this.fileDir +
					this.fileUUID +
					this.fileNameEnd
			} else if (status === 'error') {
				this.fileUpdateState = 'end' //文件上传结束状态
			}
		},
	},
}
</script>

<style scoped lang="less"></style>

<template>
  <div class="selectPeo">
    <a-input v-model="inputName"
             :placeholder="placeholder"
             @click="showDialog"
             allow-clear
             @change="InputonChange" />
    <jywsDialog :visible="visible"
                :title="modalTitle"
                @updataClose="closeModal"
                @updataWin="updataWin"
                width="960px"
                :roger="jywsDialogBottomRoger"
                :bottom-name="jywsDialogBottomName">
      <div class="selectPeo-modal">
        <a-tabs type="card"
                @change="callback"
                v-model="activeKey">
          <a-tab-pane key="1"
                      tab="人员"> </a-tab-pane>
          <a-tab-pane v-if="showGroup"
                      key="2"
                      tab="维修组">
          </a-tab-pane>
        </a-tabs>
        <div>
          <div class="selectPeo-search">
            <a-row>
              <a-col :span="8"
                     class="col-flex">
                <!-- <div>组织</div>
								<div class="col-value">
									<a-input placeholder="请输入组织" />
								</div> -->
              </a-col>
              <a-col :span="8"
                     class="col-flex"> </a-col>
              <a-col :span="8"
                     class="col-flex">
                <a-input-search :placeholder="placeholder"
                                v-model="searchValue"
                                enter-button="搜索"
                                @search="onSearch" />
              </a-col>
            </a-row>
          </div>
          <div class="selectPeo-table">
            <a-table class="borderTable"
                     :columns="columns"
                     :row-selection="rowSelection"
                     :data-source="dataSource">
              <span slot="time"
                    slot-scope="time">
                {{ $moment(time).format('YYYY-MM-DD HH:mm') }}
              </span>
            </a-table>
          </div>
        </div>
      </div>
    </jywsDialog>
  </div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import * as util from '@/core/util'

export default {
  name: 'selectPeo',
  components: {
    jywsDialog,
  },
  props: {
    radioFlag: {
      type: Boolean,
      default: false,
    },
    showGroup: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      default: '',
    },
    placeholder: {
      type: String,
      default: '请选择',
    },
  },
  data() {
    return {
      tenantId: '',
      visible: false,
      modalTitle: '选择人员',
      jywsDialogBottomRoger: '取消',
      jywsDialogBottomName: '确认',
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      activeKey: '1',
      searchValue: '',
      // placeholder: '请输入人员信息',
      // 表格表头
      columns: [
        {
          title: '名字',
          dataIndex: 'c_name',
          key: 'c_name',
          width: 200,
        },
        {
          title: '所属组织',
          dataIndex: 'c_organization_name',
          key: 'c_organization_name',
          width: 200,
        },
        {
          title: '用户名',
          dataIndex: 'c_user_name',
          key: 'c_user_name',
          width: 200,
        },
        {
          title: '电话',
          dataIndex: 'c_telephone',
          key: 'c_telephone',
          width: 200,
        },
      ],
    }
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
  },
  mounted() {
    // this.getPeoList()
  },
  computed: {
    rowSelection() {
      return {
        type: this.radioFlag ? 'radio' : 'checkbox',
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    onSearch() {
      if (this.activeKey === '1') {
        this.getPeoList()
      } else {
        this.getGroupList()
      }
    },
    // 获取人员列表
    getPeoList() {
      let dataset = {
        page: 1,
        limit: 99999,
        ids: this.tenantId,
        name: this.searchValue,
      }
      util.post('StatisticalAnalysis/queryUserInfo', dataset).then((res) => {
        let data = res.dataset
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.c_id
          })
        }
        this.dataSource = data
      })
    },
    // 获取工作组
    getGroupList() {
      let dataset = {
        page: 1,
        size: 999999,
        tenantId: this.tenantId,
        groupName: this.searchValue,
      }
      util.postform('repairGroupWeb/getPageList', dataset).then((res) => {
        let data = res.data.records
        data.forEach((item) => {
          item.key = item.id
					item.c_name = item.groupName
          let names = []
          item.repair.forEach((item) => {
            names.push(item.repairUserName)
          })
          item['userName'] = names.toString()
        })
        this.dataSource = data
      })
    },
    showDialog() {
      this.visible = true
    },
    closeModal() {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.visible = false
    },
    clearName() {
      this.inputName = ''
    },
    // 清空选中
    InputonChange(val) {
      if (val) {
        this.selectedRowKeys = []
        this.selectedRows = []
        this.$emit('onSelect', {
          key: this.selectedRowKeys,
          obj: this.selectedRows,
        })
      }
    },
    updataWin() {
      if (this.selectedRows.length !== 0) {
        this.inputName = this.selectedRows[0].c_name
        this.$emit('onSelect', {
          key: this.selectedRowKeys,
          obj: this.selectedRows,
        })
      }
      this.closeModal()
    },
    callback() {
      this.searchValue = ''
      if (this.activeKey === '1') {
        this.placeholder = '请输入人员信息'
        this.columns = [
          {
            title: '名字',
            dataIndex: 'c_name',
            key: 'c_name',
            width: 200,
          },
          {
            title: '所属组织',
            dataIndex: 'c_organization_name',
            key: 'c_organization_name',
            width: 200,
          },
          {
            title: '用户名',
            dataIndex: 'c_user_name',
            key: 'c_user_name',
            width: 200,
          },
          {
            title: '电话',
            dataIndex: 'c_telephone',
            key: 'c_telephone',
            width: 200,
          },
        ]
        this.getPeoList()
      } else if (this.activeKey === '2') {
        this.placeholder = '请输入工作组信息'
        this.columns = [
          {
            title: '工作组编号',
            dataIndex: 'groupId',
            key: 'groupId',
            width: 150,
          },
          {
            title: '工作组名称',
            dataIndex: 'groupName',
            key: 'groupName',
            width: 150,
          },
          {
            title: '工作组内成员',
            dataIndex: 'userName',
            key: 'userName',
            width: 300,
          },
          {
            title: '工作组描述',
            dataIndex: 'remark',
            key: 'remark',
            width: 150,
          },
        ]
        this.getGroupList()
      }
    },
  },
}
</script>

<style  lang="less">
.selectPeo-search {
  width: 100%;
  text-align: right;
}
.selectPeo-table {
  margin-top: 20px;
}
.ant-pagination {
  margin-top: 10px !important;
}
.ant-modal-footer {
  margin-top: 30px !important;
}
</style>

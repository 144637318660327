<template>
	<div class="outputManagementPSD">
		<div class="outputManagementPSD-content">
			<div class="content-right">
				<!-- 查询区域 -->
				<div class="content-right-form">
					<conditionQuery
						:headData="headData"
						@onTimeChange="onTimeChange"
						@exportExcel="exportExcel"
						@onHandleChange="onHandleChange"
						@onInputChange="onInputChange"
						@radioOnChange="radioOnChange"
						@onSelectClick="getoutputManagementPSD"
					/>
				</div>
				<!-- 功能按钮区域 -->
				<div class="content-right-btnGroup">
					<a-button-group>
						<a-button @click="onbtnGroup" name="addoutputManagementPSD">制定排产工单</a-button>
					</a-button-group>
				</div>
				<!-- 表格区域 -->
				<div class="content-right-table">
					<a-table
						:loading="loading"
						:columns="columns"
						:row-selection="rowSelection"
						:data-source="dataSource"
						:pagination="false"
						:scroll="{ y: 380 }"
					>
						<span slot="time" slot-scope="time">
							{{ time ? $moment(time).format('YYYY-MM-DD HH:mm') : '-' }}
						</span>
						<span slot="dateTime" slot-scope="dateTime">
							{{ dateTime ? $moment(dateTime).format('YYYY-MM-DD') : '-' }}
						</span>
						<span slot="state" slot-scope="text, record">
							<a-badge v-if="record.state === 1" status="success" text="已完成" />
							<a-badge v-else-if="record.state === 0" status="warning" text="待排产" />
							<a-badge v-else-if="record.state === 2" status="processing" text="排产中" />
						</span>
						<span slot="action" slot-scope="text, record">
							<a @click="showInfoModal(record)" name="infooutputManagementPSD">查看</a>
						</span>
					</a-table>
					<a-pagination
						@change="pageonChange"
						@showSizeChange="showSizeChange"
						size="small"
						:total="total"
						:defaultCurrent="searchForm.pageIndex"
						:defaultPageSize="searchForm.pageSize"
						show-size-changer
						show-quick-jumper
						:show-total="total => `总共 ${total} 条`"
					/>
				</div>
			</div>
		</div>
		<!-- 弹窗区域 -->
		<jywsDialog
			:visible="visible"
			:title="modalTitle"
			:width="visibleWidth"
			:roger="jywsDialogBottomRoger"
			:bottom-name="jywsDialogBottomName"
			@updataClose="closeModal"
			:typePrimary="typePrimary"
			@updataWin="updataWin"
		>
			<a-spin :spinning="spinning">
				<!-- 新增订单 -->
				<div
					class="newTransfer-modal"
					v-if="visibleType === 'addoutputManagementPSD' || visibleType === 'editoutputManagementPSD'"
				>
					<div class="addPsd-head">
						<div class="addPsd-head-left">排产数默认与订单需求数一致，可以依据实际情况进行调整</div>
						<div class="addPsd-head-left">
							<a-col :span="12" class="col-flex">
								<div class="col-label">备注</div>
								<div class="col-value">
									<a-input
										style="width: 300px"
										v-model="remark"
										placeholder="请填写备注信息（如需）"
									/>
								</div>
							</a-col>
						</div>
					</div>
					<div class="addPsd-table">
						<a-table
							class="borderTable"
							:columns="addPsdcolumns"
							:data-source="addPsddataSource"
							:pagination="false"
							:scroll="{ y: 380 }"
						>
							<span slot="dateTime" slot-scope="dateTime">
								{{ dateTime ? $moment(dateTime).format('YYYY-MM-DD') : '-' }}
							</span>
							<span slot="productionScheduleNum" slot-scope="text, record">
								<a-input
									:v-model="record.productionScheduleNum"
									type="number"
									@change="onNumInputChange($event, record)"
								></a-input>
							</span>
						</a-table>
					</div>
				</div>
				<!-- 详情界面 -->
				<div v-else-if="visibleType === 'infooutputManagementPSD'">
					<div class="aboutLoadInfo-head">
						<a-descriptions :column="2">
							<a-descriptions-item label="订单号"> {{ infoOrdersData.orderNumber }} </a-descriptions-item>
							<a-descriptions-item label="客户名称">
								{{ infoOrdersData.customerName }}
							</a-descriptions-item>
							<a-descriptions-item label="联系人"> {{ infoOrdersData.contact }} </a-descriptions-item>
							<a-descriptions-item label="联系电话">
								{{ infoOrdersData.contactPhone }}
							</a-descriptions-item>
							<a-descriptions-item label="交付日期">
								{{ $moment(infoOrdersData.deliveryDay).format('YYYY-MM-DD') }}
							</a-descriptions-item>
							<a-descriptions-item label="备注">
								{{ infoOrdersData.remark }}
							</a-descriptions-item>
						</a-descriptions>
					</div>
					<div class="aboutLoadInfo-line"></div>
					<div class="aboutLoadInfo-table">
						<a-table
							class="borderTable"
							:columns="newLoadInfocolumns"
							:data-source="newLoadInfodataSource"
							:pagination="false"
							:scroll="{ y: 380 }"
						></a-table>
					</div>
				</div>
			</a-spin>
		</jywsDialog>
		<!-- 次级弹窗 -->
		<jywsDialog
			:visible="othervisible"
			:title="othermodalTitle"
			:width="othervisibleWidth"
			:roger="otherjywsDialogBottomRoger"
			:bottom-name="otherjywsDialogBottomName"
			@updataClose="othercloseModal"
			:typePrimary="othertypePrimary"
			@updataWin="otherupdataWin"
		>
			<a-spin :spinning="otherspinning">
				<div v-if="othervisibleType === 'aboutLoad'">
					<div class="outputManagementPSD-aboutLoad-head">
						<a-row :gutter="[32, 24]" justify="center" align="middle">
							<a-col :span="8" class="col-flex">
								<div class="col-label">产品编码</div>
								<div>
									<a-input v-model="addForm.qualifiedNum" placeholder="请输入产品编码" />
								</div>
							</a-col>
							<a-col :span="8" class="col-flex">
								<div class="col-label">产品名称</div>
								<div>
									<a-input v-model="addForm.qualifiedNum" placeholder="请输入产品名称" />
								</div>
							</a-col>
							<a-col :span="5" class="col-flex"> </a-col>
							<a-col :span="3" class="col-flex">
								<a-button class="marginl20" type="primary" icon="search">查询</a-button>
							</a-col>
						</a-row>
					</div>
					<div class="outputManagementPSD-aboutLoad-content">
						<a-table
							:columns="aboutLoadcolumns"
							:row-selection="aboutLoadrowSelection"
							:data-source="aboutLoaddataSource"
							:pagination="false"
						>
						</a-table>
					</div>
					<div class="outputManagementPSD-aboutLoad-bottom">
						<a-pagination
							@change="onChange"
							@showSizeChange="showSizeChange"
							size="small"
							:total="total"
							:defaultCurrent="pageIndex"
							:defaultPageSize="pageSize"
							show-size-changer
							show-quick-jumper
							:show-total="total => `总共 ${total} 条`"
						/>
					</div>
				</div>
				<div v-else-if="othervisibleType === 'successInfo'">
					<a-result
						status="success"
						title="排产成功!"
						sub-title="已排产的工单请在“工单”菜单进行查看；如该工单需要立即开始生产，点击下方的“下发工单按钮”即可"
					>
						<template #extra>
							<a-button @click="printvisible = true">打印生产单</a-button>
							<a-button type="primary" @click="issueWorkOrder">下发工单</a-button>
						</template>
					</a-result>
					<div class="successInfo-content">
						<a-descriptions :column="2">
							<a-descriptions-item label="工单号"> {{ successInfoData.id }} </a-descriptions-item>
							<a-descriptions-item label="关联订单数">
								{{ successInfoData.relationOrderNum }}
							</a-descriptions-item>
							<a-descriptions-item label="关联产品数">
								{{ successInfoData.relationProductNum }}
							</a-descriptions-item>
							<a-descriptions-item label="备注"> {{ successInfoData.remark }} </a-descriptions-item>
						</a-descriptions>
					</div>
				</div>
			</a-spin>
		</jywsDialog>
		<jywsDialog
			v-if="printvisible"
			:visible="printvisible"
			:title="printmodalTitle"
			:width="printvisibleWidth"
			:roger="printjywsDialogBottomRoger"
			:bottom-name="printjywsDialogBottomName"
			@updataClose="printcloseModal"
			:typePrimary="printtypePrimary"
			@updataWin="printcloseModal"
		>
			<workerSinglesPrinting :id='successInfoData.id'></workerSinglesPrinting>
		</jywsDialog>
		<importDataDialog
			:visible="importVisible"
			:title="importTitle"
			:url="importUrl"
			:current="current"
			@cancel="cancelClick"
			@submit="submitClick"
		/>
	</div>
</template>

<script>
import conditionQuery from '@/components/conditionQuery.vue'
import jywsDialog from '@/components/jywsDialog.vue'
import importDataDialog from '@/components/importDataDialog.vue'
import difference from 'lodash/difference'
import { urlDataDeal } from '@/core/date'
import workerSinglesPrinting from '@/components/workerSinglesPrinting.vue'

import moment from 'moment'
import * as util from '@/core/util'
const leftTableColumns = [
	{
		dataIndex: 'description',
		title: '工艺名称',
		key: 'description',
	},
]
const rightTableColumns = [
	{
		dataIndex: 'title',
		title: '排序',
		key: 'title',
	},
	{
		dataIndex: 'description',
		title: '工艺步骤',
		key: 'description',
	},
	{
		dataIndex: 'cz',
		scopedSlots: { customRender: 'action' },
		width: 100,
		title: '操作',
		key: 'cz',
	},
]
export default {
	components: {
		conditionQuery,
		jywsDialog,
		workerSinglesPrinting,
		// uploadOss,
		importDataDialog,
	},
	data() {
		return {
			tenantId: '',
			printvisible: false,
			printmodalTitle: '打印',
			printvisibleWidth: '960px',
			printjywsDialogBottomRoger: '',
			printjywsDialogBottomName: '关闭',
			printtypePrimary: '',
			mockData: [
				{ title: '1', description: '下料', key: 0 },
				{ title: '2', description: '出货', key: 1 },
			],
			infoOrdersData: {},
			newLoadInfodataSource: [],
			newLoadInfocolumns: [
				{
					title: '产品编号',
					dataIndex: 'serialNumber',
					key: 'serialNumber',
				},
				{
					title: '产品名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '型号规格',
					dataIndex: 'specifications',
					key: 'specifications',
				},
				{
					title: '产品类型',
					dataIndex: 'type',
					key: 'type',
				},
				{
					title: '订单数量',
					dataIndex: 'num',
					key: 'num',
				},
			],
			addPsddataSource: [],
			addPsdcolumns: [
				{
					title: '订单号',
					dataIndex: 'orderNumber',
					key: 'orderNumber',
				},
				{
					title: '产品编号',
					dataIndex: 'serialNumber',
					key: 'serialNumber',
				},
				{
					title: '产品名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '型号规格',
					dataIndex: 'specifications',
					key: 'specifications',
				},
				{
					title: '产品类型',
					dataIndex: 'type',
					key: 'type',
				},
				{
					title: '交付日期',
					dataIndex: 'deliveryDay',
					key: 'deliveryDay',
					scopedSlots: { customRender: 'dateTime' },
				},
				{
					title: '订单数量',
					dataIndex: 'num',
					key: 'num',
				},
				{
					title: '排产数',
					dataIndex: 'productionScheduleNum',
					key: 'productionScheduleNum',
					scopedSlots: { customRender: 'productionScheduleNum' },
				},
			],
			targetKeys: [],
			leftColumns: leftTableColumns,
			rightColumns: rightTableColumns,
			// 导入弹窗参数
			importVisible: false,
			importTitle: '批量导入',
			importUrl:
				'https://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/saveData/%E4%BA%A7%E9%87%8F%E7%AE%A1%E7%90%86%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BF.xls',
			importDataSource: [],
			current: 0,
			// 弹窗参数
			visible: false,
			visibleType: '',
			modalTitle: '',
			visibleWidth: '960px',
			jywsDialogBottomRoger: '取消',
			jywsDialogBottomName: '确认',
			typePrimary: 'primary',
			spinning: false,
			infoList: {},
			// 新增接口传参
			addForm: {
				orderId: '',
				product: '',
				planNum: '',
				produceNum: '',
				qualifiedNum: '',
				startTime: '',
				finishTime: '',
				operUserId: '',
				operUserName: '',
			},
			editForm: {},
			infodataSource: [],
			// 表格表头
			columns: [
				{
					title: '订单号',
					dataIndex: 'orderNumber',
					key: 'orderNumber',
				},
				{
					title: '产品编号',
					dataIndex: 'serialNumber',
					key: 'serialNumber',
				},
				{
					title: '产品名称',
					dataIndex: 'name',
					key: 'name',
				},
				{
					title: '型号规格',
					dataIndex: 'specifications',
					key: 'specifications',
				},
				{
					title: '产品类型',
					dataIndex: 'type',
					key: 'type',
				},
				{
					title: '订单数量',
					dataIndex: 'num',
					key: 'num',
				},
				{
					title: '已排产数量',
					dataIndex: 'scheduleNum',
					key: 'scheduleNum',
				},
				{
					title: '交付日期',
					dataIndex: 'deliveryDay',
					key: 'deliveryDay',
					scopedSlots: { customRender: 'dateTime' },
				},
				{
					title: '排产状态',
					dataIndex: 'state',
					key: 'state',
					scopedSlots: { customRender: 'state' },
				},
				{
					title: '工单信息',
					dataIndex: 'cz',
					key: 'cz',
					scopedSlots: { customRender: 'action' },
				},
			],
			dataSource: [],
			selectedRowKeys: [],
			selectedRows: [],
			headData: [
				{
					key: 1,
					name: '订单号',
					component: 'a-input',
					value: '',
					duplicate: 'orderNumber',
					placeholder: '请输入订单号',
				},
				{
					key: 2,
					name: '产品信息',
					component: 'a-input',
					value: '',
					duplicate: 'keyword',
					placeholder: '请输入产品编号/名称',
				},
				{
					key: 3,
					name: '排产状态',
					component: 'a-radio',
					value: '',
					options: [
						{ label: '显示全部', value: undefined },
						{ label: '待排产', value: 0 },
						{ label: '已完成', value: 1 },
						{ label: '排产中', value: 2 },
					],
					duplicate: 'state',
				},
			],
			// 查询特种设备列表参数
			searchForm: {
				orderNumber: undefined,
				keyword: undefined,
				state: undefined,
				pageIndex: 1,
				pageSize: 10,
				tenantId: '',
			},
			remark: '',
			total: 10,
			oneTreeVisble: true,
			deviceTreeList: [],
			importName: '下一步',
			operUserId: '',
			operUserName: '',
			loading: false,
			aboutLoadselectedRowKeys: [],
			aboutLoadselectedRows: [],
			aboutLoaddataSource: [],
			aboutLoadcolumns: [
				{
					title: '产品编号',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '产品名称',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '型号规格',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '产品类型',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '产品单位',
					dataIndex: 'orderId',
					key: 'orderId',
				},
				{
					title: '备注',
					dataIndex: 'orderId',
					key: 'orderId',
				},
			],
			othervisible: false,
			othermodalTitle: '新增工艺列表',
			othervisibleWidth: '540px',
			otherjywsDialogBottomRoger: '取消',
			othertypePrimary: 'primary',
			otherjywsDialogBottomName: '确认',
			otherspinning: false,
			othervisibleType: '',
			WorkmanshipName: '',
			successInfoData: {},
		}
	},
	created() {
		var str = this.$route.fullPath.split('=')
		this.tenantId = str[1].split('&')[0]
		this.operUserId = str[2].split('&')[0]
		this.operUserName = decodeURIComponent(str[3].split('&')[0])
	},
	computed: {
		rowSelection() {
			return {
				selectedRowKeys: this.selectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.selectedRowKeys = selectedRowKeys
					this.selectedRows = selectedRows
				},
			}
		},
		aboutLoadrowSelection() {
			return {
				type: 'radio',
				selectedRowKeys: this.aboutLoadselectedRowKeys,
				onChange: (selectedRowKeys, selectedRows) => {
					this.aboutLoadselectedRowKeys = selectedRowKeys
					this.aboutLoadselectedRows = selectedRows
				},
			}
		},
		nextReleaseTime() {
			let timeText = ''
			if (this.addForm.periodTime && this.addForm.executiveDays) {
				timeText = moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD')
			}
			return timeText
		},
	},
	mounted() {
		this.getoutputManagementPSD()
	},
	methods: {
		// 新增工艺列表
		addWorkmanship() {
			this.othermodalTitle = '新增工艺列表'
			this.othervisibleWidth = '960px'
			this.otherjywsDialogBottomRoger = '取消'
			this.othertypePrimary = 'primary'
			this.otherjywsDialogBottomName = '确认'
			this.othervisibleType = 'aboutLoad'
			this.othervisible = true
		},
		printcloseModal() {
			this.printvisible = false
		},
		// 打开成功结果
		openSuccessInfo() {
			this.othermodalTitle = '制定排产工单'
			this.othervisibleWidth = '960px'
			this.otherjywsDialogBottomRoger = ''
			this.othertypePrimary = ''
			this.otherjywsDialogBottomName = '关闭'
			this.othervisibleType = 'successInfo'
			this.othervisible = true
		},
		// 下发工单
		issueWorkOrder(){
			let that = this
			this.$confirm({
				title: '提示！',
				content: '是否确认下发选中工单？',
				onOk() {
					util.postform('/productWorkOrder/issue', [that.successInfoData.id])
						.then(res => {
							if (res.code === 200) {
								that.$message.success('下发成功！')
							}
						})
						.catch(res => {
							that.$message.error(res.response.data.message)
						})
				},
				onCancel() {},
			})
		},
		othercloseModal() {
			this.othervisible = false
		},
		otherupdataWin() {
			this.othervisible = false
		},
		upGo(arr, index) {
			console.log(index)
			if (index != 0) {
				arr[index] = arr.splice(index - 1, 1, arr[index][0])
			} else {
				arr.push(arr.shift())
			}
			console.log(arr)
		},
		downGo(arr, index) {
			if (index != arr.length - 1) {
				arr[index] = arr.splice(index + 1, 1, arr[index][0])
			} else {
				arr.unshift(arr.splice(index, 1)[0])
			}
		},
		ontransferChange(nextTargetKeys) {
			this.targetKeys = nextTargetKeys
		},
		getRowSelection({ disabled, selectedKeys, itemSelectAll, itemSelect }) {
			return {
				getCheckboxProps: item => ({ props: { disabled: disabled || item.disabled } }),
				onSelectAll(selected, selectedRows) {
					const treeSelectedKeys = selectedRows.filter(item => !item.disabled).map(({ key }) => key)
					const diffKeys = selected
						? difference(treeSelectedKeys, selectedKeys)
						: difference(selectedKeys, treeSelectedKeys)
					itemSelectAll(diffKeys, selected)
				},
				onSelect({ key }, selected) {
					itemSelect(key, selected)
				},
				selectedRowKeys: selectedKeys,
			}
		},
		// 刷新
		reflush() {
			this.initTree()
		},
		// 查询区域 - 时间范围变动
		onTimeChange(val) {
			this.searchForm.startTime = val.dateString[0]
			this.searchForm.endTime = val.dateString[1]
		},
		// 导出
		exportExcel() {
			this.searchForm.tenantId = this.tenantId
			let urldata = urlDataDeal(this.searchForm)
			let downUrl = util.getBaseURL() + 'product/exportProduct?' + urldata
			window.open(downUrl)
		},
		// 关闭弹窗
		closeModal() {
			this.visible = false
			this.addForm = {
				orderId: '',
				product: '',
				planNum: '',
				produceNum: '',
				qualifiedNum: '',
				startTime: '',
				finishTime: '',
				operUserId: '',
				operUserName: '',
			}
			this.remark = ''
			this.selectedRowKeys = []
			this.addPsddataSource = []
			this.getoutputManagementPSD()
		},
		// 弹窗确认按钮
		updataWin() {
			if (this.visibleType === 'addoutputManagementPSD') {
				this.addoutputManagementPSDBtn()
			} else if (this.visibleType === 'editoutputManagementPSD') {
				this.editoutputManagementPSDBtn()
			} else if (this.visibleType === 'changePlan') {
				this.changePlanBtn()
			} else if (this.visibleType === 'infooutputManagementPSD') {
				this.closeModal()
			}
		},
		// 查看关联详情
		showInfoModal(val) {
			this.infoOrdersData = val
			util.get('productOrderInfoDetail/info/' + val.orderId).then(res => {
				console.log(res)
				this.newLoadInfodataSource = res.data
			})
			this.showModal({ target: { name: 'infooutputManagementPSD' } })
		},
		// 获取特种设备列表
		getoutputManagementPSD() {
			this.loading = true
			this.searchForm.tenantId = this.tenantId
			util.postform('productOrderInfoDetail/getPageList', this.searchForm).then(res => {
				let temp = res.data.records
				temp.forEach(item => {
					item.key = item.id
				})
				this.loading = false
				this.dataSource = temp
				this.total = res.data.total
			})
		},
		// 翻页
		pageonChange(page) {
			this.searchForm.pageIndex = page
			this.getoutputManagementPSD()
		},
		// 页数size改变
		showSizeChange(key, size) {
			this.searchForm.pageSize = size
			this.getoutputManagementPSD()
		},
		// 新增产量管理
		addoutputManagementPSDBtn() {
			console.log(this.addPsddataSource)
			let children = []
			let flag = false
			this.addPsddataSource.forEach(item => {
				if (item.productionScheduleNum === '') {
					flag = true
				}
				children.push({
					deliveryDay: item.deliveryDay,
					orderId: item.orderId,
					orderDetailId: item.id,
					productId: item.productId,
					productName: item.name,
					productSerialNumber: item.serialNumber,
					productSpecifications: item.specifications,
					productionScheduleNum: item.productionScheduleNum,
				})
			})
			if (flag) {
				this.$message.error('请输入排产数量！')
				return
			}
			let dataset = {
				tenantId: this.tenantId,
				createUserId: this.operUserId,
				createUserName: this.operUserName,
				children: children,
				remark: this.remark,
			}
			util.postform('productWorkOrder/makeWorkOrder', dataset).then(res => {
				if (res.code == 200) {
					this.$message.success('排产成功！')
					this.successInfoData = res.data
					this.openSuccessInfo()
					this.closeModal()
				}
			})
		},
		// 变更下发计划
		changePlanBtn() {
			let dataset = {
				beforeDay: this.addForm.executiveDays,
				nextInspectTime: moment(this.addForm.periodTime)
					.subtract(this.addForm.executiveDays, 'days')
					.format('YYYY-MM-DD'),
				periodTime: this.addForm.periodTime,
				id: this.selectedRows[0].id,
			}
			util.postform('inspectPlan/changePlan', dataset).then(res => {
				if (res.code == 200) {
					this.$message.success('变更成功！')
					this.closeModal()
				}
			})
		},
		// 删除
		delBtn() {
			let that = this
			if (this.selectedRowKeys.length === 0) {
				this.$info({
					title: '提示',
					content: '请选择需要删除的记录！',
					onOk() {},
				})
				return
			}
			this.$confirm({
				title: '删除提示！',
				content: '是否删除选中产品信息？',
				onOk() {
					util.postform('product/removeBetch', that.selectedRowKeys).then(res => {
						if (res.code === 200) {
							that.$message.success('删除成功！')
							that.getoutputManagementPSD()
						}
					})
				},
				onCancel() {},
			})
		},
		// 功能按钮操作
		onbtnGroup(e) {
			let name = e.target.name
			if (name === 'editoutputManagementPSD') {
				if (this.selectedRows.length !== 1) {
					this.$warning({
						title: '请选择一条记录进行修改操作！',
					})
					return
				}
				let temp = this.selectedRows[0]
				this.addForm = temp
			} else if (name == 'addoutputManagementPSD') {
				if (this.selectedRows.length === 0) {
					this.$warning({
						title: '请先勾选订单进行排产操作！',
					})
					return
				}
				let flag = false
				this.selectedRows.forEach(item => {
					if (item.state === 1) {
						flag = true
					}
				})
				if (flag) {
					this.$warning({
						title: '已完成的订单无法继续排产！',
					})
					return
				}
				this.selectedRows.forEach(item => {
					item.productionScheduleNum = ''
				})
				this.addPsddataSource = this.selectedRows
			}
			this.showModal(e)
		},
		// 选择执行人/组
		onSelectPeo(val) {
			if (val.obj.length === 0) {
				return
			} else {
				if (val.obj[0].groupId) {
					this.addForm.execType = 1
					this.addForm.nowRecipients = val.obj[0].groupId
					this.addForm.nowRecipientsName = val.obj[0].groupName
				} else {
					this.addForm.execType = 0
					this.addForm.executive = val.obj[0].c_id
					this.addForm.executiveName = val.obj[0].c_name
				}
			}
		},
		// 选择抄送人
		onSelectPeocc(val) {
			if (val.obj.length === 0) {
				return
			} else {
				this.addForm.cc = val.obj[0].c_id
				this.addForm.ccName = val.obj[0].c_name
			}
		},
		// 选择设备
		onSelectPeoEqus(val) {
			if (val.obj.length === 0) {
				return
			} else {
				this.addForm.objectId = val.obj[0].c_id
				this.addForm.objectName = val.obj[0].c_name
				this.addForm.organizationId = val.obj[0].c_organization_id
				this.addForm.organizationName = val.obj[0].depName
			}
		},
		// 上传附件 -- 获取oss地址
		setFileList(val) {
			this.addForm.credentialsFile = val
		},
		setFileListName(val) {
			this.addForm.fileName = val
		},
		showModal(e) {
			let name = e.target.name

			this.visibleType = name
			console.log(this.visibleType)
			switch (name) {
				case 'addoutputManagementPSD':
					this.modalTitle = '新增'
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					this.visible = true
					break
				case 'editoutputManagementPSD':
					this.modalTitle = '修改'
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					this.visible = true
					break
				case 'infooutputManagementPSD':
					this.modalTitle = '详情'
					this.visibleWidth = '960px'
					this.typePrimary = ''
					this.jywsDialogBottomName = '关闭'
					this.jywsDialogBottomRoger = ''
					this.visible = true
					break
				case 'aboutLoad':
					this.modalTitle = '关联产品'
					this.visible = true
					this.visibleWidth = '960px'
					this.jywsDialogBottomRoger = '取消'
					this.typePrimary = 'primary'
					this.jywsDialogBottomName = '确认'
					break
				case 'importExecl':
					this.importVisible = true
					break
				default:
					break
			}
		},
		cancelClick() {
			this.importVisible = false
		},
		submitClick(file) {
			const fd = new FormData()
			fd.append('tenantId', this.tenantId)
			fd.append('operUserName', this.operUserId)
			fd.append('file', file[0])
			util.postformData('product/importExcel', fd)
				.then(res => {
					if (res.code == 200) {
						this.importDataSource = res.data
						this.$message.success('导入成功')
						this.importVisible = false
					} else {
						this.$message.error(res.data.message)
					}
				})
				.catch(err => {
					this.$message.error(err.response.data.message)
				})
		},
		// 查看详情
		openinfooutputManagementPSD(val) {
			util.postform('inspect/getInspectByPlanIdList/' + val.id).then(res => {
				this.infodataSource = res.data
				this.infoList = val
				this.typePrimary = ''
				this.jywsDialogBottomName = '关闭'
				this.jywsDialogBottomRoger = ''
				this.modalTitle = '详情'
				this.visibleType = 'infooutputManagementPSD'
				this.visible = true
				this.visibleWidth = '960px'
			})

			console.log(val)
		},
		// 输入数量
		onNumInputChange(e, record) {
			record.productionScheduleNum = e.target.value.replace(/^(0+)|[^\d]+/g,'')
		},
		// 下拉框选中
		onHandleChange(item) {
			if (item.duplicate == 'credentialsType') {
				this.searchForm.credentialsType = item.value
			} else if (item.duplicate == 'credentialsState') {
				this.searchForm.credentialsState = item.value
			}
		},
		radioOnChange(item) {
			console.log(item)
			if (item.duplicate == 'state') {
				this.searchForm.state = item.value
			}
		},
		// 输入框
		onInputChange(item) {
			if (item.duplicate == 'orderNumber') {
				this.searchForm.orderNumber = item.value
			} else if (item.duplicate == 'keyword') {
				this.searchForm.keyword = item.value
			}
		},
	},
}
</script>

<style scoped lang="less">
.outputManagementPSD {
	background-color: #f0f2f5;
	width: 100%;
	height: 100%;
	// display: flex;
	// justify-content: space-between;
	box-sizing: border-box;
	overflow: auto;
}
.info-title {
	display: flex;
	height: 40px;
	align-items: center;
	.info-title-icon {
		width: 4px;
		height: 14px;
		background: #1890ff;
	}
	.info-title-text {
		margin-left: 8px;
		font-size: 16px;
		color: #333333;
	}
}
.outputManagementPSD-title {
	width: 100%;
	height: 56px;
	background: #ffffff;
	border-radius: 4px 4px 0px 0px;
	color: #333333;
	font-size: 16px;
	display: flex;
	align-items: center;
	padding-left: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.infooutputManagementPSD-title {
	font-size: 24px;
	color: #333333;
	text-align: left;
}
.addPsd-head {
	display: flex;
	align-items: center;
	.addPsd-head-left {
		width: 50%;
		font-size: 14px;
		color: #333333;
	}
}
.addPsd-table {
	margin-top: 10px;
}
.infooutputManagementPSD-smtitle {
	display: flex;
	color: #999999;
	font-size: 12px;
	text-align: left;
	margin-top: 16px;
}
.infooutputManagementPSD-content {
	margin-top: 24px;
}
.mg-l-5 {
	margin-left: 5px;
}
.mg-l-50 {
	margin-left: 50px;
}
.outputManagementPSD-aboutLoad-transferTitle {
	display: flex;
	.transferTitle-left {
		margin-left: 15px;
		width: calc(46% - 15px);
	}
	.transferTitle-name {
		font-size: 16px;
		color: #333333;
		letter-spacing: 0.32px;
		margin-top: 24px;
		margin-bottom: 16px;
	}
}
.aboutLoadInfo-line {
	width: 100%;
	height: 1px;
	background: #e8e8e8;
}
.newTransfer-modal {
	/deep/ .ant-transfer-list {
		border: none !important;
		padding-top: 0 !important;
	}
	/deep/ .ant-transfer-list-header {
		display: none;
	}
}
.aboutLoadInfo-btn {
	margin-top: 16px;
	display: flex;
	.aboutLoadInfo-btn-text {
		font-size: 14px;
		color: #b2b2b2;
		margin-left: 12px;
		padding-top: 8px;
	}
}
.successInfo-content {
	margin: 0 auto;
	width: 640px;
	background: #fafafa;
	/deep/ .ant-descriptions-row > td {
		padding-top: 5px;
		padding-left: 10px;
	}
}
.aboutLoadInfo-table {
	margin-top: 12px;
}
.title-icon {
	width: 4px;
	height: 16px;
	background: #1890ff;
	display: inline-block;
	margin-right: 12px;
}
.col-value {
	width: 200px;
}
.outputManagementPSD-aboutLoad-head {
	padding-bottom: 20px;
	border-bottom: 1px solid #e8e8e8;
}
.outputManagementPSD-content {
	background: #ffffff;
	width: 100%;
	height: 100%;
	display: flex;
}
.flush {
	font-size: 14px;
	color: #1890ff;
}
.content-right-btnGroup {
	height: 56px;
	width: 100%;
	line-height: 56px;
	margin-top: 10px;
}
.content-right-form {
	// width: 100%;
	background: #ffffff;
	padding: 0px 21px 0px 17px;
	.form-search {
		display: flex;
		align-items: center;
		width: 420px;
	}
}
.form-label {
	font-size: 14px;
	color: #333333;
	width: 70px;
	text-align: right;
	margin-right: 16px;
}
.flex {
	display: flex;
	justify-content: center;
	align-items: center;
}
.content-left {
	width: 220px;
	height: calc(100% - 30px);
	background: #ffffff;
	border: 1px solid #e8e8e8;
	margin-left: 12px;
	overflow: auto;
	.content-left-title {
		height: 48px;
		width: 100%;
		padding: 0 16px 0 16px;
		display: flex;
		align-items: center;
		justify-content: space-between;
		color: #333333;
		font-size: 16px;
		border-bottom: 1px solid #e8e8e8;
	}
	.content-left-btnGroup {
		height: 48px;
		width: 100%;
		border-bottom: 1px solid #e8e8e8;
	}
}
.content-right {
	height: calc(100% - 30px);
	width: calc(100% - 60px);
	margin-left: 27px;
}
.content-right-message {
	margin-top: 20px;
}
</style>

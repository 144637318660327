<template>
  <div class="leftTree">
    <!-- 搜索框 -->
    <div class="leftTree-search">
      <!-- <a-input ref="userNameInput"
               v-model="typeName"
               @change="onChange"
               placeholder="请输入类型名称">
        <a-icon slot="prefix"
                type="search" />
      </a-input> -->
    </div>
    <!-- 树区域 -->
    <div class="leftTree-tree">
      <a-tree :show-line="true"
              :show-icon='true'
              :replaceFields='replaceFields'
              :tree-data="treeData"
              @select="onSelect">
        <a-icon slot="home"
                type="home" />
        <a-icon slot="hdd"
                type="hdd" />
      </a-tree>
    </div>
  </div>
</template>

<script>
// const dataList = [];
// const generateList = data => {
//   for (let i = 0; i < data.length; i++) {
//     const node = data[i];
//     const key = node.key;
//     dataList.push({ key, title: key });
//     if (node.children) {
//       generateList(node.children);
//     }
//   }
// };
// // generateList(this.treeData);
// const getParentKey = (key, tree) => {
//   let parentKey
//   for (let i = 0; i < tree.length; i++) {
//     const node = tree[i]
//     if (node.children) {
//       if (node.children.some((item) => item.key === key)) {
//         parentKey = node.key
//       } else if (getParentKey(key, node.children)) {
//         parentKey = getParentKey(key, node.children)
//       }
//     }
//   }
//   return parentKey
// }
export default {
  name: 'leftTree',
  props: {
    replaceFields: {
      type: Object,
      default: () => {
        return {
          children: 'children',
          title: 'title',
          key: 'key',
        }
      },
      require: false,
    },
    treeData: {
      type: Array,
      default: () => {
        return []
      },
      require: false,
    },
  },
  data() {
    return {
      typeName: '',
      expandedKeys: [],
      autoExpandParent: true,
    }
  },
  created() {},
  mounted() {},
  methods: {
    onSelect(val, e) {
      let data = {}
      if (e.selected) {
        data = e.node._props.dataRef
      } else {
        data = {}
      }
      this.$emit('treeSelect', val, data)
    },
    getParentKey(key, tree) {
      let parentKey
      let that = this
      for (let i = 0; i < tree.length; i++) {
        const node = tree[i]
        if (node.children) {
          if (node.children.some((item) => item.key === key)) {
            parentKey = node.key
          } else if (that.getParentKey(key, node.children)) {
            parentKey = that.getParentKey(key, node.children)
          }
        }
      }
      return parentKey
    },
    onChange(e) {
      let that = this
      const value = e.target.value
      const expandedKeys = this.treeData
        .map((item) => {
          if (item.title.indexOf(value) > -1) {
            return that.getParentKey(item.key, that.treeData)
          }
          return null
        })
        .filter((item, i, self) => item && self.indexOf(item) === i)
      Object.assign(this, {
        expandedKeys,
        searchValue: value,
        autoExpandParent: true,
      })
    },
  },
}
</script>

<style scoped lang="less">
.leftTree {
  width: 100%;
  // display: flex;
  // flex-direction: column;
  // align-items: center;
}
.leftTree-search {
  width: calc(100% - 30px);
  margin: 0 auto;
  margin-top: 8px;
}
.leftTree-tree {
  text-align: left;
  margin-left: 10px;
}
</style>

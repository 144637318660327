<!--
 * @Author: your name
 * @Date: 2022-04-27 16:45:02
 * @LastEditTime: 2022-07-12 15:13:02
 * @LastEditors: zwb 1172031304@qq.com
 * @Description: 打开koroFileHeader查看配置 进行设置: https://github.com/OBKoro1/koro1FileHeader/wiki/%E9%85%8D%E7%BD%AE
 * @FilePath: /jyws_vue/src/components/importDataDialog.vue
-->
<template>
  <div class="importDataDialog">
    <jyws-dialog :visible="visible" :title="title" :width="width" :roger="roger" :bottom-name="bottomName" @updataClose="updataClose" @updataWin="updataWin">
      <a-spin :spinning="spinning">
      <a-steps :current="current">
        <a-step v-for="item in steps" :key="item.title" :title="item.title" />
      </a-steps>
      <template v-if="current == 0">
        <div class="importDataDialog-come" @click="windowOpen">
          <div class="marginB12"><span class="importDataDialog-come-step">步骤1：</span><span>下载Excel模板</span></div>
          <div><a-button type="primary">下载Excel模板</a-button></div>
        </div>
        <div class="importDataDialog-come">
          <div class="marginB12"><span class="importDataDialog-come-step">步骤2：</span><span>在已经下载的Excel模板中录入数据</span></div>
          <div><a-icon type="form" style="font-size: 48px;color: rgb(143, 143, 143)" /></div>
        </div>
        <div class="importDataDialog-come">
          <div class="marginB12"><span class="importDataDialog-come-step">步骤3：</span><span>导入已经录入数据的Excel</span></div>
          <a-upload-dragger
            name="file"
            :fileList="excelList"
            :remove="onRemove"
            :before-upload="beforeUpload"
          >
            <p class="ant-upload-drag-icon">
              <a-icon type="inbox" />
            </p>
            <p class="ant-upload-text">
              单击或拖动模板文件到该区域
            </p>
            <p class="ant-upload-hint">
              点击"下载Excel模板"下载
            </p>
          </a-upload-dragger>
        </div>
      </template>
      <template v-if="current == 1">
        <div class="importDataDialog-come" @click="windowOpen">
          <a-result
            status="success"
            title="导入完成"
          >
          </a-result>
        </div>
      </template>
      </a-spin>
    </jyws-dialog>
  </div>
</template>
<script>
import jywsDialog from '@/components/jywsDialog.vue'
export default {
  name: 'ImportDataDialog',
  props: {
    title: {
      type: String,
      default: '导入'
    },
    visible: {
      type: Boolean,
      default: true
    },
    width: {
      type: String,
      default: '960px'
    },
    roger: {
      type: String,
      default: '取消'
    },
    bottomName: {
      type: String,
      default: '确定'
    },
    url:{
      type: String,
      default: ''
    },
    current:{
      type: Number,
      default: 0
    },
    spinning: {
      type: Boolean,
      default: false
    },
  },
  components: {
    jywsDialog
  },
  data() {
    return {
      steps: [
        {
          title: '导入模板信息'
        },
        {
          title: '导入完成'
        }
      ],
      excelList: []
    }
  },
  created() {},
  mounted() {},
  methods: {
    windowOpen() {
      window.open(this.url, '_black')
    },
    onRemove(file) {
      this.excelList.some((item, i) => {
        if (item.name == file.name) {
          this.excelList.splice(i, 1)
          return true //当内部return true时跳出整个循环
        }
      })
    },
    deleteXls(excelList, file) {
      const index = excelList.indexOf(file);
      const newFileList = excelList.slice();
      newFileList.splice(index, 1);
      return {
          excelList: newFileList,
      };
    },
    beforeUpload(file) {
      if (this.excelList.length < 1) {
          this.excelList = [file]
          return false;
      } else if (this.excelList.length == 1) {
          this.excelList = [file]
          return false;
      }
    },
    updataClose() {
      this.$emit('cancel')
    },
    updataWin() {
      if (this.spinning) return
      if (this.current == 0) {
        this.$emit('submit', this.excelList)
      } else if (this.current == 1) {
        this.excelList = []
        this.$emit('cancel')
      }
    }
  },
}
</script>

<style scoped lang="less">
.importDataDialog-come {
  padding: 30px 40px;
  background-color: #f5f5f5;
  border: 1px solid #e8e8e8;
  margin-top: 20px;
  text-align: center;
}
.importDataDialog-come-step {
  color: rgba(0, 0, 0, 0.85);
  font-weight: bold;
}
.marginB12 {
  margin-bottom: 10px;
}
</style>

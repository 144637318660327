<template>
	<div class="deviceTree">
		<!-- 搜索框 -->
		<div class="deviceTree-search" v-if="searchType === 'equs'">
			<selectEqus
				:inputName="selectName"
				:radioFlag="true"
				special="1"
				@onSelect="onSelectPeoEqus"
			></selectEqus>
		</div>
    <div class="deviceTree-search" v-else-if="searchType === 'peos'">
			<selectPeo
				:inputName="selectName"
				@onSelect="onSelectPeoEqus"
				:radioFlag="true"
			></selectPeo>
		</div>
    <div class="deviceTree-search" v-else>
			<a-input ref="userNameInput" v-model="typeName" placeholder="请输入名称">
				<a-icon slot="prefix" type="search" />
			</a-input>
		</div>
		<!-- 树区域 -->
		<div class="deviceTree-tree">
			<a-tree
				showLine
				show-icon
				:replaceFields="replaceFields"
				:default-expanded-keys="['0-0-0', '0-0-1', '0-0-2']"
				:tree-data="treeData"
				:load-data="onLoadData"
				@select="onSelect"
			>
				<a-icon slot="smile" type="home" />
				<!-- <a-icon slot="meh" 
                :style="selected ? 'color: rgba(28, 106, 235, 1)' : ''"
                type="hdd" /> -->
				<a-icon slot="meh" type="hdd" />
				<template slot="custom" slot-scope="{ scopedSlots }">
					<a-icon slot="meh" :style="scopedSlots.selected ? 'color: #1890FF;' : ''" type="hdd" />
				</template>
			</a-tree>
		</div>
	</div>
</template>

<script>
import selectEqus from '@/components/selectEqus.vue'
import selectPeo from '@/components/selectPeo.vue'

export default {
	name: 'deviceTree',
	props: {
		replaceFields: {
			type: Object,
			default: () => {
				return {
					children: 'children',
					title: 'title',
					key: 'key',
				}
			},
		},
		treeData: {
			type: Array,
			default: () => {
				return []
			},
		},
		searchType: {
			type: String,
			default: () => {
				return ''
			},
		},
	},
	components: {
		selectEqus,
    selectPeo
	},
	data() {
		return {
			typeName: '',
      selectName:'',
			dataList: [],
			// treeData: [
			//   {
			//     title: '专家课程',
			//     key: '0-0',
			//     slots: {
			//       icon: 'smile',
			//     },
			//     children: [
			//       { title: 'leaf', key: '0-0-0', slots: { icon: 'meh' } },
			//       { title: 'leaf', key: '0-0-1', slots: { icon: 'meh' } },
			//     ],
			//   },
			// ],
		}
	},
	created() {},
	mounted() {},
	methods: {
		onSelect(val, e) {
			let data = e.node._props.dataRef
			this.$emit('treeSelect', val, data)
		},
		onLoadData(treeNode) {
			return new Promise(resolve => {
				if (treeNode.dataRef.children) {
					resolve()
					return
				}
				setTimeout(() => {
					this.$emit('expandTree', treeNode.dataRef)
					resolve()
				}, 1000)
			})
		},
    // 选择
		onSelectPeoEqus(val) {
      this.$emit('searchTreeBtn',val.obj)
		},
	},
}
</script>

<style scoped lang="less">
.deviceTree {
	width: 100%;
	height: 100%;
}
.deviceTree-search {
	width: calc(100% - 30px);
	margin: 0 auto;
	margin-top: 8px;
}
.deviceTree-tree {
	height: calc(100% - 32px);
	text-align: left;
	margin-left: 10px;
	overflow: auto;
}
</style>

<template>
  <div class="diyBoard">
    <div class="diyBoard-left">
      <div class="diyBoard-left-title">
        <div class="title-lable">指标组件</div>
        <div class="title-icon">刷新</div>
      </div>
      <div class="diyBoard-left-line"></div>
      <!-- 指标搜索框 -->
      <div class="searchName-Input">
        <a-input ref="userNameInput"
                 v-model="searchName"
                 placeholder="请输入指标">
          <a-icon slot="prefix"
                  type="search" />
        </a-input>
      </div>
      <div class="diyBoard-left-line"></div>
      <!-- 指标区域 -->
      <div class="diyBoard-left-content">
        <!-- 成本指标 -->
        <div class="left-content-one">
          <div class="left-content-title"
               @click="tobeshow('cb')">
            <div>成本指标</div>
            <div :class="this.isshowcblist ? 'isshow' : 'noshow'"></div>
          </div>
          <draggable v-if="this.isshowcblist"
                     class="left-content-list"
                     @start="start"
                     v-bind="{ sort: false }"
                     @end="end">
            <div class="left-content-list-one"
                 data-src='维修成本@3x'
                 data-echartsId='repairMoneyEchart'
                 data-title="cblist">
              <img src="@/assets/icon_Linechart1@3x.png" />
              <div>维修成本</div>
            </div>
            <div class="left-content-list-one"
                 data-src='能耗成本@3x'
                 data-echartsId='energyEchart'
                 data-title="cblist">
              <img src="@/assets/icon_Linechart2@3x.png" />
              <div>能耗成本</div>
            </div>
            <div class="left-content-list-one"
                 data-src='维修费用率@3x'
                 data-echartsId='repairUseEchart'
                 data-title="cblist">
              <img src="@/assets/icon_Linechart3@3x.png" />
              <div>维修费用率</div>
            </div>
            <div class="left-content-list-one"
                 data-src='单位能耗成本@3x'
                 data-echartsId='unitenergyEchart'
                 data-title="cblist">
              <img src="@/assets/icon_Linechart4@3x.png" />
              <div>单位能耗成本</div>
            </div>
          </draggable>
        </div>
        <!-- 效率指标 -->
        <div class="left-content-one">
          <div class="left-content-title"
               @click="tobeshow('xl')">
            <div>效率指标</div>
            <div :class="this.isshowxllist ? 'isshow' : 'noshow'"></div>
          </div>
          <div v-if="this.isshowxllist">
            <div class="left-content-smalltitle">设备稼动率</div>
            <draggable class="left-content-list"
                       @start="start"
                       v-bind="{ sort: false }"
                       @end="end">
              <div class="left-content-list-one"
                   data-src='TEEP@3x'
                   data-title="sbjd">
                <img src="@/assets/icon_Dashboard1@3x.png" />
                <div>TEEP</div>
              </div>
              <div class="left-content-list-one"
                   data-src='OEE@3x'
                   data-title="sbjd">
                <img src="@/assets/icon_Dashboard2@3x.png" />
                <div>OEE</div>
              </div>
              <!-- <div class="left-content-list-one"
                   data-src='开动率@3x'
                   data-title="sbjd">
                <img src="@/assets/icon_Dashboard3@3x.png" />
                <div>开动率</div>
              </div> -->
            </draggable>
            <div class="left-content-smalltitle">工作负荷率</div>
            <draggable class="left-content-list"
                       @start="start"
                       v-bind="{ sort: false }"
                       @end="end">
              <div class="left-content-list-one"
                   data-src='正在作业人数占比@3x'
                   data-title="gzfh">
                <img src="@/assets/icon_Ring_graph1@3x.png" />
                <div>作业人数占比</div>
              </div>
              <div class="left-content-list-one"
                   data-src='平均有效作业工时@3x'
                   data-echartsId='peoTimesEcharts'
                   data-title="gzfh">
                <img src="@/assets/histogram1@3x.png" />
                <div>平均作业工时</div>
              </div>
            </draggable>
            <div class="left-content-smalltitle">运维工作效率</div>
            <draggable class="left-content-list"
                       @start="start"
                       v-bind="{ sort: false }"
                       @end="end">
              <div class="left-content-list-one"
                   data-src='MTBF_MRT_MTTR@3x'
                   data-title="ywgz">
                <img src="@/assets/index1@3x.png" />
                <div>设备指标</div>
              </div>
              <div class="left-content-list-one"
                   data-src='AM_BM_PM@3x'
                   data-title="ywgz">
                <img src="@/assets/flow_chart1@3x.png" />
                <div>设备运维</div>
              </div>
            </draggable>
          </div>
        </div>
        <!-- 计划指标 -->
        <div class="left-content-one">
          <div class="left-content-title"
               @click="tobeshow('jh')">
            <div>计划指标</div>
            <div :class="this.isshowjhlist ? 'isshow' : 'noshow'"></div>
          </div>
          <draggable v-if="this.isshowjhlist"
                     class="left-content-list"
                     @start="start"
                     v-bind="{ sort: false }"
                     @end="end">
            <div class="left-content-list-one"
                 data-src='计划数@3x'
                 data-title="jh">
              <img src="@/assets/icon_progress_bar1@3x.png" />
              <div>订单计划数</div>
            </div>
            <!-- <div class="left-content-list-one"
                 data-title="jh">
              <img src="@/assets/icon_progress_bar2@3x.png" />
              <div>生产排产数</div>
            </div>
            <div class="left-content-list-one"
                 data-title="jh">
              <img src="@/assets/icon_progress_bar3@3x.png" />
              <div>生产派工数</div>
            </div> -->

          </draggable>
        </div>
        <!-- 质量指标 -->
        <div class="left-content-one">
          <div class="left-content-title"
               @click="tobeshow('zl')">
            <div>质量指标</div>
            <div :class="this.isshowzllist ? 'isshow' : 'noshow'"></div>
          </div>
          <draggable v-if="this.isshowzllist"
                     class="left-content-list"
                     @start="start"
                     v-bind="{ sort: false }"
                     @end="end">
            <div class="left-content-list-one"
                 data-src='良品率排行@3x'
                 data-title="zl">
              <img src="@/assets/ranking_List1@3x.png" />
              <div>良品率排名</div>
            </div>
            <div class="left-content-list-one"
                 data-src='故障引发质量分布@3x'
                 data-title="zl">
              <img src="@/assets/rose_chart@3x.png" />
              <div>质量件数分布</div>
            </div>
          </draggable>
        </div>
        <!-- 交期指标 -->
        <div class="left-content-one">
          <div class="left-content-title"
               @click="tobeshow('jq')">
            <div>交期指标</div>
            <div :class="this.isshowjqlist ? 'isshow' : 'noshow'"></div>
          </div>
          <draggable v-if="this.isshowjqlist"
                     class="left-content-list"
                     @start="start"
                     v-bind="{ sort: false }"
                     @end="end">
            <div class="left-content-list-one"
                 data-src='产品生产周期@3x'
                 data-title="jq">
              <img src="@/assets/histogram1@3x.png" />
              <div>产品生产周期</div>
            </div>
            <div class="left-content-list-one"
                 data-src='A类设备故障排名@3x'
                 data-title="jq">
              <img src="@/assets/ranking2@3x.png" />
              <div>大故障排名</div>
            </div>
            <!-- <div class="left-content-list-one"
                 data-title="jq">
              <img src="@/assets/histogram2@3x.png" />
              <div>单位小时产量</div>
            </div> -->
          </draggable>
        </div>
        <!-- 安全指标 -->
        <div class="left-content-one">
          <div class="left-content-title"
               @click="tobeshow('aq')">
            <div>安全指标</div>
            <div :class="this.isshowaqlist ? 'isshow' : 'noshow'"></div>
          </div>
          <div v-if="this.isshowaqlist">
            <draggable class="left-content-list"
                       @start="start"
                       v-bind="{ sort: false }"
                       @end="end">
              <div class="left-content-list-one"
                   data-src='安全事件等级@3x'
                   data-title="aq">
                <img src="@/assets/index2@3x.png" />
                <div>安全事件等级</div>
              </div>
              <div class="left-content-list-one"
                   data-src='隐患消缺率@3x'
                   data-title="aq">
                <img src="@/assets/histogram1@3x.png" />
                <div>隐患消缺率</div>
              </div>
            </draggable>
            <div class="left-content-smalltitle">及时检验率</div>
            <draggable class="left-content-list"
                       @start="start"
                       v-bind="{ sort: false }"
                       @end="end">
              <div class="left-content-list-one"
                   data-src='特种证书复审@3x'
                   data-title="aq">
                <img src="@/assets/icon_Ring_graph1@3x.png" />
                <div>特种证书复审</div>
              </div>
              <div class="left-content-list-one"
                   data-src='特种设备检验@3x'
                   data-title="aq">
                <img src="@/assets/icon_Ring_graph2@3x.png" />
                <div>特种设备检验</div>
              </div>
            </draggable>
          </div>

        </div>
        <!-- 知识指标 -->
        <div class="left-content-one">
          <div class="left-content-title"
               @click="tobeshow('zs')">
            <div>知识指标</div>
            <div :class="this.isshowzslist ? 'isshow' : 'noshow'"></div>
          </div>
          <draggable v-if="this.isshowzslist"
                     class="left-content-list"
                     @start="start"
                     v-bind="{ sort: false }"
                     @end="end">
            <div class="left-content-list-one"
                 data-src='OPL@3x'
                 data-title="zs">
              <img src="@/assets/icon_Linechart2@3x.png" />
              <div>OPL数</div>
            </div>
            <div class="left-content-list-one"
                 data-src='基准书数@3x'
                 data-title="zs">
              <img src="@/assets/icon_扇形图5@3x.png" />
              <div>基准书数</div>
            </div>
            <div class="left-content-list-one"
                 data-src='知识库@3x'
                 data-title="zs">
              <img src="@/assets/icon_Linechart3@3x.png" />
              <div>知识库数</div>
            </div>
          </draggable>
        </div>
      </div>
    </div>
    <div class="diyBoard-right">
      <!-- 右侧操作区域 -->
      <div class="diyBoard-right-top">
        <a class="right-top-box"
           @click="todel">
          <img src="@/assets/delete01@3x.png" />
          <div class="ml-t-8">删除</div>
        </a>
        <a class="right-top-box"
           @click="settingBoard('0')">
          <img src="@/assets/icon_save@3x.png" />
          <div class="ml-t-8">保存</div>
        </a>
        <a class="right-top-box"
           @click="settingBoard('1')">
          <img src="@/assets/icon_release@3x.png" />
          <div class="ml-t-8">发布</div>
        </a>
      </div>
      <!-- 右侧diy图标区域 -->
      <div class="diyBoard-right-bottom">
        <div class="diyImg-top">
          <a-tree-select v-model="settingJson.deptvalue"
                         style="width: 100%;"
                         size="small"
                         :dropdown-style="{ maxHeight: '400px', overflow: 'auto' }"
                         placeholder="请选择组织"
                         allow-clear
                         @change='depTreeChange'
                         :treeDataSimpleMode='true'
                         :tree-data='depTreeData'>
          </a-tree-select>
        </div>
        <div class="diyImg-title">
          <uploadOss @setFileList='setFileList'
                     accept='.png,.jpg,.jpeg,.ico'>
            <div v-if="this.settingJson.logoImg === ''"
                 class="diyImg-title-logo">
              <span>logo</span>
            </div>
            <img v-else
                 class="diyImg-title-logo"
                 :src="this.settingJson.logoImg">
          </uploadOss>
          <div class="diyImg-title-text">
            <a-input placeholder="自定义标题 （限13字以内）"
                     v-model='settingJson.titleText'
                     :maxLength='13' />
          </div>
        </div>
        <!-- 自定义标题 -->
        <a-input class="fz-titleInput"
                 placeholder="自定义标题"
                 v-model='settingJson.centerTextleft'
                 :maxLength='8' />
        <!-- 自定义口号 -->
        <a-input class="kh-titleInput"
                 placeholder="自定义口号（超过18个字符将滚动,限50字内）"
                 v-model='settingJson.centerTextright'
                 :maxLength='50' />
        <!-- 自定义中央图片 -->
        <div class="diyImg-center">
          <uploadOss @setFileList='setFileList2'
                     accept='.png,.jpg,.jpeg,.ico'>
            <div v-if="this.settingJson.centerImg === ''"
                 class="diyImg-center-logo">
              <span>请选择图片</span>
            </div>
            <img v-else
                 class="diyImg-center-logo"
                 :src="this.settingJson.centerImg">
          </uploadOss>
        </div>
        <!-- 成本区域diy -->
        <draggable ghostClass="ghost"
                   animation="300"
                   @sort="sort"
                   class="diy-allbox diy-chengben-position"
                   @end="end">
          <div v-for="(item, index) in this.settingJson.cbList"
               :key="index"
               :class="
							item.checkflag || cbflag
								? 'diy-allbox-onePlus flex diy-oncheck'
								: 'diy-allbox-one flex'
						"
               data-title="cblist"
               @click="tocheckcb(item)"
               :data-index="index"
               @dragover="onDragover"
               @drop="onDrop">
            <div data-title="cblist"
                 :data-index="index"
                 v-if="item.src === ''">
              暂无拖拽图标
            </div>
            <img data-title="cblist"
                 :data-index="index"
                 v-else
                 :src="item.src" />
          </div>
        </draggable>
        <!-- 效率 设备稼动率区域 diy -->
        <draggable ghostClass="ghost"
                   animation="300"
                   @sort="sort"
                   class="diy-allbox diy-sdjd-position"
                   @end="end">
          <div v-for="(item, index) in this.settingJson.sbjdList"
               :key="index"
               :class="
							item.checkflag || sbjdflag
								? 'diy-allbox-onePlus flex diy-oncheck diy-sdjd-wh'
								: 'diy-allbox-one flex diy-sdjd-wh'
						"
               data-title="sbjd"
               @click="tocheckcb(item)"
               :data-index="index"
               @dragover="onDragover"
               @drop="onDrop">
            <div data-title="sbjd"
                 :data-index="index"
                 v-if="item.src === ''">
              <!-- 暂无拖拽图标 -->
            </div>
            <img data-title="sbjd"
                 :data-index="index"
                 v-else
                 :src="item.src" />
          </div>
        </draggable>
        <!-- 效率 工作负荷率区域 diy -->
        <draggable ghostClass="ghost"
                   animation="300"
                   @sort="sort"
                   class="diy-gzfh-position"
                   @end="end">
          <div v-for="(item, index) in this.settingJson.gzfhList"
               :key="index"
               :class="
							item.checkflag || gzfhflag
								? 'diy-allbox-onePlus flex diy-oncheck diy-gzfh-wh'
								: 'diy-allbox-one flex diy-gzfh-wh'
						"
               data-title="gzfh"
               @click="tocheckcb(item)"
               :data-index="index"
               @dragover="onDragover"
               @drop="onDrop">
            <div data-title="gzfh"
                 :data-index="index"
                 v-if="item.src === ''">
              暂无拖拽图标
            </div>
            <img data-title="gzfh"
                 :data-index="index"
                 v-else
                 :src="item.src" />
          </div>
        </draggable>
        <!-- 效率 运维工作效率区域 diy -->
        <draggable ghostClass="ghost"
                   animation="300"
                   @sort="sort"
                   class="diy-ywgz-position"
                   @end="end">
          <div v-for="(item, index) in this.settingJson.ywgzList"
               :key="index"
               :class="
							item.checkflag || ywgzflag
								? 'diy-allbox-onePlus flex diy-oncheck diy-gzfh-wh'
								: 'diy-allbox-one flex diy-gzfh-wh'
						"
               data-title="ywgz"
               @click="tocheckcb(item)"
               :data-index="index"
               @dragover="onDragover"
               @drop="onDrop">
            <div data-title="ywgz"
                 :data-index="index"
                 v-if="item.src === ''">
              暂无拖拽图标
            </div>
            <img data-title="ywgz"
                 :data-index="index"
                 v-else
                 :src="item.src" />
          </div>
        </draggable>
        <!-- 计划区域 diy -->
        <draggable ghostClass="ghost"
                   animation="300"
                   @sort="sort"
                   class="diy-jh-position"
                   @end="end">
          <div v-for="(item, index) in this.settingJson.jhList"
               :key="index"
               :class="
							item.checkflag || jhflag
								? 'diy-allbox-onePlus flex diy-oncheck diy-jh-wh'
								: 'diy-allbox-one flex diy-jh-wh'
						"
               data-title="jh"
               @click="tocheckcb(item)"
               :data-index="index"
               @dragover="onDragover"
               @drop="onDrop">
            <div data-title="jh"
                 :data-index="index"
                 v-if="item.src === ''">
              暂无拖拽图标
            </div>
            <img data-title="jh"
                 :data-index="index"
                 v-else
                 :src="item.src" />
          </div>
        </draggable>
        <!-- 质量区域 diy -->
        <draggable ghostClass="ghost"
                   @sort="sort"
                   animation="300"
                   class="diy-zl-position"
                   @end="end">
          <div v-for="(item, index) in this.settingJson.zlList"
               :key="index"
               :class="
							item.checkflag || zlflag
								? 'diy-allbox-onePlus flex diy-oncheck diy-zl-wh'
								: 'diy-allbox-one flex diy-zl-wh'
						"
               data-title="zl"
               @click="tocheckcb(item)"
               :data-index="index"
               @dragover="onDragover"
               @drop="onDrop">
            <div data-title="zl"
                 :data-index="index"
                 v-if="item.src === ''">
              暂无拖拽图标
            </div>
            <img data-title="zl"
                 :data-index="index"
                 v-else
                 :src="item.src" />
          </div>
        </draggable>
        <!-- 交期区域 diy -->
        <draggable ghostClass="ghost"
                   animation="300"
                   @sort="sort"
                   class="diy-jq-position"
                   @end="end">
          <div v-for="(item, index) in this.settingJson.jqList"
               :key="index"
               :class="
							item.checkflag || jqflag
								? 'diy-allbox-onePlus flex diy-oncheck diy-jq-wh'
								: 'diy-allbox-one flex diy-jq-wh'
						"
               data-title="jq"
               @click="tocheckcb(item)"
               :data-index="index"
               @dragover="onDragover"
               @drop="onDrop">
            <div data-title="jq"
                 :data-index="index"
                 v-if="item.src === ''">
              暂无拖拽图标
            </div>
            <img data-title="jq"
                 :data-index="index"
                 v-else
                 :src="item.src" />
          </div>
        </draggable>
        <!-- 安全区域 diy -->
        <draggable ghostClass="ghost"
                   animation="300"
                   @sort="sort"
                   class="diy-allbox diy-aq-position"
                   @end="end">
          <div v-for="(item, index) in this.settingJson.aqList"
               :key="index"
               :class="
							item.checkflag || aqflag
								? 'diy-allbox-onePlus flex diy-oncheck diy-aq-wh'
								: 'diy-allbox-one flex diy-aq-wh'
						"
               data-title="aq"
               @click="tocheckcb(item)"
               :data-index="index"
               @dragover="onDragover"
               @drop="onDrop">
            <div data-title="aq"
                 :data-index="index"
                 v-if="item.src === ''">
              暂无拖拽图标
            </div>
            <img data-title="aq"
                 :data-index="index"
                 v-else
                 :src="item.src" />
          </div>
        </draggable>
        <!-- 知识区域 diy -->
        <draggable ghostClass="ghost"
                   animation="300"
                   @sort="sort"
                   class="diy-zs-position"
                   @end="end">
          <div v-for="(item, index) in this.settingJson.zsList"
               :key="index"
               :class="
							item.checkflag || zsflag
								? 'diy-allbox-onePlus flex diy-oncheck diy-zs-wh'
								: 'diy-allbox-one flex diy-zs-wh'
						"
               data-title="zs"
               @click="tocheckcb(item)"
               :data-index="index"
               @dragover="onDragover"
               @drop="onDrop">
            <div data-title="zs"
                 :data-index="index"
                 v-if="item.src === ''">
              暂无拖拽图标
            </div>
            <img data-title="zs"
                 :data-index="index"
                 v-else
                 :src="item.src" />
          </div>
        </draggable>
      </div>

    </div>
  </div>
</template>

<script>
import draggable from 'vuedraggable'
import currency from '@/core/currency'
import * as util from '@/core/util'
import UUID from 'es6-uuid'
import uploadOss from '@/components/uploadOss'
export default {
  components: {
    draggable,
    uploadOss,
  },
  data() {
    return {
      nowImg: require('@/assets/logo.png'),
      searchName: '',
      fileData: {},
      fileAction: '',
      fileList: [],
      fileDir: '',
      fileName: '',
      fileUUID: '',
      fileNameEnd: '',
      fileUpdateState: '',
      confirmText:'',
      cbflag: false,
      sbjdflag: false,
      gzfhflag: false,
      ywgzflag: false,
      jhflag: false,
      zlflag: false,
      jqflag: false,
      aqflag: false,
      zsflag: false,
      dropFlag: false,
      dropFlagsbjd: false,
      isshowcblist: true, // 成本指标 显示/隐藏
      isshowjhlist: true, // 计划指标 显示/隐藏
      isshowzllist: true, // 质量指标 显示/隐藏
      isshowjqlist: true, // 交期指标 显示/隐藏
      isshowxllist: true, // 效率指标 显示/隐藏
      isshowaqlist: true, // 安全指标 显示/隐藏
      isshowzslist: true, // 知识指标 显示/隐藏
      nowelement: '',
      dropSrc: '',
      dropName: '',
      dropEachartId: '',
      centerTextright: '',
      settingJson: {
        logoImg: '',
        deptnewName: '',
        titleText: '',
        centerTextleft: '',
        centerTextright: '',
        centerImg: '',
        deptvalue: undefined,
        // 成本区域内容
        cbList: [
          {
            name: '',
            src: '',
            checkflag: false,
            echartsId: '',
          },
          {
            name: '',
            src: '',
            checkflag: false,
            echartsId: '',
          },
          {
            name: '',
            src: '',
            checkflag: false,
            echartsId: '',
          },
          {
            name: '',
            src: '',
            checkflag: false,
            echartsId: '',
          },
        ],
        // 设备稼动率内容
        sbjdList: [
          {
            name: '',
            src: '',
            checkflag: false,
            value:0
          },
          {
            name: '',
            src: '',
            checkflag: false,
            value:0
          },
        ],
        // 工作负荷率内容
        gzfhList: [
          {
            name: '',
            src: '',
            checkflag: false,
            echartsId: '',
          },
          {
            name: '',
            src: '',
            checkflag: false,
            echartsId: '',
          },
        ],
        // 运维工作效率内容
        ywgzList: [
          {
            name: '',
            src: '',
            checkflag: false,
          },
          {
            name: '',
            src: '',
            checkflag: false,
          },
        ],
        // 计划区域内容
        jhList: [
          {
            name: '',
            src: '',
            checkflag: false,
          },
        ],
        // 质量区域内容
        zlList: [
          {
            name: '',
            src: '',
            checkflag: false,
          },
          {
            name: '',
            src: '',
            checkflag: false,
          },
        ],
        // 交期区域内容
        jqList: [
          {
            name: '',
            src: '',
            checkflag: false,
          },
          {
            name: '',
            src: '',
            checkflag: false,
          },
        ],
        // 安全区域内容
        aqList: [
          {
            name: '',
            src: '',
            checkflag: false,
          },
          {
            name: '',
            src: '',
            checkflag: false,
          },
          {
            name: '',
            src: '',
            checkflag: false,
          },
          {
            name: '',
            src: '',
            checkflag: false,
          },
        ],
        // 知识区域内容
        zsList: [
          {
            name: '',
            src: '',
            checkflag: false,
          },
          {
            name: '',
            src: '',
            checkflag: false,
          },
          {
            name: '',
            src: '',
            checkflag: false,
          },
        ],
      },
      depTreeData: [],
      tenantId: '',
      deptvalue: undefined,
    }
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
    console.log(str[2].split('&'));
    currency.setLocalStorageItem('token', this.GetQueryString('token'))
  },
  mounted() {
    this.getDepTree()
    this.getNowDiydata()
    // console.log(this.$route.fullPath)
  },
  methods: {
    // 分解地址栏参数
    GetQueryString(name) {
      var reg = new RegExp('(^|&)' + name + '=([^&]*)(&|$)')
      var r = this.$route.fullPath.substr(1).match(reg)
      console.log(r);
      if (r != null) return decodeURIComponent(r[2])
      return null
    },
    // 3.0登录接口
    login() {
      const formData = new FormData()
      formData.append('telNumber', 'demo')
      formData.append('passWord', '12345678')
      formData.append('tenantId', '7135a622e1f34210aae9ff4bb52c5e49')
      formData.append('userId', '4d5eba4d739111ecac2700163e043cb2')
      util.postFormData3('/admin/user/login', formData).then((res) => {
        currency.setLocalStorageItem('token', res.data.token)
        // this.settingBoard()
        this.getNowDiydata()
      })
    },
    // 获取当前租户所有部门
    getDepTree() {
      let dataset = {
        dataset: this.tenantId,
      }
      util.post('department/search', dataset).then((res) => {
        let temp = res.dataset
        temp.forEach((ele) => {
          ele.value = ele.id
          ele.title = ele.name
          if (!ele.pId) {
            ele.pId = null
          }
        })
        this.depTreeData = temp
      })
    },
    // 选中部门方法
    depTreeChange(val, obj, e) {
      console.log(obj)
      this.settingJson.deptvalue = val
      this.settingJson.deptnewName = e.triggerNode.title
    },
    setFileList(val) {
      this.settingJson.logoImg = val
    },
    setFileList2(val) {
      this.settingJson.centerImg = val
    },
    // 获取当前已保存配置记录
    getNowDiydata() {
      let dataset = {
        setType: '0',
        // tenantId: this.tenantId,
        // tissueInfoId: '4d5eba4d739111ecac2700163e043cb2',
      }
      util
        .getFormData3('admin/settingBoard/getSettingBoard', dataset)
        .then((res) => {
          // console.log(JSON.parse(res.data.settingJson))
          if (res.data) {
            this.settingJson = JSON.parse(res.data.settingJson)
          }
        })
    },
    settingBoard(val) {
      let that = this
      const formData = new FormData()
      formData.append('setType', val)
      formData.append('settingJson', JSON.stringify(this.settingJson))
      formData.append('tenantId', this.tenantId)
      formData.append('tissueInfoId', this.settingJson.deptvalue)
      if (val === '1') {
        this.confirmText = '是否确认发布此配置到正式环境？'
      } else if (val === '0') {
        this.confirmText = '是否确认保存此配置到本地？'
      }
      this.$confirm({
        title: that.confirmText,
        onOk() {
          util
            .postFormData3('admin/settingBoard/applyBoard', formData)
            .then((res) => {
              if (res.statusCode === 200) {
                that.$message.success('操作成功！')
              }
              that.getNowDiydata()
            })
        },
        onCancel() {},
      })
    },
    // 切换显示隐藏- 成本
    tobeshow(val) {
      if (val === 'cb') {
        this.isshowcblist = !this.isshowcblist
      } else if (val === 'xl') {
        this.isshowxllist = !this.isshowxllist
      } else if (val === 'jh') {
        this.isshowjhlist = !this.isshowjhlist
      } else if (val === 'zl') {
        this.isshowzllist = !this.isshowzllist
      } else if (val === 'jq') {
        this.isshowjqlist = !this.isshowjqlist
      } else if (val === 'aq') {
        this.isshowaqlist = !this.isshowaqlist
      } else if (val === 'zs') {
        this.isshowzslist = !this.isshowzslist
      }
    },
    // 上传之前
    beforeUpload(file) {
      return new Promise((resolve) => {
        util.post('ossService/webUpdateFile', '').then((reg) => {
          let uuid = UUID(32)
          let fileName = file.name //文件名
          let fileNameEnd = fileName.substring(
            fileName.lastIndexOf('.'),
            fileName.length
          ) //文件后缀
          let temp = {
            OSSAccessKeyId: reg.accessid,
            callback: reg.callback,
            key: reg.dir + uuid + fileNameEnd,
            policy: reg.policy,
            signature: reg.signature,
            success_action_status: '200', //让服务端返回200,不然，默认会返回204
          }
          this.fileData = temp
          this.fileAction = reg.host
          this.fileDir = reg.dir
          this.fileUUID = uuid
          this.fileName = fileName
          this.fileNameEnd = fileNameEnd
          resolve(file)
        })
      })
    },
    // 上传文件变动
    handleChange(info) {
      let fileList = [...info.fileList]
      if (fileList.length > 1) {
        //如果上传第二个文件
        fileList = fileList.slice(-1)
      }
      this.fileList = fileList
      const { status } = info.file
      if (status == 'removed') {
        this.fileUpdateState = 'end' //文件上传结束状态
      }
      if (status == 'uploading') {
        this.fileUpdateState = 'ing' //文件上传中状态
      }
      if (status === 'done') {
        // message.success(`${info.file.name} 文件上传成功。`)
        console.log('上传成功')
        this.fileUpdateState = 'end' //文件上传结束状态
        this.logoImg =
          'http://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/' +
          this.fileDir +
          this.fileUUID +
          this.fileNameEnd
      } else if (status === 'error') {
        this.fileUpdateState = 'end' //文件上传结束状态
      }
    },
    // 上传文件变动
    centerhandleChange(info) {
      let fileList = [...info.fileList]
      if (fileList.length > 1) {
        //如果上传第二个文件
        fileList = fileList.slice(-1)
      }
      this.fileList = fileList
      const { status } = info.file
      if (status == 'removed') {
        this.fileUpdateState = 'end' //文件上传结束状态
      }
      if (status == 'uploading') {
        this.fileUpdateState = 'ing' //文件上传中状态
      }
      if (status === 'done') {
        // message.success(`${info.file.name} 文件上传成功。`)
        console.log('上传成功')
        this.fileUpdateState = 'end' //文件上传结束状态
        this.centerImg =
          'http://new-jingyiweishi.oss-cn-shenzhen.aliyuncs.com/' +
          this.fileDir +
          this.fileUUID +
          this.fileNameEnd
      } else if (status === 'error') {
        this.fileUpdateState = 'end' //文件上传结束状态
      }
    },
    // 选中图块
    tocheckcb(item) {
      item.checkflag = !item.checkflag
    },
    // 删除选中图块
    todel() {
      let that = this
      this.$confirm({
        title: '是否确认删除选中区域？',
        onOk() {
          that.settingJson.cbList.forEach((item) => {
            if (item.checkflag) {
              item.src = ''
              item.name = ''
              item.checkflag = false
              item.echartsId = ''
            }
          })
          that.settingJson.sbjdList.forEach((item) => {
            if (item.checkflag) {
              item.src = ''
              item.name = ''
              item.checkflag = false
              item.value = 0
            }
          })
          that.settingJson.gzfhList.forEach((item) => {
            if (item.checkflag) {
              item.src = ''
              item.name = ''
              item.checkflag = false
              item.echartsId = ''
            }
          })
          that.settingJson.ywgzList.forEach((item) => {
            if (item.checkflag) {
              item.src = ''
              item.name = ''
              item.checkflag = false
            }
          })
          that.settingJson.jhList.forEach((item) => {
            if (item.checkflag) {
              item.src = ''
              item.name = ''
              item.checkflag = false
            }
          })
          that.settingJson.zlList.forEach((item) => {
            if (item.checkflag) {
              item.src = ''
              item.name = ''
              item.checkflag = false
            }
          })
          that.settingJson.jqList.forEach((item) => {
            if (item.checkflag) {
              item.src = ''
              item.name = ''
              item.checkflag = false
            }
          })
          that.settingJson.aqList.forEach((item) => {
            if (item.checkflag) {
              item.src = ''
              item.name = ''
              item.checkflag = false
            }
          })
          that.settingJson.zsList.forEach((item) => {
            if (item.checkflag) {
              item.src = ''
              item.name = ''
              item.checkflag = false
            }
          })
        },
        onCancel() {},
      })
    },
    onDragover(e) {
      e.preventDefault()
    },
    onDrop(e) {
      if (this.dropFlag === false) {
        return
      }
      if (e.target.dataset.title !== this.droptype) {
        this.$warning({
          title: '请移动正确区域！',
          mask: false,
        })
        return
      }
      if (e.target.dataset.title === 'cblist') {
        this.settingJson.cbList[e.target.dataset.index].name = this.dropName
        this.settingJson.cbList[e.target.dataset.index].echartsId =
          this.dropEachartId
        this.settingJson.cbList[
          e.target.dataset.index
        ].src = require('@/assets/' + this.dropSrc + '.png')
      } else if (e.target.dataset.title === 'sbjd') {
        this.settingJson.sbjdList[e.target.dataset.index].name = this.dropName
        this.settingJson.sbjdList[
          e.target.dataset.index
        ].src = require('@/assets/' + this.dropSrc + '.png')
      } else if (e.target.dataset.title === 'gzfh') {
        this.settingJson.gzfhList[e.target.dataset.index].name = this.dropName
        this.settingJson.gzfhList[e.target.dataset.index].echartsId =
          this.dropEachartId
        this.settingJson.gzfhList[
          e.target.dataset.index
        ].src = require('@/assets/' + this.dropSrc + '.png')
      } else if (e.target.dataset.title === 'ywgz') {
        this.settingJson.ywgzList[e.target.dataset.index].name = this.dropName
        this.settingJson.ywgzList[
          e.target.dataset.index
        ].src = require('@/assets/' + this.dropSrc + '.png')
      } else if (e.target.dataset.title === 'jh') {
        this.settingJson.jhList[e.target.dataset.index].name = this.dropName
        this.settingJson.jhList[
          e.target.dataset.index
        ].src = require('@/assets/' + this.dropSrc + '.png')
      } else if (e.target.dataset.title === 'zl') {
        this.settingJson.zlList[e.target.dataset.index].name = this.dropName
        this.settingJson.zlList[
          e.target.dataset.index
        ].src = require('@/assets/' + this.dropSrc + '.png')
      } else if (e.target.dataset.title === 'jq') {
        this.settingJson.jqList[e.target.dataset.index].name = this.dropName
        this.settingJson.jqList[
          e.target.dataset.index
        ].src = require('@/assets/' + this.dropSrc + '.png')
      } else if (e.target.dataset.title === 'aq') {
        this.settingJson.aqList[e.target.dataset.index].name = this.dropName
        this.settingJson.aqList[
          e.target.dataset.index
        ].src = require('@/assets/' + this.dropSrc + '.png')
      } else if (e.target.dataset.title === 'zs') {
        this.settingJson.zsList[e.target.dataset.index].name = this.dropName
        this.settingJson.zsList[
          e.target.dataset.index
        ].src = require('@/assets/' + this.dropSrc + '.png')
      }
      this.droptype = ''
      this.dropFlag = false
    },
    start(e) {
      console.log(e)
      this.dropFlag = true
      this.droptype = e.item.dataset.title
      this.dropSrc = e.item.dataset.src
      this.dropEachartId = e.item.dataset.echartsid
      this.dropName = e.item.innerText
      if (e.item.dataset.title === 'cblist') {
        this.cbflag = true
      } else if (e.item.dataset.title === 'sbjd') {
        this.sbjdflag = true
      } else if (e.item.dataset.title === 'gzfh') {
        this.gzfhflag = true
      } else if (e.item.dataset.title === 'ywgz') {
        this.ywgzflag = true
      } else if (e.item.dataset.title === 'jh') {
        this.jhflag = true
      } else if (e.item.dataset.title === 'zl') {
        this.zlflag = true
      } else if (e.item.dataset.title === 'jq') {
        this.jqflag = true
      } else if (e.item.dataset.title === 'aq') {
        this.aqflag = true
      } else if (e.item.dataset.title === 'zs') {
        this.zsflag = true
      }
    },
    sort(e) {
      console.log(e)
      let name = e.item.dataset.title
      if (name === 'cblist') {
        this.settingJson.cbList = this.swapArr(
          this.settingJson.cbList,
          e.oldIndex,
          e.newIndex
        )
      } else if (name === 'sbjd') {
        this.settingJson.sbjdList = this.swapArr(
          this.settingJson.sbjdList,
          e.oldIndex,
          e.newIndex
        )
      } else if (name === 'gzfh') {
        this.settingJson.gzfhList = this.swapArr(
          this.settingJson.gzfhList,
          e.oldIndex,
          e.newIndex
        )
      } else if (name === 'ywgz') {
        console.log(this.ywgzList)
        this.settingJson.ywgzList = this.swapArr(
          this.settingJson.ywgzList,
          e.oldIndex,
          e.newIndex
        )
      } else if (name === 'jh') {
        this.settingJson.jhList = this.swapArr(
          this.settingJson.jhList,
          e.oldIndex,
          e.newIndex
        )
      } else if (name === 'zl') {
        this.settingJson.zlList = this.swapArr(
          this.settingJson.zlList,
          e.oldIndex,
          e.newIndex
        )
      } else if (name === 'jq') {
        this.settingJson.jqList = this.swapArr(
          this.settingJson.jqList,
          e.oldIndex,
          e.newIndex
        )
      } else if (name === 'aq') {
        this.settingJson.aqList = this.swapArr(
          this.settingJson.aqList,
          e.oldIndex,
          e.newIndex
        )
      } else if (name === 'zs') {
        this.settingJson.zsList = this.swapArr(
          this.settingJson.zsList,
          e.oldIndex,
          e.newIndex
        )
      }
    },
    // 数组内容位置对调
    swapArr(array, index1, index2) {
      // console.log(array, index1, index2)
      [array[index1], array[index2]] = [array[index2], array[index1]]
      return array
    },
    end() {
      this.cbflag = false
      this.sbjdflag = false
      this.gzfhflag = false
      this.ywgzflag = false
      this.jhflag = false
      this.zlflag = false
      this.jqflag = false
      this.aqflag = false
      this.zsflag = false
    },
    tohei() {
      console.log(1)
    },
  },
}
</script>

<style scoped lang="less">
.searchName-Input {
  margin-top: 10px;
  padding: 0 20px 0 20px;
  width: 100%;
  margin-bottom: 10px;
}
.flex {
  display: flex;
  justify-content: center;
  align-items: center;
}
.diy-allbox-onePlus {
  width: 170px;
  height: 86px;
  margin-top: 6px;
  background: rgba(0, 3, 28, 0.25);
  border: 1px solid #2fc25b;
  font-size: 12px;
  img {
    width: 100%;
    height: 100%;
  }
}
// .diy-chengben-onePlus:hover {
//   border: 1px solid #2fc25b;
// }
.ghost {
  border: 1px solid #2fc25b !important;
}
.diy-sbjd {
  display: flex;
  flex-wrap: wrap;
  width: 355px;
  position: absolute;
  top: 182px;
  left: 15px;
  justify-content: space-between;
  font-size: 12px;
  .diy-sbjd-one {
    width: 170px;
    height: 86px;
    margin-top: 6px;
    background: rgba(0, 3, 28, 0.25);
    border: 1px solid rgba(0, 126, 215, 0.65);
    img {
      width: 100%;
      height: 100%;
    }
  }
}
.diy-chengben-position {
  position: absolute;
  top: 82px;
  left: 15px;
}
.isshow {
  width: 16px;
  height: 16px;
  background-image: url('../assets/icon_xiala@3x.png');
  background-size: 100% 100%;
}
.ant-input {
  background-color: rgba(0, 3, 28, 0.25) !important;
  border: 1px solid rgba(0, 126, 215, 0.65) !important;
  color: #606d74 !important;
  border-radius: 0 !important;
}
.noshow {
  width: 16px;
  height: 16px;
  background-size: 100% 100%;
  background-image: url('../assets/icon_zhedie@3x.png');
}
.diy-sdjd-position {
  position: absolute;
  top: 318px;
  left: 15px;
  background-size: 100% 100%;
  background-image: url('../assets/仪表盘@3x.png');
}
.diy-gzfh-position {
  position: absolute;
  top: 462px;
  left: 15px;
}
.diy-ywgz-position {
  position: absolute;
  top: 462px;
  left: 200px;
}
.diy-jh-position {
  position: absolute;
  top: 122px;
  left: 392px;
}
.diy-zl-position {
  position: absolute;
  bottom: 17px;
  left: 392px;
  display: flex;
  width: 245px;
  justify-content: space-between;
}
.diy-aq-position {
  position: absolute;
  top: 87px;
  right: 20px;
  width: 350px !important;
}
.diy-zs-position {
  position: absolute;
  bottom: 17px;
  right: 20px;
  display: flex;
  width: 350px;
  justify-content: space-between;
}
.diy-jq-position {
  position: absolute;
  bottom: 17px;
  left: 662px;
  display: flex;
  width: 245px;
  justify-content: space-between;
}
.fz-titleInput {
  width: 69px;
  height: 29px;
  background: rgba(0, 3, 28, 0.25);
  border: 1px solid rgba(0, 126, 215, 0.65);
  font-size: 12px;
  color: #fff;
  position: absolute;
  left: 392px;
  top: 80px;
}
.kh-titleInput {
  width: 414px;
  height: 29px;
  background: rgba(0, 3, 28, 0.25);
  border: 1px solid rgba(0, 126, 215, 0.65);
  font-size: 12px;
  position: absolute;
  left: 482px;
  top: 80px;
}
.diy-aq-wh {
  width: 170px !important;
  height: 172px !important;
}
.diy-sdjd-wh {
  height: 125px !important;
  border: 0 !important;
}
.diy-zs-wh {
  width: 112px !important;
  height: 210px !important;
}
.diy-zl-wh {
  width: 118px !important;
  height: 173px !important;
}
.diy-jq-wh {
  width: 118px !important;
  height: 173px !important;
}
.diy-gzfh-wh {
  width: 170px !important;
  height: 110px !important;
}
.diy-jh-wh {
  width: 516px !important;
  height: 58px !important;
}
.diy-oncheck {
  border: 1px solid #2fc25b !important;
}
.diy-allbox-one {
  width: 170px;
  height: 86px;
  margin-top: 6px;
  background: rgba(0, 3, 28, 0.25);
  border: 1px solid rgba(0, 126, 215, 0.65);
  font-size: 12px;
  img {
    width: 100%;
    height: 100%;
  }
}
.diy-allbox {
  display: flex;
  flex-wrap: wrap;
  width: 355px;
  justify-content: space-between;
}
.diyImg-center-logo {
  width: 510px;
  height: 285px;
  background: rgba(0, 3, 28, 0.25);
  border: 1px solid rgba(0, 126, 215, 0.65);
  color: #bfbfbf;
  line-height: 285px;
  text-align: center;
}
.diyImg-center {
  position: absolute;
  left: 392px;
  top: 201px;
}
.diyImg-title {
  width: 450px;
  height: 32px;
  margin: 0 auto;
  padding-top: 13px;
  display: flex;
  .diyImg-title-logo {
    width: 32px;
    height: 32px;
    background: rgba(0, 3, 28, 0.25);
    border: 1px solid rgba(0, 126, 215, 0.65);
    font-size: 12px;
    color: #bfbfbf;
    padding-top: 1px;
  }
  .diyImg-title-text {
    width: 401px;
    height: 32px;
    // height: 29px;
    // background: rgba(0, 3, 28, 0.25);
    // border: 1px solid rgba(0, 126, 215, 0.65);
    margin-left: 8px;
    color: #606d74;
    font-size: 16px;
  }
}
.diyImg-top {
  width: 136px;
  height: 21px;
  background: rgba(0, 3, 28, 0.25);
  border: 1px solid rgba(0, 126, 215, 0.65);
  position: absolute;
  left: 8px;
  top: 4px;
  .select-dep {
    background: rgba(0, 3, 28, 0.25);
    border: 1px solid rgba(0, 126, 215, 0.65);
  }
}
.ml-t-8 {
  margin-top: 8px;
}
.diyBoard-right {
  width: calc(100% - 332px);
  // min-width: 1298px;
  height: 100%;
  background-color: #fff;
  padding: 24px 20px 0 20px;
  .diyBoard-right-top {
    width: 100%;
    height: 81px;
    background: #e6f7ff;
    border-radius: 41px;
    display: flex;
    .right-top-box {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      color: #1890ff;
      font-size: 14px;
      margin-left: 36px;
      img {
        width: 24px;
        height: 24px;
      }
    }
  }
  .diyBoard-right-bottom {
    position: relative;
    max-width: 1298px;
    width: 1298px;
    margin-top: 24px;
    height: 708px;
    background: url('../assets/bg.png') no-repeat;
    // background-image: url('../assets/bg.png');
    background-size: 100% 100%;
    image-rendering: -webkit-optimize-contrast;
  }
}
.left-content-one {
  margin-top: 20px;
  .left-content-list {
    display: flex;
    flex-wrap: wrap;
    .left-content-list-one {
      width: 88px;
      height: 88px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      img {
        width: 44px;
        height: 44px;
      }
    }
  }
  .left-content-title {
    width: 100%;
    height: 40px;
    border: 1px solid #e8e8e8;
    display: flex;
    padding: 0 15px 0 12px;
    align-items: center;
    justify-content: space-between;
    color: #333333;
    font-size: 14px;
  }
  .left-content-smalltitle {
    text-align: left;
    font-size: 12px;
    color: #767e91;
    margin-left: 9px;
    margin-top: 12px;
  }
}
.diyBoard-left-content {
  width: 100%;
  height: calc(100% - 120px);
  padding: 0 18px 0 18px;
  overflow: scroll;
}
/* 设置滚动条的样式 */

::-webkit-scrollbar {
  width: 12px;
}

/* 滚动槽 */

::-webkit-scrollbar-track {
  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.3);

  border-radius: 10px;
}

/* 滚动条滑块 */

::-webkit-scrollbar-thumb {
  border-radius: 10px;

  background: rgba(0, 0, 0, 0.1);

  -webkit-box-shadow: inset006pxrgba(0, 0, 0, 0.5);
}

::-webkit-scrollbar-thumb:window-inactive {
  // background:rgba(255,0,0,0.4);
}
.diyBoard-left-title {
  display: flex;
  padding: 0px 16px 0 16px;
  height: 48px;
  justify-content: space-between;
  align-items: center;
  .title-lable {
    font-size: 16px;
    color: #333333;
  }
  .title-icon {
    font-size: 14px;
    color: #1890ff;
  }
}
.diyBoard-left-line {
  height: 1px;
  width: 100%;
  background-color: #e8e8e8;
}
.diyBoard {
  background-color: #f0f2f5;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: space-between;
  padding: 20px 20px 0 20px;
  box-sizing: border-box;
  overflow: auto;
  .diyBoard-left {
    width: 312px;
    height: 100%;
    background-color: #fff;
  }
}
.demo {
  width: 200px;
  height: 200px;
  color: pink;
  background-color: pink;
}
.tobecolor {
  width: 200px;
  height: 200px;
  img {
    width: 100%;
    height: 100%;
  }
}
.one {
  height: 50px;
  width: 50px;
  border: 1px solid #000000;
}
.two {
  height: 50px;
  width: 50px;
  border: 1px solid #000000;
}
</style>

<template>
  <div class="selectEqus">
    <a-input v-model="inputName"
             placeholder="请选择"
             @click="showDialog"
             allow-clear
             @change="InputonChange" />
    <jywsDialog :visible="visible"
                :title="modalTitle"
                @updataClose="closeModal"
                @updataWin="updataWin"
                width="960px"
                :roger="jywsDialogBottomRoger"
                :bottom-name="jywsDialogBottomName">
      <div class="selectEqus-modal">
        <div>
          <div class="selectEqus-search">
            <a-row>
              <a-col :span="8"
                     class="col-flex">
                <!-- <div>组织</div>
								<div class="col-value">
									<a-input placeholder="请输入组织" />
								</div> -->
              </a-col>
              <a-col :span="8"
                     class="col-flex"> </a-col>
              <a-col :span="8"
                     class="col-flex">
                <a-input-search :placeholder="placeholder"
                                v-model="searchValue"
                                enter-button="搜索"
                                @search="onSearch" />
              </a-col>
            </a-row>
          </div>
          <div class="selectEqus-table">
            <a-table class="borderTable"
                     :columns="columns"
                     :scroll="{ y: 500 }"
                     :row-selection="rowSelection"
                     :data-source="dataSource"
                     :pagination="false">
              <span slot="time"
                    slot-scope="time">
                {{ $moment(time).format('YYYY-MM-DD HH:mm') }}
              </span>
              <span slot="c_special"
                    slot-scope="text, record">
                {{ record.c_special === '1' ? '是' : '否' }}
              </span>
            </a-table>
            <a-pagination @change="onChange"
                          @showSizeChange="showSizeChange"
                          size="small"
                          :total="total"
                          :defaultCurrent="pageIndex"
                          :defaultPageSize="pageSize"
                          show-size-changer
                          show-quick-jumper
                          :show-total="(total) => `总共 ${total} 条`" />
          </div>
        </div>
      </div>
    </jywsDialog>
  </div>
</template>

<script>
import jywsDialog from '@/components/jywsDialog.vue'
import * as util from '@/core/util'

export default {
  name: 'selectEqus',
  components: {
    jywsDialog,
  },
  props: {
    radioFlag: {
      type: Boolean,
      default: false,
    },
    inputName: {
      type: String,
      default: '',
    },
    special: {
      type: String,
      default: '0',
    },
  },
  data() {
    return {
      tenantId: '',
      visible: false,
      modalTitle: '组织/设备',
      jywsDialogBottomRoger: '取消',
      jywsDialogBottomName: '确认',
      pageIndex: 1,
      pageSize: 10,
      total: 10,
      dataSource: [],
      selectedRowKeys: [],
      selectedRows: [],
      activeKey: '1',
      searchValue: '',
      placeholder: '请输入设备信息',
      // 表格表头
      columns: [
        {
          title: '所属组织',
          dataIndex: 'depName',
          key: 'depName',
          width: 150,
        },
        {
          title: '设备简称',
          dataIndex: 'c_simple_name',
          key: 'c_simple_name',
          width: 150,
        },
        {
          title: '型号',
          dataIndex: 'c_standard',
          key: 'c_standard',
          width: 150,
        },
        {
          title: '特种设备',
          dataIndex: 'c_special',
          key: 'c_special',
          width: 150,
          scopedSlots: { customRender: 'c_special' },
        },
        {
          title: '资产编码',
          dataIndex: 'c_assets_code',
          key: 'c_assets_code',
          width: 150,
        },
      ],
    }
  },
  created() {
    var str = this.$route.fullPath.split('=')
    this.tenantId = str[1].split('&')[0]
  },
  mounted() {
    this.getEqusList()
  },
  computed: {
    rowSelection() {
      return {
        type: this.radioFlag ? 'radio' : 'checkbox',
        selectedRowKeys: this.selectedRowKeys,
        onChange: (selectedRowKeys, selectedRows) => {
          this.selectedRowKeys = selectedRowKeys
          this.selectedRows = selectedRows
        },
      }
    },
  },
  methods: {
    onSearch() {
      this.getEqusList()
    },
    // 翻页
    onChange(page) {
      this.pageIndex = page
      this.getEqusList()
    },
    // 页数size改变
    showSizeChange(key, size) {
      console.log(key)
      this.pageSize = size
      this.getEqusList()
    },
    // 获取人员列表
    getEqusList() {
      let dataset = {
        page: this.pageIndex,
        limit: this.pageSize,
        tenantId: this.tenantId,
        name: this.searchValue,
      }
      if (this.special === '1') {
        dataset.special = this.special
      }
      util.post('equipment/queryEquList', dataset).then((res) => {
        let data = res.dataset
        if (data.length > 0) {
          data.forEach((element) => {
            element.key = element.c_id
          })
        }
        this.dataSource = data
        this.total = res.cot
      })
    },
    showDialog() {
      this.visible = true
    },
    closeModal() {
      this.selectedRowKeys = []
      this.selectedRows = []
      this.searchValue = ''
      this.visible = false
    },
    clearName() {
      this.inputName = ''
    },
    // 清空选中
    InputonChange(val) {
      if (val) {
        this.selectedRowKeys = []
        this.selectedRows = []
        this.$emit('onSelect', {
          key: this.selectedRowKeys,
          obj: this.selectedRows,
        })
      }
    },
    updataWin() {
      if (this.selectedRows.length !== 0) {
        this.inputName = this.selectedRows[0].c_name
        this.$emit('onSelect', {
          key: this.selectedRowKeys,
          obj: this.selectedRows,
        })
      }
      this.closeModal()
    },
  },
}
</script>

<style scoped lang="less">
.selectEqus-search {
  width: 100%;
  text-align: right;
}
.selectEqus-table {
  margin-top: 20px;
}
.borderTable {
  margin-bottom: 20px;
}
.selectEqus-modal {
  .ant-pagination {
    margin-top: 0px !important;
  }
}
</style>
